import { AsyncPipe, NgForOf, NgIf, NgTemplateOutlet } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  ElementRef,
  inject,
  Input,
  ViewChild
} from '@angular/core';
import { RouterLink } from '@angular/router';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import { interval, Subject } from 'rxjs';
import { repeatWhen, takeUntil } from 'rxjs/operators';

import { BreakpointsService } from '../../../services/breakpoints.service';
import { CardComponent } from '../card/card.component';
import { GetLogoBasedOnAuthor } from '../../../pages/dynamic-page/dynamic-page.pipe';
import { HomeCardAltLink, HomeNews } from '../home.model';

@Component({
  selector: 'app-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    AsyncPipe,
    CardComponent,
    NgForOf,
    NgIf,
    GetLogoBasedOnAuthor,
    NgTemplateOutlet,
    RouterLink
  ]
})
export class CarouselComponent implements AfterViewInit {
  @Input({ required: true }) protected readonly cards: (HomeCardAltLink | HomeNews)[];

  protected carouselStop = false;
  protected slideNumber = 0;

  @ViewChild('carousel', { static: false }) protected readonly carousel: ElementRef<HTMLDivElement>;

  protected readonly breakpointsService = inject(BreakpointsService);
  private readonly cdr = inject(ChangeDetectorRef);
  private readonly destroyRef = inject(DestroyRef);

  private readonly stopSlider = new Subject<void>();
  private readonly startSlider = new Subject<void>();

  ngAfterViewInit(): void {
    this.runCarousel();
  }

  private slideCarouser(slideNumber: number): void {
    this.slideNumber = slideNumber;
    this.carousel.nativeElement.style.left = `-${ slideNumber * 1200 }px`;
  }

  protected slideCarouserManually(slideNumber: number): void {
    this.slideCarouser(slideNumber);
    this.resetSlider();
  }

  private resetSlider(): void {
    this.stopSlider.next();
    this.startSlider.next();
  }

  private runCarousel(): void {
    if (this.breakpointsService.isDesktop) {
      interval(8000).pipe(
        takeUntilDestroyed(this.destroyRef),
        takeUntil(this.stopSlider),
        repeatWhen(() => this.startSlider),
      ).subscribe(() => {
        if (!this.carouselStop) {
          this.slideCarouser(this.slideNumber === 0 ? 1 : 0);
          this.cdr.markForCheck();
        }
      })
    }
  }
}
