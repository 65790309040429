<article class="home">
  <section class="section section-light">
    <h1 class="section-container welcome">
      <app-welcome></app-welcome>
    </h1>
  </section>

  <section class="section section-dark">
    <h2 class="header-with-cards first header-extra-space">
      <a routerLink="/biblia">Szukaj w Biblii</a>
    </h2>

    <div class="section-container">
      <div class="flex-container">
        @for (card of bibleCards; track card.title) {
          @defer (on viewport; prefetch on idle) {
          <app-card [title]="card.title" [imgName]="card.imgName" [altLink]="card?.altLink"></app-card>
          } @placeholder (minimum 1s) {
          <div class="placeholder-card"></div>
        }
      }
    </div>
  </div>
</section>

<section class="section section-light">
  <h2 class="header-with-cards header-extra-space">
    <a routerLink="/strong">System Stronga</a>
  </h2>

  <div class="section-container">
    <div class="flex-container">
      @for (card of strongCards; track card) {
        @defer (on viewport; prefetch on idle) {
        <app-card [title]="card.title" [imgName]="card.imgName" [altLink]="card?.altLink"></app-card>
        } @placeholder (minimum 1s) {
        <div class="placeholder-card"></div>
      }
    }
  </div>
</div>
</section>

<section class="section section-dark">
  <h2 class="header-with-cards header-extra-space">Nowości na platformie</h2>

  @defer (on viewport; prefetch on idle) {
  <app-carousel [cards]="newsCards"></app-carousel>
  } @placeholder (minimum 1s) {
  <div class="placeholder-news">
    <div></div>
    <div *exceptLandscape></div>
    <div *onlyDesktop></div>
  </div>
}
</section>

<section class="section section-dark">
  <h2 class="header-with-cards header-extra-space">Szukaj w wybranych działach</h2>

  <div class="section-container">
    <div class="flex-container">
      @for (card of studiesCards; track card) {
        @defer (on viewport; prefetch on idle) {
        <app-card [title]="card.title" [imgName]="card.imgName" [altLink]="card?.altLink"></app-card>
        } @placeholder (minimum 1s) {
        <div class="placeholder-card"></div>
      }
    }
  </div>
</div>
</section>

<section class="section section-light">
  <h2 class="header-with-cards header-extra-space">
    <a routerLink="/ksiazki">Polecane książki i broszury</a>
  </h2>

  @defer (on viewport; prefetch on idle) {
  <app-carousel [cards]="booksCard"></app-carousel>
  } @placeholder (minimum 1s) {
  <div class="placeholder-books">
    <div></div>
    <div></div>
    <div *onlyDesktopTablet></div>
  </div>
}
</section>

<section class="section section-dark">
  <h2 class="header-with-cards header-extra-space">Pieśni chrześcijańskie</h2>

  <div class="section-container">
    <div class="flex-container">
      @for (card of musicCard; track card) {
        @defer (on viewport; prefetch on idle) {
        <app-card [imgName]="card.imgName" [url]="card?.url"></app-card>
        } @placeholder (minimum 1s) {
        <div class="placeholder-card"></div>
      }
    }
  </div>
</div>
</section>

<section class="section section-light">
  <h2 class="header-with-cards header-extra-space">Projekty</h2>

  <div class="section-container">
    <div class="flex-container">
      @for (card of otherCards; track card) {
        @defer (on viewport; prefetch on idle) {
        <app-card [title]="card.title" [imgName]="card.imgName" [altLink]="card?.altLink" [url]="card?.url"></app-card>
        } @placeholder (minimum 1s) {
        <div class="placeholder-card"></div>
      }
    }
  </div>
</div>
</section>

<section class="section section-dark">
  <h2 class="header-with-cards">Śledz nas w naszych mediach</h2>

  <div class="section-container">
    @defer (on viewport; prefetch on idle) {
    <app-social></app-social>
    } @placeholder (minimum 1s) {
    <div class="placeholder-social">
      <div></div>
      <div></div>
      <div></div>
    </div>
  }
</div>
</section>

@if (environment.newsletter) {
  <section class="section section-light">
    <h2 class="header-with-cards">Powiadomienia o aktualnościach</h2>
    <div class="section-container flex-center">
      @defer (on viewport; prefetch on idle) {
      <app-newsletter></app-newsletter>
      } @placeholder (minimum 1s) {
      <div class="placeholder-newsletter"></div>
    }
  </div>
</section>
}
</article>
