<form [formGroup]="form">
  <mat-form-field appearance="fill">
    <mat-label>
      @if (navigationItems) {
        Szukaj na stronie po tytule lub autorze...
      }
      @if (!navigationItems && !isError) {
        Pobieram dane...
      }
      @if (!navigationItems && isError) {
      }
    </mat-label>

    <input #input type="text" [placeholder]="'Wpisz frazę'" matInput formControlName="group" [tabIndex]="inputTabIndex"
      [matAutocomplete]="autoGroup" #trigger="matAutocompleteTrigger" (focusin)="updateValue()" (focusout)="focusout()">

    <mat-autocomplete #autoGroup="matAutocomplete">
      @for (item of navigationItems$ | async; track item) {
        <mat-optgroup>
          <div class="label-container">
            <div>{{ item[0] | split }}</div>
            <mat-icon [svgIcon]="'icons:' + NAVIGATION_ICONS.get(item[0] | dash)"></mat-icon>
          </div>
          @for (article of item[1]; track article) {
            @if ((item[0] | dash) + '/' + (article.title | dash)
              + (article.chapter ? '-' + article.chapter : '') | plEn; as routeLink) {
              <mat-option
                [value]="article | value" (onSelectionChange)="onSelectionChange(article.url || routeLink, article)"
                class="search-input-option">
                <div class="option-wrapper">
                  @if (item[0] === 'ostatnio-wyszukiwane') {
                    <mat-icon [svgIcon]="'icons:' + (article.url.split('/')[0] | icon)" [class]="article.url.split('/')[0] | icon"></mat-icon>
                  }
                  <div class="option-value">
                    <span class="text">
                      {{ article.title }}
                      {{ ' ' + article.chapter ? article.chapter : '' }}
                      {{ ' ' + article.subtitle ? article.subtitle : '' }}
                    </span>
                    <span class="chips-wrapper">
                      @for (chipCode of article.chips; track chipCode) {
                        <span class="chip" [class]="(chipCode | chip : item[0]) | plEn | dash"></span>
                      }
                    </span>
                  </div>
                </div>
              </mat-option>
            }
          }
        </mat-optgroup>
      }
    </mat-autocomplete>
  </mat-form-field>

  @if (!navigationItems && !isError) {
    <mat-spinner diameter="40"></mat-spinner>
  }

  @if (isError) {
    <mat-icon class="error-icon" svgIcon="icons:error_outline" matTooltip="Wystąpił błąd podczas pobierania danych."></mat-icon>
  }

  @if (value) {
    <div class="clear-container">
      <app-icon-button label="Wyczyść pole wyszukiwarki" class="small alt-hover" icon="close" (click$)="clearForm($event, trigger, input)"></app-icon-button>
    </div>
  }
</form>
