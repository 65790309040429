import { BreakpointObserver } from '@angular/cdk/layout';
import { ChangeDetectionStrategy, Component, EventEmitter, inject, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import {
  MatAutocompleteModule,
  MatAutocompleteTrigger
} from '@angular/material/autocomplete';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatOptionModule } from '@angular/material/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NavigationEnd, Router, RouterEvent, RouterModule } from '@angular/router';

import { filter, map } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { BibleQueryService } from '../../services/bible-query.service';
import { Breakpoints } from '../../directives/media/breakpoints.enum';
import { IconButtonComponent } from '../icon-button/icon-button.component';
import { MediaDirectivesModule } from '../../directives/media/media-directives.module';
import { Navigation, NAVIGATION_ICONS, NavigationItem } from '../../services/navigation-cache/navigation-cache.model';
import { NavigationCacheService } from '../../services/navigation-cache/navigation-cache.service';
import { SearchInputValuePipe } from './search-input-value.pipe';
import { SharedPipesModule } from '../../pipes/shared-pipes.module';
import { SubComponent } from '../utils/sub/sub.component';

@Component({
  selector: 'app-search-input',
  templateUrl: './search-input.component.html',
  styleUrls: ['./search-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    IconButtonComponent,
    MatAutocompleteModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatOptionModule,
    MatProgressSpinnerModule,
    MatTooltipModule,
    MediaDirectivesModule,
    ReactiveFormsModule,
    RouterModule,
    SearchInputValuePipe,
    SharedPipesModule,
  ],
  providers: [SearchInputValuePipe],
})
export class SearchInputComponent extends SubComponent implements OnInit {
  readonly NAVIGATION_ICONS = NAVIGATION_ICONS;

  private readonly bibleQueryService = inject(BibleQueryService);
  private readonly breakpointObserver = inject(BreakpointObserver);
  private readonly navigationCacheService = inject(NavigationCacheService);
  private readonly router = inject(Router);
  private readonly valuePipe = inject(SearchInputValuePipe);

  protected readonly form = new FormGroup({
    group: new FormControl(''),
  })

  protected inputTabIndex: number;
  protected isError = false;
  protected value = '';

  protected navigationItems: Navigation;
  protected navigationItems$: Observable<Navigation>;

  @Output() private readonly selectionChange$ = new EventEmitter<void>();

  private notClearInputWhitelist = [ 'apologetyka', 'komentarza', 'opracowania' ];

  ngOnInit() {
    this.fetchNavigation();
    this.initStateGroups();
    this.observeStateGroupValueChanges();
    this.observeNavigationEnd();
    this.observeBreakpointsChanges();
  }

  onSelectionChange(url: string, article: NavigationItem): void {
    SearchInputComponent.saveLastSearchingResultsToStorage(url, article);
    this.selectionChange$.emit();
    this.router.navigateByUrl(url);
  }

  clearForm(event: Event, trigger: MatAutocompleteTrigger, input: HTMLInputElement): void {
    event.stopPropagation();
    this.clearInput();
    trigger.openPanel();
    input.focus();
  }

  updateValue(): void {
    this.form.updateValueAndValidity();
    this.bibleQueryService.disable();
  }

  focusout(): void {
    this.bibleQueryService.enable();
  }

  private fetchNavigation(): void {
    this.subscription.add(this.navigationCacheService.navigation$.subscribe(value => {
      this.navigationItems = value?.data;
      this.isError = value.isError;

      if (!value.isError) {
        setTimeout(() => {
          this.form.get('group').enable();
          this.form.get('group').updateValueAndValidity();
          this.bibleQueryService.disable();
        })
      } else {
        this.form.get('group').disable();
        setTimeout(() => this.updateValue());
      }
    }));
  }

  private observeStateGroupValueChanges(): void {
    this.navigationItems$ = this.form.get('group').valueChanges.pipe(
      map(value => (value === '') ? SearchInputComponent.getLastSearchResults() : this.filterGroup(value))
    );
  }

  private static getLastSearchResults(): Navigation {
    const storageItem = JSON.parse(localStorage.getItem('search')) || {};
    return !!Object.values(storageItem).length ? [['ostatnio-wyszukiwane', Object.values(storageItem)]] : null;
  }

  private filterGroup(value: string): Navigation {
    this.value = value;

    return !value ? this.navigationItems : this.navigationItems
      .map(item => [item[0], this.filter(item[1], value.trim())] as [string, NavigationItem[]])
      .filter(item => item[1].length > 0);
  }

  private filter(opt: NavigationItem[], value: string): NavigationItem[] {
    return opt.filter(item => {
      const author = !!item.author ? item.author.toLowerCase() : 'apologetyka biblijna';
      return (!!value && this.valuePipe.transform(item).toLowerCase().includes(value?.toLowerCase())) ||
        (!!value && (author.includes(value?.toLowerCase())));
    });
  };

  private initStateGroups(): void {
    this.form.get('group').disable();

    if (!this.navigationItems) {
      this.navigationItems = JSON.parse(sessionStorage.getItem('navigation')) as Navigation;

      if (this.navigationItems) {
        this.form.get('group').enable();
      }
    }
  }

  private observeNavigationEnd(): void {
    this.subscription.add(this.router.events.pipe(filter(e => e instanceof NavigationEnd)).subscribe(e => {
      if (!this.notClearInputWhitelist.includes((e as RouterEvent).url.split('/')[1])) {
        this.clearInput();
      }
    }));
  }

  private clearInput(): void {
    this.form.get('group').setValue('');
    this.value = null;
  }

  private observeBreakpointsChanges(): void {
    this.subscription.add(this.breakpointObserver.observe([`(min-width: ${Breakpoints.DESKTOP}px)`])
      .subscribe(value => this.inputTabIndex = value.matches ? 0 : -1));
  }

  private static saveLastSearchingResultsToStorage(url: string, article: NavigationItem): void {
    const storageItem: {} = JSON.parse(localStorage.getItem('search')) || {};
    const entries = Object.entries(storageItem);

    if (storageItem[url]) {
      entries.splice(entries.findIndex(entry => entry[0] === url), 1);
    } else if (entries.length > 4) {
      entries.pop();
    }
    article.url = url;
    entries.unshift([url, article]);
    localStorage.setItem('search', JSON.stringify(Object.fromEntries(entries)));
  }
}
