<!--suppress HtmlFormInputWithoutLabel -->
<app-table-generic-filter>
  <form [formGroup]="form">
    <div class="filter-container">
      <mat-form-field appearance="fill" class="field-title">
        <mat-label>Tytuł</mat-label>
        <input matInput formControlName="title" placeholder="Wpisz tytuł">
      </mat-form-field>

      @if (this.authors.length > 1) {
        <mat-form-field appearance="fill" class="field-author">
          <mat-label>Autor</mat-label>
          <mat-select formControlName="author" #select [panelWidth]="null">
            <mat-select-trigger>{{ select.value }}</mat-select-trigger>
            <mat-option>---</mat-option>
            @for (author of authors; track author) {
              <mat-option [value]="author">
                <img [src]="'assets/img/' + (author | getLogoBasedOnAuthor)" alt="" class="select-image">
                {{ author }}
                <span class="chip-counter">
                  &nbsp;{{ authorsCounter.has(author) ? ('(' + (authorsCounter.get(author)) + ')') : '' }}
                </span>
              </mat-option>
            }
          </mat-select>
        </mat-form-field>
      }

      <mat-form-field appearance="fill" class="field-date">
        <mat-label>Zakres daty publikacji</mat-label>

        <mat-date-range-input [rangePicker]="picker">
          <input matStartDate formControlName="startDate" placeholder="start">
          <input matEndDate formControlName="endDate" placeholder="koniec">
        </mat-date-range-input>

        @if (isDateFilled) {
          <mat-datepicker-toggle matSuffix class="clear-toggle" (click)="clearDatePickerValue()">
            <mat-icon matDatepickerToggleIcon svgIcon="icons:close" aria-label="wyczyść zakres daty publikacji"></mat-icon>
          </mat-datepicker-toggle>
        }

        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>

        <mat-date-range-picker #picker [touchUi]="!(breakpointService.observeDesktop | async)"></mat-date-range-picker>
      </mat-form-field>

      <mat-form-field appearance="fill" class="field-tag">
        <mat-label>Etykieta</mat-label>
        <mat-select formControlName="tag" [panelWidth]="null">
          <mat-select-trigger>
            <span class="select-chip" [class]="form.get('tag').value | chip | plEn | dash"></span>

            {{ form.get('tag').value | chip }}
          </mat-select-trigger>

          <mat-option>---</mat-option>
          @for (chipCode of [] | chips; track chipCode) {
            <mat-option [value]="chipCode">
              <span class="select-chip" [class]="chipCode | chip | plEn | dash"></span>
              {{ chipCode | chip }}
              <span class="chip-counter">
                &nbsp;{{ chipsCounter.has(chipCode) ? ('(' + (chipsCounter.get(chipCode)) + ')') : '' }}
              </span>
            </mat-option>
          }
        </mat-select>
      </mat-form-field>
    </div>

    <div class="filter-container extra-container">
      <div class="flex-container">
        <mat-form-field appearance="fill" class="field-title">
          <mat-label>Tytuł</mat-label>
          <input matInput formControlName="title" placeholder="Wpisz tytuł">
        </mat-form-field>

        @if (this.authors.length > 1) {
          <mat-form-field appearance="fill" class="field-author">
            <mat-label>Autor</mat-label>
            <input matInput formControlName="author" placeholder="Wpisz autora">
          </mat-form-field>
        }
      </div>

      <div class="flex-container">
        <mat-form-field appearance="fill" class="field-date">
          <mat-label>Zakres daty publikacji</mat-label>
          <mat-date-range-input [rangePicker]="picker2">
            <input matStartDate formControlName="startDate" placeholder="start">
            <input matEndDate formControlName="endDate" placeholder="koniec">
          </mat-date-range-input>

          @if (isDateFilled) {
            <mat-datepicker-toggle matSuffix class="clear-toggle" (click)="clearDatePickerValue()">
              <mat-icon matDatepickerToggleIcon svgIcon="icons:close" aria-label="wyczyść zakres daty publikacji"></mat-icon>
            </mat-datepicker-toggle>
          }

          <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>

          <mat-date-range-picker #picker2 [touchUi]="!(breakpointService.observeDesktop | async)"></mat-date-range-picker>
        </mat-form-field>

        <mat-form-field appearance="fill" class="field-tag">
          <mat-label>Etykieta</mat-label>
          <mat-select formControlName="tag">
            <mat-option>---</mat-option>
            @for (chipCode of [] | chips; track chipCode) {
              <mat-option [value]="chipCode">
                <span class="select-chip"></span>
                {{ chipCode | chip }}
                <span class="chip-counter">
                  &nbsp;{{ chipsCounter.has(chipCode) ? ('(' + (chipsCounter.get(chipCode)) + ')') : '' }}
                </span>
              </mat-option>
            }
          </mat-select>
        </mat-form-field>
      </div>
    </div>
  </form>
</app-table-generic-filter>
