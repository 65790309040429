<app-table-generic [data]="data" [isLoading]="isLoading" [svgIcon]="svgIcon" [sort]="matSort" [title]="title">
  <ng-content></ng-content>

  <app-table-article-filter
    [authors]="authors"
    [authorsCounter]="authorsCounter"
    [chipsCounter]="chipsCounter"
    (search$)="applyFilter($event)"
  ></app-table-article-filter>

  <table mat-table multiTemplateDataRows matSort matSortActive="date" matSortDirection="desc"
    [dataSource]="dataSource"
    >
    <ng-container matColumnDef="position">
      <th mat-header-cell *matHeaderCellDef>
        <mat-icon svgIcon="icons:tag" class="tag"></mat-icon>
      </th>

      <td mat-cell *matCellDef="let element; let i = dataIndex">
        <app-article-chips [chips]="element.chips"></app-article-chips>
      </td>
    </ng-container>

    <ng-container matColumnDef="title">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>tytuł</th>
      <td mat-cell *matCellDef="let element">
        {{ element.title }}
        @if (element.subtitle) {
          <div class="subtitle">{{ element.subtitle }}</div>
        }
      </td>
    </ng-container>

    <ng-container matColumnDef="author">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="hidden-landscape-portrait">autor</th>
      <td mat-cell *matCellDef="let element" class="hidden-landscape-portrait column-with-img">
        <img [src]="'assets/img/' + (element.author | getLogoBasedOnAuthor)" alt="" class="logo-image">

        {{ element.author || '---' }}
      </td>
    </ng-container>

    <ng-container matColumnDef="date">
      <th mat-header-cell *matHeaderCellDef mat-sort-header arrowPosition="before">
        <div *onlyDesktopTablet>data publikacji</div>
        <div *onlyLandscape>data publik.</div>
        <div *onlyPortrait>data</div>
      </th>

      <td mat-cell *matCellDef="let element">
        <div *onlyDesktopTablet>{{ element.date | date: 'longDate' }}</div>
        <div *onlyLandscape>{{ element.date | date }}</div>
        <div *onlyPortrait>{{ element.date | date: 'shortDate' }}</div>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>

    <tr mat-row *matRowDef="let element; columns: displayedColumns;" tabIndex="0" class="element-row"
      [routerLink]="'/' + section + '/' + (element.url || (element.title | dash | plEn))"
    ></tr>

    <tr class="mat-row no-data" *matNoDataRow>
      @if (!isLoading && !navigationCache.isError) {
        <td class="mat-cell not-found" colspan="4">Nie znaleziono, zmień kryteria wyszukiwania.</td>
      }
      @if (isLoading && !navigationCache.isError) {
        <td class="mat-cell" colspan="4"><mat-spinner diameter="40"></mat-spinner></td>
      }
      @if (navigationCache.isError) {
        <td class="mat-cell" colspan="4">
          <div class="error-container">
            <mat-icon svgIcon="icons:error_outline"></mat-icon> Wystąpił błąd podczas pobierania danych.
          </div>
        </td>
      }
    </tr>
  </table>
</app-table-generic>

<ng-template #button let-hidden="hidden">
  <button mat-fab aria-label="czytaj wpis" matTooltip="czytaj wpis" matTooltipPosition="left"
    routerLink="/" [tabIndex]="hidden ? -1 : 0">
    <mat-icon svgIcon="icons:read"></mat-icon>
  </button>
</ng-template>
