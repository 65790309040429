<!--suppress HtmlFormInputWithoutLabel -->
<app-table-generic-filter>
  <form [formGroup]="form">
    <div *exceptLandscape class="filter-container">
      <mat-form-field appearance="fill" class="field-book">
        <mat-label>Księga</mat-label>
        <mat-select formControlName="book">
          <mat-option>---</mat-option>
          @for (book of books.keys(); track book) {
            <mat-option [value]="book">{{ book }}</mat-option>
          }
        </mat-select>
      </mat-form-field>

      @if (chapters.length > 0) {
        <mat-form-field appearance="fill" class="field-chapter">
          <mat-label>Rozdział</mat-label>
          <mat-select formControlName="chapter">
            <mat-option>---</mat-option>
            @for (chapter of chapters; track chapter) {
              <mat-option [value]="chapter">{{ chapter }}</mat-option>
            }
          </mat-select>
        </mat-form-field>
      }

      @if (chapters.length === 0) {
        <mat-form-field appearance="fill" class="field-chapter">
          <mat-label>Rozdział</mat-label>
          <input matInput formControlName="chapter" placeholder="Wpisz rozdział">
        </mat-form-field>
      }

      <mat-form-field appearance="fill" class="field-date">
        <mat-label>Zakres daty publikacji</mat-label>

        <mat-date-range-input [rangePicker]="picker">
          <input matStartDate placeholder="start" formControlName="startDate">
          <input matEndDate placeholder="koniec" formControlName="endDate">
        </mat-date-range-input>

        @if (isDateFilled) {
          <mat-datepicker-toggle matSuffix class="clear-toggle" (click)="clearDatePickerValue()">
            <mat-icon matDatepickerToggleIcon svgIcon="icons:close" aria-label="wyczyść zakres daty publikacji"></mat-icon>
          </mat-datepicker-toggle>
        }

        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>

        <mat-date-range-picker #picker [touchUi]="!(breakpointService.observeDesktop | async)"></mat-date-range-picker>
      </mat-form-field>
    </div>

    <div *onlyLandscape class="filter-container">
      <mat-form-field appearance="fill" class="field-book">
        <mat-label>Księga</mat-label>
        <mat-select formControlName="book">
          <mat-option>---</mat-option>
          @for (book of books.keys(); track book) {
            <mat-option [value]="book">{{ book }}</mat-option>
          }
        </mat-select>
      </mat-form-field>

      <div class="flex-container">
        @if (chapters.length > 0) {
          <mat-form-field appearance="fill" class="field-chapter">
            <mat-label>Rozdział</mat-label>
            <mat-select formControlName="chapter">
              <mat-option>---</mat-option>
              @for (chapter of chapters; track chapter) {
                <mat-option [value]="chapter">{{ chapter }}</mat-option>
              }
            </mat-select>
          </mat-form-field>
        }

        @if (chapters.length === 0) {
          <mat-form-field appearance="fill" class="field-chapter">
            <mat-label>Rozdział</mat-label>
            <input matInput formControlName="chapter" placeholder="Wpisz rozdział">
          </mat-form-field>
        }

        <mat-form-field appearance="fill" class="field-date">
          <mat-label>Zakres daty publikacji</mat-label>

          <mat-date-range-input [rangePicker]="picker2">
            <input matStartDate placeholder="start" formControlName="startDate">
            <input matEndDate placeholder="koniec" formControlName="endDate">
          </mat-date-range-input>

          @if (isDateFilled) {
            <mat-datepicker-toggle matSuffix class="clear-toggle" (click)="clearDatePickerValue()">
              <mat-icon matDatepickerToggleIcon svgIcon="icons:close" aria-label="wyczyść zakres daty publikacji"></mat-icon>
            </mat-datepicker-toggle>
          }

          <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>

          <mat-date-range-picker #picker2 [touchUi]="!(breakpointService.observeDesktop | async)"></mat-date-range-picker>
        </mat-form-field>
      </div>
    </div>
  </form>
</app-table-generic-filter>
