<div class="page-wrapper">
  <app-table-article class="table" [data]="data" svgIcon="apologetics" title="Apologetyka"
                     [isLoading]="isLoading" section="apologetyka" apologetics="true">
    <p>
      Dział w popularnym formacie <b>pytań i odpowiedzi.</b> Poprzez pytania biblijne rozumie się pytania, do których
      Biblia bezpośrednio lub pośrednio się odnosi.
    </p>

    <p>
      Apostoł Piotr w swoim pierwszym liście 3:15 napisał: "Pana Boga uświęcajcie w waszych sercach i <b>bądźcie zawsze
      gotowi udzielić odpowiedzi każdemu, kto domaga się od was uzasadnienia waszej nadziei</b>, z łagodnością i bojaźnią."
    </p>

    <p *onlyDesktopTabletLandscape>
      Ten dział stanowi odpowiedź na wezwanie Piotra. Autor nie zamierza uciekać wobec żadnego biblijnego pytania, będąc
      gotowy szukać prawdy dokładnie tam, gdzie się znajduje oraz podążając dokładnie tam, gdzie prowadzącą dowody i argumenty.
      Jeżeli chciałbyś, aby omówiono w tym dziale jakieś pytanie, wyślij je drogą mailową na adres:
      <b class="hover" [cdkCopyToClipboard]="environment.email" (click)="copyEmail()">{{ environment.email }}</b>
      dodając w tytule frazę [PYTANIE] lub zadaj je poprzez formularz kontaktowy klikając na poniższy przycisk.
    </p>

    <ng-container *onlyPortrait>
      <p>
        Ten dział stanowi odpowiedź na wezwanie Piotra. Autor nie zamierza uciekać wobec żadnego biblijnego pytania, będąc
        gotowy szukać prawdy dokładnie tam, gdzie się znajduje oraz podążając dokładnie tam, gdzie prowadzącą dowody i argumenty.
        Jeżeli chciałbyś, aby omówiono w tym dziale jakieś pytanie, wyślij je drogą mailową na adres:
      </p>

      <p class="center no-padding-top">
        <b class="hover" [cdkCopyToClipboard]="environment.email" (click)="copyEmail()">
          {{ environment.email }}</b>
      </p>
    </ng-container>

    <div class="question-container">
      <app-button link="./pytanie" icon="question" text="zadaj pytanie"></app-button>
    </div>
  </app-table-article>
</div>
