<footer>
  <section>
    <div class="footer-up">
      <a class="logo-link" routerLink="/" (click)="clickLogoElement()">
        <mat-icon class="logo" svgIcon="logo"></mat-icon>
      </a>
    </div>

    <p>
      <i>
        O głębokości bogactwa zarówno mądrości, jak i poznania Boga! Jak niezbadane są jego wyroki i niedocieczone jego drogi!
      </i>
    </p>
    <p class="verse">Rz.11,33</p>

    <ng-container *onlyDesktopTablet>
      <p>
        <i>
          Mamy też mocniejsze słowo prorockie, a wy dobrze czynicie, trzymając się go jak lampy, która świeci w ciemnym miejscu, dopóki dzień nie zaświta i jutrzenka nie wzejdzie w waszych sercach; To przede wszystkim wiedząc, że żadne proroctwo Pisma nie podlega własnemu wykładowi. Nie z ludzkiej bowiem woli przyniesione zostało kiedyś proroctwo, ale święci Boży ludzie przemawiali prowadzeni przez Ducha Świętego.
        </i>
      </p>
      <p class="verse">1P.1,19-21</p>
    </ng-container>
  </section>

  <section class="footer-down">
    <p class="copyright">
      {{ AppComponent.NAME }} 2022-{{ currentYear }} &copy; Wszelkie prawa zastrzeżone
    </p>

    <div class="socials">
      <a class="social-link link-1" href="https://www.facebook.com/apologetykabiblia" target="_blank">
        <mat-icon svgIcon="icons:facebook" aria-label="facebook"></mat-icon>
      </a>

      <a class="social-link link-2" href="https://youtube.com/@apologetykabiblijna" target="_blank">
        <mat-icon svgIcon="icons:youtube" aria-label="youtube"></mat-icon>
      </a>

      <a class="social-link link-3" href="https://drive.google.com/drive/folders/1WlnYaDlZ4qNR5KCRHV5-oznvmChlIDWJ?usp=sharing" target="_blank">
        <mat-icon svgIcon="icons:google-drive" aria-label="google drive"></mat-icon>
      </a>
    </div>
  </section>

  <section class="links">
    <a class="page-link" routerLink="/o-autorze" rel="noreferrer">o autorze</a>
    <a class="page-link" routerLink="/o-platformie" rel="noreferrer">o plaftormie</a>
    <a class="page-link" routerLink="/kontakt" rel="noreferrer">kontakt</a>
    <a class="page-link" routerLink="/prawa-autorskie" rel="noreferrer">prawa autorskie</a>
    <a class="page-link" routerLink="/polityka-prywatnosci" rel="noreferrer">polityka prywatności</a>
    <a class="page-link" routerLink="/mapa-platformy" rel="noreferrer">mapa platformy</a>
  </section>
</footer>
