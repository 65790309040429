<mat-accordion>
  <mat-expansion-panel>
    <mat-expansion-panel-header>
      <mat-panel-title>
        <ng-content></ng-content>
      </mat-panel-title>
    </mat-expansion-panel-header>

    <app-element-separator></app-element-separator>

    @for (link of links | routerLinks : name; track link; let i = $index; let first = $first) {
      @if (!first) {
        <app-element-separator></app-element-separator>
      }
      <app-element-link
        [abbreviation]="abbreviations[i]"
        [asQueryParams]="true"
        [smallSvgId]="smallSvgIds[i]"
        [isActive]="subIndex === i && index === currentIndex"
        [name]="links[i]"
        [link]="link"
        [url]="urls[i]"
        (click)="changeSubIndex(i)">
      </app-element-link>
    }
  </mat-expansion-panel>
</mat-accordion>
