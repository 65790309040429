<div class="section-container section-carousel">
  <div class="flex-container" #carousel
       (mouseenter)="carouselStop = true" (mouseleave)="carouselStop = false">
    <ng-container *ngFor="let card of cards; let i = index">
      <div *ngIf="i % ((breakpointsService.observeTabletOrBelow | async) ? 2 : 3) === 0" class="card-group"
           [class.card-group-author]="cards[i]?.author">
        <ng-container *ngIf="cards[i] as card1">
          <ng-container *ngIf="card1.author; else cardA">
            <ng-container *ngTemplateOutlet="authorCard; context: { card: card1 }"></ng-container>
          </ng-container>

          <ng-template #cardA>
            <app-card [title]="card1.title" [imgName]="card1.imgName" [altLink]="card1?.altLink"></app-card>
          </ng-template>
        </ng-container>

        <ng-container *ngIf="cards[i + 1] as card2">
          <ng-container *ngIf="card2.author; else cardB">
            <ng-container *ngTemplateOutlet="authorCard; context: { card: card2 }"></ng-container>
          </ng-container>

          <ng-template #cardB>
            <app-card [title]="card2.title" [imgName]="card2.imgName" [altLink]="card2?.altLink"></app-card>
          </ng-template>
        </ng-container>

        <ng-container *ngIf="!(breakpointsService.observeTabletOrBelow | async)">
          <ng-container *ngIf="cards[i + 2] as card3">
            <ng-container *ngIf="card3.author; else cardC">
              <ng-container *ngTemplateOutlet="authorCard; context: { card: card3 }"></ng-container>
            </ng-container>

            <ng-template #cardC>
              <app-card [title]="card3.title" [imgName]="card3.imgName" [altLink]="card3?.altLink"></app-card>
            </ng-template>
          </ng-container>
        </ng-container>
      </div>
    </ng-container>
  </div>

  <div class="slider-panel" *ngIf="!(breakpointsService.observeTabletOrBelow | async)">
    <button class="slider-dot" #sliderDot1 (click)="slideCarouserManually(0); sliderDot1.blur()"
            [class.active]="slideNumber === 0"
            aria-label="Pokaż pierwszy slajd z książkami"></button>
    <button class="slider-dot" #sliderDot2 (click)="slideCarouserManually(1); sliderDot2.blur()"
            [class.active]="slideNumber === 1"
            aria-label="Pokaż drugi slajd z książkami"></button>
  </div>
</div>

<ng-template #authorCard let-card="card">
  <a class="author-card image-box" [routerLink]="card.altLink">
    <div class="author-text">
      <div class="author-title">{{ card.title }}</div>

      <div class="author">{{ card.author }}</div>
    </div>

    <img [src]="'assets/img/' + (card.author | getLogoBasedOnAuthor)"
         alt="'wystąpił błąd przy pobieraniu obrazka'" class="logo-image">
  </a>
</ng-template>
