import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, HostBinding, inject, Input, OnInit } from '@angular/core';

import { FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatNativeDateModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';

import { ChipsPipe } from '../../../../pipes/chips.pipe';
import { GenericFilterEmitterComponent } from '../../generic/filter/generic-filter-emitter.component';
import { GetLogoBasedOnAuthor } from '../../../../pages/dynamic-page/dynamic-page.pipe';
import { SharedPipesModule } from '../../../../pipes/shared-pipes.module';
import { TableArticleFilterForm } from './table-article-filter.model';
import { TableGenericChipsPipe } from '../../generic/table/table-generic-chips.pipe';
import { TableGenericFilterComponent } from '../../generic/filter/table-generic-filter.component';
import { BreakpointsService } from '../../../../services/breakpoints.service';

export interface ArticleFilter {
  title: string;
  author: string;
  startDate: string;
  endDate: string;
}

@Component({
  selector: 'app-table-article-filter',
  templateUrl: './table-article-filter.component.html',
  styleUrls: ['./table-article-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    AsyncPipe,
    GetLogoBasedOnAuthor,
    MatDatepickerModule,
    MatFormFieldModule,
    MatIconModule,
    MatNativeDateModule,
    MatInputModule,
    MatSelectModule,
    ReactiveFormsModule,
    SharedPipesModule,
    TableGenericChipsPipe,
    TableGenericFilterComponent,
  ],
})
export class TableArticleFilterComponent extends GenericFilterEmitterComponent<ArticleFilter> implements OnInit {
  @Input({ required: true }) protected readonly authors: string[];
  @Input({ required: true }) protected readonly authorsCounter = new Map<string, number>();
  @Input({ required: true }) protected readonly chipsCounter = new Map<string, number>();

  protected readonly breakpointService = inject(BreakpointsService);
  private readonly fb = inject(FormBuilder);

  @HostBinding('class.one-author') oneAuthor = false;

  protected readonly form: FormGroup<TableArticleFilterForm> = this.fb.group({
    title: this.fb.control(''),
    author: this.fb.control(''),
    startDate: this.fb.control(''),
    endDate: this.fb.control(''),
    tag: this.fb.control(''),
  });

  protected get isDateFilled(): boolean {
    return !!this.form.get('startDate').value || !!this.form.get('endDate').value;
  }

  ngOnInit() {
    this.removeAuthorsIfOnlyOneIsDefined();
    this.initFiltersBasedOnUrlState();
    this.observerValueChanges();
  }

  protected clearDatePickerValue(): void {
    this.form.get('startDate').reset();
    this.form.get('endDate').reset();
  }

  private observerValueChanges(): void {
    this.subscription.add(this.form.valueChanges.subscribe(value => this.applyFilter(value as ArticleFilter)));
  }

  private initFiltersBasedOnUrlState(): void {
    if (this.authors?.includes(history.state?.author)) {
      this.form.get('author').setValue(history.state.author);
    }
    this.form.get('tag').setValue(ChipsPipe.getKeyByValue(history.state?.tag));
    this.applyFilter(this.form.value as ArticleFilter);
  }

  private removeAuthorsIfOnlyOneIsDefined(): void {
    if (this.authors.length <= 1) {
      this.oneAuthor = true;
      this.form.removeControl('author');
    }
  }
}
