import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { LOCALE_ID, NgModule } from '@angular/core';
import localePl from '@angular/common/locales/pl';
import { registerLocaleData } from '@angular/common';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { CookieBannerDialogComponent } from './components/cookie-baner-dialog/cookie-banner-dialog.component';
import { FooterComponent } from './components/footer/footer.component';
import { HideForDirective } from './directives/hide-for.directive';
import { HideScrollTopButtonDirective } from './components/scroll-top-button/hide-scroll-top-button.directive';
import { HomeComponent } from './components/home/home.component';
import { MediaDirectivesModule } from './directives/media/media-directives.module';
import { PagesModule } from './pages/pages.module';
import { provideHttpClient } from '@angular/common/http';
import { ScrollTopButtonComponent } from './components/scroll-top-button/scroll-top-button.component';
import { SidenavComponent } from './components/sidenav/sidenav.component';
import { SimpleFooterComponent } from './components/simple-footer/simple-footer.component';
import { TablesModule } from './tables/tables.module';
import { ThemeUrlPipe } from './theme-url.pipe';
import { TopBarComponent } from './components/top-bar/top-bar.component';

registerLocaleData(localePl);

@NgModule({
  declarations: [AppComponent, ThemeUrlPipe],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    CookieBannerDialogComponent,
    FooterComponent,
    HideForDirective,
    HideScrollTopButtonDirective,
    MediaDirectivesModule,
    HomeComponent,
    PagesModule,
    ScrollTopButtonComponent,
    SidenavComponent,
    SimpleFooterComponent,
    TablesModule,
    TopBarComponent,
  ],
  bootstrap: [AppComponent],
  providers: [
    { provide: LOCALE_ID, useValue: 'pl'},
    provideHttpClient(),
  ]
})
export class AppModule { }
