import { Pipe, PipeTransform } from '@angular/core';

import { NavigationItem } from '../../services/navigation-cache/navigation-cache.model';

@Pipe({
  name: 'value',
  standalone: true,
})
export class SearchInputValuePipe implements PipeTransform {
  transform(item: NavigationItem): string {
    return item.title + ' ' + (item.chapter ? item.chapter : item.subtitle ? item.subtitle : '');
  }
}
