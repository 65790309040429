import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterLink } from '@angular/router';

import { DynamicPageParagraphContent } from '../../../../services/dynamic-page/dynamic-page.model';
import { ParagraphBibleComponent } from '../paragraph-bible/paragraph-bible.component';
import { ParagraphStrongComponent } from '../paragraph-strong/paragraph-strong.component';
import { ScrollerHandlerComponent } from '../../../../components/bibliography/scroller-handler/scroller-handler.component';
import { SharedPipesModule } from '../../../../pipes/shared-pipes.module';

@Component({
  selector: 'app-dynamic-page-paragraph',
  standalone: true,
  imports: [
    CommonModule,
    MatIconModule,
    MatTooltipModule,
    ParagraphBibleComponent,
    ParagraphStrongComponent,
    RouterLink,
    ScrollerHandlerComponent,
    SharedPipesModule,
  ],
  templateUrl: './dynamic-page-paragraph.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DynamicPageParagraphComponent {
  @Input({ required: true }) protected readonly paragraph: DynamicPageParagraphContent[];
  @Input() protected readonly noPadding = false;
}
