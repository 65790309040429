import { Component, ChangeDetectionStrategy } from '@angular/core';

import { Observable } from 'rxjs';

import { DynamicPageComponent } from '../dynamic-page.component';
import { DynamicPageResponse } from '../../../services/dynamic-page/dynamic-page.model';

@Component({
  templateUrl: '../dynamic-page.component.html',
  styleUrls: ['../dynamic-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ApologeticsPageComponent extends DynamicPageComponent {
  protected fetchPage(id: string): Observable<DynamicPageResponse> {
    return this.dynamicPageService.getApologetics(id);
  }
}
