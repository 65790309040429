import { ChangeDetectionStrategy, Component, Input } from '@angular/core';


import { DynamicPagePoemContent } from '../../services/dynamic-page/dynamic-page.model';

@Component({
  selector: 'app-poem',
  templateUrl: './poem.component.html',
  styleUrls: ['./poem.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [],
})
export class PoemComponent {
  @Input({ required: true }) protected readonly poemes: DynamicPagePoemContent;
}
