@if (navigation) {
  <section class="center">
    <app-header title="Czytaj dalej"></app-header>
    <div class="buttons">
      @if (elementBefore) {
        <a [routerLink]="'../' + elementBefore.link"
          aria-label="Poprzedni artykuł" class="box-shadow">
          <mat-icon svgIcon="icons:arrow-prev"></mat-icon>
          <span class="text">{{ elementBefore.title }}</span>
        </a>
      }
      @if (elementAfter) {
        <a [routerLink]="'../' + elementAfter.link"
          aria-label="Następny artykuł" class="box-shadow">
          <span class="text">{{ elementAfter.title }}</span>
          <mat-icon svgIcon="icons:arrow-next"></mat-icon>
        </a>
      }
    </div>
  </section>
}

@if (recommended) {
  <section class="center">
    <app-header title="Polecamy również"></app-header>
    <div class="buttons">
      @if (recommended[0]; as item) {
        <a [routerLink]="'../' + item.link"
          aria-label="Poprzedni artykuł" class="box-shadow center">
          <mat-icon svgIcon="icons:article"></mat-icon>
          {{ item.title }}
          <span class="author">{{ item.author | uppercase }}</span>
        </a>
      }
      @if (recommended[1]; as item) {
        <a [routerLink]="'../' + item.link"
          aria-label="Następny artykuł" class="box-shadow center">
          <mat-icon svgIcon="icons:article"></mat-icon>
          {{ item.title }}
          <span class="author">{{ item.author | uppercase }}</span>
        </a>
      }
    </div>
  </section>
}

@if (otherFromAuthor) {
  <section class="center">
    <app-header title="Inne artykuły autora"></app-header>
    <div class="buttons">
      @if (otherFromAuthor[0]; as item) {
        <a [routerLink]="'../' + item.link"
          aria-label="Poprzedni artykuł" class="box-shadow center">
          <mat-icon svgIcon="icons:account_circle"></mat-icon>
          {{ item.title }}
        </a>
      }
      @if (otherFromAuthor[1]; as item) {
        <a [routerLink]="'../' + item.link"
          aria-label="Następny artykuł" class="box-shadow center">
          <mat-icon svgIcon="icons:account_circle"></mat-icon>
          {{ item.title }}
        </a>
      }
    </div>
  </section>
}
