import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  input,
  OnInit,
  reflectComponentType,
  ViewChild
} from '@angular/core';
import { BreakpointObserver } from '@angular/cdk/layout';
import { DOCUMENT, ViewportScroller } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTooltipModule } from '@angular/material/tooltip';
import { PageEvent } from '@angular/material/paginator';
import { RouterLink } from '@angular/router';

import { BibleQuotationComponent } from '../../../../components/bible-quotation/bible-quotation.component';
import { BibleReferencesService } from '../../../../services/bible-references.service';
import {
  BibliaInfoSearchChapterModel,
  BibliaInfoSearchResult,
} from '../../../../services/biblia-info/biblia-info.model';
import { Breakpoints } from '../../../../directives/media/breakpoints.enum';
import { getPaginatorIntl } from '../../../../utils/paginator.helper';
import { HeaderComponent } from '../../../../components/header/header/header.component';
import { HighlightIndexPipe } from '../../../common/bible-search/table/highlight-index.pipe';
import {
  MatPaginator,
  MatPaginatorIntl,
  MatPaginatorModule
} from '@angular/material/paginator';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MediaDirectivesModule } from '../../../../directives/media/media-directives.module';
import { ParagraphStrongComponent } from '../paragraph-strong/paragraph-strong.component';
import { SearchVerseNoPipe } from '../../../common/bible-search/table/search-verse-no.pipe';
import { SharedPipesModule } from '../../../../pipes/shared-pipes.module';
import { SubComponent } from '../../../../components/utils/sub/sub.component';

const maxResults: { value: number } = {
  value: 0,
};

@Component({
  selector: 'app-bible-references',
  templateUrl: './bible-references.component.html',
  styleUrls: [
    './bible-references.component.scss',
    '../../../common/bible-search/table/bible-search-table.component.scss'
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  providers: [
    { provide: MatPaginatorIntl, useValue: getPaginatorIntl( maxResults) },
  ],
  imports: [
    HeaderComponent,
    HighlightIndexPipe,
    MatIconModule,
    MatPaginatorModule,
    MatProgressSpinnerModule,
    MatTableModule,
    MatTooltipModule,
    MediaDirectivesModule,
    ParagraphStrongComponent,
    RouterLink,
    SearchVerseNoPipe,
    SharedPipesModule
]
})
export class BibleReferencesComponent extends SubComponent implements OnInit, AfterViewInit {
  protected readonly removeFirstResult = input<boolean>();

  protected readonly dataSource = new MatTableDataSource<BibliaInfoSearchChapterModel>([]);
  protected readonly pageSizeOptions = [5, 100];

  protected data: BibliaInfoSearchResult;
  protected displayedColumns = ['bible', 'text'];
  protected isLoading = true;
  protected maxResults = 0;

  @ViewChild(MatPaginator) protected readonly paginator: MatPaginator;

  constructor(
    private bibleReferencesService: BibleReferencesService,
    private breakpointObserver: BreakpointObserver,
    private cdr: ChangeDetectorRef,
    @Inject(DOCUMENT) private document,
    private viewportScroller: ViewportScroller,
  ) {
    super();
  }

  ngOnInit(): void {
    this.observePortraitBreakpoints();
  }

  ngAfterViewInit(): void {
    const length = this.countReferences();

    if (length > 0) {
      this.subscription.add(
        this.bibleReferencesService.passagesSize$.subscribe(size => {
          if (length === size) {
            this.dataSource.data = this.getBibliaInfoSearchChapterModel().slice(0, 5);
            this.data = {
              all_results: length,
              bible: this.bibleReferencesService.getPassages().at(0).bible,
              results_range: '1-5',
              results: [],
              query: null,
              type: 'Odnośniki biblijne'
            };
            this.isLoading = false;
            this.cdr.markForCheck();
          }
        })
      );
    }
  }

  protected onDestroy(): void {
    this.bibleReferencesService.clearPassages();
  }

  protected paginationChange($event: PageEvent): void {
    const startPage = $event.pageIndex * $event.pageSize + 1;
    const endPage = startPage - 1 + $event.pageSize;
    const pageSize = +endPage - +startPage + 1;
    const pageIndex = (+startPage - 1) / pageSize;
    this.dataSource.data = this.getBibliaInfoSearchChapterModel().slice(pageSize * pageIndex, pageSize * pageIndex + pageSize);
    this.scrollToTable();
    setTimeout(() => {
      this.paginator.pageIndex = pageIndex;
      this.paginator.pageSize = pageSize;
    });
  }

  protected getQueryParams(ksiega: string, rozdzial: number, werset: number): Object {
    return { przeklad: this.data.bible.abbreviation, ksiega, rozdzial, werset };
  }

  private countReferences(): number {
    const length = this.document.querySelectorAll(
      reflectComponentType(BibleQuotationComponent).selector
    ).length - (this.removeFirstResult ? 1 : 0 );
    maxResults.value = length;
    this.maxResults = length;
    return length;
  }

  private getBibliaInfoSearchChapterModel(): BibliaInfoSearchChapterModel[] {
    return this.bibleReferencesService.getPassages().map(item => ({
      book: item.book,
      chapter: item.chapter,
      verses: item.verses,
      verses_range: item.verses_range,
    })).filter((v, i) => i >= (this.removeFirstResult ? 1 : 0 ));
  }

  private observePortraitBreakpoints(): void {
    this.subscription.add(this.breakpointObserver.observe([`(max-width: ${Breakpoints.LANDSCAPE - 1}px)`])
      .subscribe(value => {
        this.displayedColumns = value.matches ? ['text'] : ['bible', 'text'];
        this.cdr.markForCheck();
      }));
  }

  private scrollToTable(): void {
    this.viewportScroller.scrollToAnchor('bible-references');
  }
}
