import { AfterViewInit, ChangeDetectionStrategy, Component, inject, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';

import { MatSort } from '@angular/material/sort'
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';

import { NavigationCacheService } from '../../../../services/navigation-cache/navigation-cache.service';
import { TableGenericChipsPipe } from './table-generic-chips.pipe';
import { TableHeaderComponent } from '../../../header/table-header/table-header.component';

@Component({
  selector: 'app-table-generic',
  templateUrl: './table-generic.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
  standalone: true,
  imports: [
    TableGenericChipsPipe,
    TableHeaderComponent
]
})
export class TableGenericComponent<T> implements AfterViewInit, OnInit, OnChanges {
  @Input({ required: true }) protected readonly data: T[];
  @Input() protected readonly sort: MatSort;
  @Input({ required: true }) protected readonly svgIcon: string;
  @Input({ required: true }) protected readonly isLoading: boolean;
  @Input({ required: true }) protected readonly title: string;

  protected readonly navigationCache = inject(NavigationCacheService);
  protected readonly router = inject(Router);

  dataSource: MatTableDataSource<T>;

  ngOnInit(): void {
    this.dataSource = new MatTableDataSource<T>(this.data);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.data?.currentValue && this.dataSource) {
      this.dataSource.data = this.data;
    }
  }

  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
  }

  applyFilter(value: any): void {
    this.dataSource.data = this.data?.filter((element: any) => {
      let condition = true;
      if (value.chapter) {
        condition &&= element.chapter === +value.chapter;
      }
      if (value.book) {
        condition &&= element.book.toLowerCase() === value.book.toLowerCase();
      }
      if (value.title) {
        condition &&= element.title.toLowerCase().includes(value.title.toLowerCase());
      }
      if (value.author) {
        condition &&= element.author.toLowerCase().includes(value.author.toLowerCase());
      }
      if (value.startDate) {
        condition &&= element.date >= value.startDate;
      }
      if (value.endDate) {
        condition &&= element.date <= value.endDate;
      }
      if (value.tag) {
        condition &&= element.chips?.includes(value.tag);
      }
      return condition;
    });
  }
}
