import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, HostListener, OnInit, Output } from '@angular/core';

import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatOptionModule } from '@angular/material/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NavigationStart, Router, RouterModule } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';

import { filter } from 'rxjs/operators';

import { ActiveRouteService } from '../../services/active-route.service';
import { MediaDirectivesModule } from '../../directives/media/media-directives.module';
import { MenuService } from '../../services/menu.service';
import { SearchInputComponent } from '../search-input/search-input.component';
import { SharedPipesModule } from '../../pipes/shared-pipes.module';
import { SplitHashPipe } from './split-hash.pipe';
import { StateService } from '../../services/state/state.service';
import { TopBarService } from '../../services/top-bar.service';

@Component({
  selector: 'app-top-bar',
  templateUrl: './top-bar.component.html',
  styleUrls: ['./top-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    MatAutocompleteModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatOptionModule,
    MatProgressSpinnerModule,
    MatTooltipModule,
    MediaDirectivesModule,
    ReactiveFormsModule,
    RouterModule,
    SearchInputComponent,
    SharedPipesModule,
    SplitHashPipe
],
})
export class TopBarComponent implements OnInit {
  @Output() private readonly changeTheme$ = new EventEmitter<'light' | 'dark'>();

  protected theme: 'light' | 'dark';

  @HostBinding('class.top-bar-hidden') private hidden = false;
  private prevScrollY: number;

  static get clearIndex(): number {
    return ActiveRouteService.clearIndex;
  }

  private hideTopBarWhiteListRoutes = ['/strong', '/biblia', '/komentarze/', '/opracowania/', '/apologetyka/'];
  private hideTopBar: boolean;

  constructor(
    private activeRouteService: ActiveRouteService,
    private menuService: MenuService,
    private router: Router,
    protected stateService: StateService,
    private topBarService: TopBarService,
  ) {}

  ngOnInit(): void {
    this.theme = TopBarComponent.getThemeFromStorage();
    this.changeTheme$.emit(this.theme);
    this.observeNavigationStart();
    this.observeTopBarRequests();
  }

  @HostListener('window:scroll', ['$event'])
  trackScrollPosition(): void {
    this.updateTopBarVisibility();
    this.prevScrollY = window.scrollY;
  }

  private observeNavigationStart(): void {
    this.router.events.pipe(
      filter(event => event instanceof NavigationStart)
    ).subscribe((event: NavigationStart) => {
      this.hideTopBar = this.hideTopBarWhiteListRoutes.some(el => event.url.startsWith(el));
    });
  }

  private observeTopBarRequests(): void {
    this.topBarService.hidden$.subscribe(hidden => {
      if(!hidden) {
        this.hidden = false;
      }
    })
  }

  private updateTopBarVisibility(): void {
    if (this.hideTopBar) {
      if (window.scrollY < this.prevScrollY) {
        if (this.hidden === true) {
          this.topBarService.toggleHidden();
        }
        this.hidden = false;
      } else if (location.pathname !== '/') {
        const hidden = this.hidden;
        this.hidden = window.scrollY > 200;
        if (this.hidden !== hidden) {
          this.topBarService.toggleHidden();
        }
      }
    }
  }

  protected openMenu(): void {
    this.menuService.toggle();
  }

  protected closeMenu(): void {
    if (this.menuService.isOpen) {
      this.menuService.toggle();
    }
  }

  protected clickLogoElement(): void {
    this.activeRouteService.changeIndexes(TopBarComponent.clearIndex, null);
  }

  protected changeTheme(): void {
    this.theme = 'dark' === this.theme ? 'light' : 'dark';
    localStorage.setItem('theme', this.theme);
    this.changeTheme$.emit(this.theme);
  }

  private static getThemeFromStorage(): 'light' |'dark' {
    switch (localStorage.getItem('theme')) {
      case 'light': return 'light';
      default: return 'dark';
    }
  }
}
