import { ChangeDetectionStrategy, Component } from '@angular/core';


@Component({
  selector: 'app-table-generic-filter',
  templateUrl: 'table-generic-filter.component.html',
  styleUrls: ['table-generic-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
})
export class TableGenericFilterComponent {}
