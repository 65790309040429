import { ChangeDetectionStrategy, Component, ElementRef, HostBinding, HostListener, inject, ViewChild } from '@angular/core';
import { ViewportScroller } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { Router } from '@angular/router';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import { BreakpointsService } from '../../services/breakpoints.service';
import { IconButtonComponent } from '../icon-button/icon-button.component';
import { HideScrollTopButtonDirective } from './hide-scroll-top-button.directive';

@Component({
  selector: 'app-scroll-top-button',
  templateUrl: './scroll-top-button.component.html',
  styleUrls: ['./scroll-top-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    HideScrollTopButtonDirective,
    IconButtonComponent,
    MatIconModule,
  ],
})
export class ScrollTopButtonComponent {
  @ViewChild('button', { static: false }) private button: ElementRef<HTMLButtonElement>;

  @HostBinding('class.visible-button') private buttonVisible = false;

  static readonly buttonBreakpointInPxDesktop = 500;
  static readonly buttonBreakpointInPxMobile = 250;

  private readonly breakpointsService = inject(BreakpointsService)
  private readonly router = inject(Router)
  private readonly viewportScroller = inject(ViewportScroller)

  private buttonBreakpointInPx = ScrollTopButtonComponent.buttonBreakpointInPxDesktop;

  constructor() {
    this.breakpointsService.observePortraitLandscape
      .pipe(takeUntilDestroyed())
      .subscribe(result => this.buttonBreakpointInPx = result
        ? ScrollTopButtonComponent.buttonBreakpointInPxMobile
        : ScrollTopButtonComponent.buttonBreakpointInPxDesktop);
  }

  @HostListener('window:scroll', ['$event'])
  trackScrollPosition(): void {
    this.buttonVisible = window.scrollY > this.buttonBreakpointInPx;
  }

  protected scrollToTop(): void {
    const segments = this.router.url.split('/');
    if (segments.length > 2 && ['apologetyka', 'komentarze', 'opracowania'].includes(segments[1])) {
      const contents = document.getElementById('spis-tresci');
      if (contents) {
        this.viewportScroller.scrollToAnchor('spis-tresci');
      } else {
        this.viewportScroller.scrollToPosition([0,0]);
      }
    } else {
      this.viewportScroller.scrollToPosition([0,0]);
    }
  }
}
