import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'routerLink',
  standalone: true,
})
export class RouterLinkPipe implements PipeTransform {
  transform(link: string): string {
    return './' + link
      .split(' ')
      .join('-')
      .toLowerCase();
  }
}
