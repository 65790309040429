import { ClipboardModule } from '@angular/cdk/clipboard';
import { CommonModule } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { ApologeticsPageComponent } from './dynamic-page/apologetics/apologetics-page.component';
import { BibleModule } from './common/bible/bible.module';
import { BibleQuotationComponent } from '../components/bible-quotation/bible-quotation.component';
import { BibleReferencesComponent } from './dynamic-page/shared/bible-references/bible-references.component';
import { BibliographyComponent } from '../components/bibliography/bibliography.component';
import { ButtonsPaginatorComponent } from './dynamic-page/shared/buttons-paginator/buttons-paginator.component';
import { CommentaryPageComponent } from './dynamic-page/commentary/commentary-page.component';
import { ContactFormComponent } from '../components/contact-form/contact-form.component';
import { DynamicPageBookComponent } from './dynamic-page/shared/book/dynamic-page-book.component';
import { DynamicPageComponent } from './dynamic-page/dynamic-page.component';
import { DynamicPageParagraphComponent } from './dynamic-page/shared/paragraph/dynamic-page-paragraph.component';
import { ElaborationPageComponent } from './dynamic-page/elaboration/elaboration-page.component';
import { ErrorPageComponent } from '../components/error-page/error-page.component';
import { GetLogoBasedOnAuthor, IsPredefinedAuthor, ParagraphCenterPipe } from './dynamic-page/dynamic-page.pipe';
import { HeaderComponent } from '../components/header/header/header.component';
import { ImageComponent } from '../components/image/image.component';
import { LoaderComponent } from '../components/loader/loader.component';
import { LogoComponent } from '../components/logo/logo.component';
import { MediaDirectivesModule } from '../directives/media/media-directives.module';
import { PageHeaderComponent } from '../components/header/page-header/page-header.component';
import { PageNavButtonComponent } from './dynamic-page/navigation/nav-button/page-nav-button.component';
import { PageNavDialogComponent } from './dynamic-page/navigation/nav-dialog/page-nav-dialog.component';
import { ParagraphBibleComponent } from './dynamic-page/shared/paragraph-bible/paragraph-bible.component';
import { ParagraphStrongComponent } from './dynamic-page/shared/paragraph-strong/paragraph-strong.component';
import { PoemComponent } from '../components/poem/poem.component';
import { QuestionComponent } from '../components/question/question.component';
import { QuotationComponent } from '../components/quotation/quotation.component';
import { SharedPipesModule } from '../pipes/shared-pipes.module';
import { SimpleTableComponent } from '../components/simple-table/simple-table.component';
import { StrongComponent } from './common/strong/strong.component';
import { TableOfContentsModule } from '../components/table-of-contents/table-of-contents.module';
import { WelcomeComponent } from '../components/home/welcome/welcome.component';
import { QuestionPageComponent } from './common/question/question-page.component';
import { YoutubePlayerComponent } from '../components/youtube-player/youtube-player.component';
import { SwapImagesComponent } from '../components/swap-images/swap-images.component';

const PAGES = [
  ApologeticsPageComponent,
  CommentaryPageComponent,
  DynamicPageComponent,
  ElaborationPageComponent,
  QuestionPageComponent,
]

const COMPONENTS = [
  ...PAGES,
];

@NgModule({
  declarations: [...COMPONENTS],
  imports: [
    BibleModule,
    BibleQuotationComponent,
    BibleReferencesComponent,
    BibliographyComponent,
    ButtonsPaginatorComponent,
    ClipboardModule,
    CommonModule,
    ContactFormComponent,
    DynamicPageBookComponent,
    DynamicPageParagraphComponent,
    ParagraphBibleComponent,
    ErrorPageComponent,
    HeaderComponent,
    GetLogoBasedOnAuthor,
    ImageComponent,
    IsPredefinedAuthor,
    LoaderComponent,
    LogoComponent,
    MatFormFieldModule,
    MatIconModule,
    MatProgressSpinnerModule,
    MatSelectModule,
    MatTooltipModule,
    MediaDirectivesModule,
    PageHeaderComponent,
    PageNavButtonComponent,
    PageNavDialogComponent,
    ParagraphCenterPipe,
    ParagraphStrongComponent,
    PoemComponent,
    QuestionComponent,
    QuotationComponent,
    ReactiveFormsModule,
    RouterModule,
    SharedPipesModule,
    SimpleTableComponent,
    StrongComponent,
    SwapImagesComponent,
    TableOfContentsModule,
    WelcomeComponent,
    YoutubePlayerComponent,
  ],
  exports: [...COMPONENTS],
})
export class PagesModule {}
