import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { delay } from 'rxjs/operators';
import { Observable, of } from 'rxjs';

import { BibliaInfoBook } from '../biblia-info/biblia-info-book';
import { DynamicPageResponse } from './dynamic-page.model';
import { environment } from '../../../environments/environment';
import { GoogleUrlPipe } from '../../pipes/google-url.pipe';

@Injectable({ providedIn: 'root' })
export class DynamicPageService {

  private readonly url = `${environment.api_endpoint}/pages`;

  constructor(private httpClient: HttpClient) {}

  /** Returns selected commentary page in JSON */
  getCommentary(id: string): Observable<DynamicPageResponse> {
    return environment.is_real_data
      ? this.httpClient.get<DynamicPageResponse>(`${this.url}/commentaries/${id}`)
      : DynamicPageService.getMockPage(id);
  }

  /** Returns selected elaboration page in JSON */
  getElaboration(id: string): Observable<DynamicPageResponse> {
    return environment.is_real_data
      ? this.httpClient.get<DynamicPageResponse>(`${this.url}/elaborations/${id}`)
      : DynamicPageService.getMockPage(id);
  }

  /** Returns selected apologetics page in JSON */
  getApologetics(id: string): Observable<DynamicPageResponse> {
    return environment.is_real_data
      ? this.httpClient.get<DynamicPageResponse>(`${this.url}/apologetics/${id}`)
      : DynamicPageService.getMockPage(id);
  }

  private static getMockPage(id: string): Observable<DynamicPageResponse> {
    return of({
      _id: '61d0d597e284d4dd5c833552',
      code: id,
      description: 'Opis testowy strony',
      page: {
        title: 'Tego, co w górze, szukajmy',
        subtitle: 'New Age w chrześcijaństwie',
        pdfUrl: GoogleUrlPipe.getUrl('1GsnSNUQt4D3Eh2Wv_wBGXf79FCstk5pJ'),
        author: 'Dave Hunt',
        date: '2021/4/15',
        minutes: 5,
        chips: ['apologetyka', 'zbawienie', 'Nowe Przymierze', 'krzyż'],
        book: {
          url: {
            frontUrl: 'https://i.ibb.co/q7J9JSw/Occult-Invasion-ok-adka.jpg',
            backUrl: 'https://i.ibb.co/YX1xvxc/Occult-Invasion-ok-adka-ty.jpg',
            pdfId: '1GsnSNUQt4D3Eh2Wv_wBGXf79FCstk5pJ',
            mobiId: '1GsnSNUQt4D3Eh2Wv_wBGXf79FCstk5pJ',
            epubId: '1GsnSNUQt4D3Eh2Wv_wBGXf79FCstk5pJ',
            google: 'https://drive.google.com/drive/folders/1ZxcqzUivpalAs9Bp43DvooJVrHkPI93N'
          },
          alt: {
            frontCover: 'Opis przodu okładki',
            backCover: 'Opis przodu okładki',
          },
          bookDescription: [
            [
              {
                text: 'Pierwszy akapit opisu obok okładki.',
                noPaddingBottom: true
              },
            ],
            [
              {
                text: 'Drugi akapit opisu obok okładki.'
              }
            ]
          ],
          description: [
            [
              {
                text: 'Pierwszy akapit opisu pod okładką.'
              }
            ]
          ],
          download: [
            [
              {
                text: 'Opcjonalny opis pod linkami do pobrania plików elektronicznych.'
              }
            ]
          ],
          header: "Własny tytuł (domyślnie Pobierz książkę)"
        },
        navigation: [
          {
            title: 'Część 1: New Age w chrześcijaństwie',
            current: true,
          },
          {
            title: 'Część 2: Ruch New Age',
            link: '/powrot-do-biblijnego-chrzescijanstwa-2'
          }
        ],
        // tableOfContents: [ 'Biblijna nadzieja Pochwycenia w odstawkę', 'Bibliografia' ],
        tableOfContents: {
          content: [ 'Biblijna nadzieja Pochwycenia w odstawkę',  'Bibliografia' ],
          oneColumn: true,
        },
        bibliography: [
          { content: 'Jennifer Schuessler, Moving Wikipedia From Computer to Many, Many Bookshelves, „The New York Times”, 16 czerwca 2015, ISSN 0362-4331 [dostęp 2017-11-27] (ang.)' },
          { content: 'Turkish authorities block Wikipedia without giving reason (ang.). BBC. [dostęp 2017-04-30]' },
          { content: 'Rys: Król Lew, https://cdn.britannica.com/29/150929-050-547070A1/lion-Kenya-Masai-Mara-National-Reserve.jpg [dostęp 2017-04-30]' },
          { content: 'Linus Torvalds' }
        ],
        content: [
          {
            paragraph: [
              {
                text: 'Opis strony:',
              },
              {
                link: {
                  text: 'tutaj',
                  textAfter: '.',
                  routerLink: '/o-serwisie'
                }
              },
              {
                text: 'Znaczenie słowa'
              },
              {
                strong: {
                  text: 'Paulos.',
                  no: 3972,
                  tooltip: 'Παῦλος'
                }
              },
              {
                text: 'Wielokrotnie już pisałem o ruchu'
              },
              {
                bibliographyNo: 1,
              },
              {
                bold: '„rekonstrukcjonistycznym”.',
                tooltip: 'Podejście w obrębie neopogaństwa, opierające się na rewitalizacji czy odrodzeniu (rekonstrukcja) religii pogańskich, przede wszystkim politeistycznych.'
              },
              {
                bibliographyNo: 2,
              },
              {
                boldItalic: 'Wielokrotnie',
              },
              {
                italic: 'już pisałem o książce',
              },
              {
                link: {
                  italic: 'Judaizm rekonstrukcjonistyczny',
                  textAfter: '.',
                  external: {
                    href: 'https://pl.wikipedia.org/wiki/Judaizm_rekonstrukcjonistyczny',
                    access: '28.01.2022'
                  }
                }
              },
              {
                text: 'Na koniec fragmenty biblijne',
              },
              {
                paragraphBible: {
                  content: [
                    {
                      book: BibliaInfoBook.RODZAJU,
                      chapter: 1,
                      verses: '1-3',
                    },
                    {
                      book: BibliaInfoBook.PIERWSZY_LIST_DO_KORYNTIAN,
                      chapter: 3,
                      verses: '5',
                    }
                  ],
                  brackets: true,
                  dot: true,
                }
              }
            ],
          },
          {
            orderedList: ['Element pierwszy', 'Element drugi', 'Element trzeci']
          },
          {
            list: [
              'Element pierwszy',
              'Element drugi',
              'Element trzeci',
              {
                router: {
                  text: 'Link do artykułu',
                  routerLink: '/opracowania/opracowanie-2'
                }
              }
            ]
          },
          {
            separator: true
          },
          {
            header: 'Biblijna nadzieja Pochwycenia w odstawkę',
          },
          {
            table: {
              columns: ['pierwszy aspekt słowa', { text: 'drugi aspekt słowa', width: 10 }],
              rows: [
                ['1. sprawozdanie, rachunek', '1. opowiadanie, opowieść, relacja'],
                ['2. podstawa, zasada, motyw, racja', '2. mowa, przemówienie, słowo mówione'],
                ['3. argument, sprawa', '3. słowo'],
                ['4. uzasadnienie, sens, wyjaśnienie', '4. wyrażenie'],
                ['5. prawo, zasada postępowania', '5. wieść, nowina']
              ]
            }
          },
          {
            image: {
              src: 'https://cdn.britannica.com/29/150929-050-547070A1/lion-Kenya-Masai-Mara-National-Reserve.jpg',
              caption: 'Król Lew',
              bibliographyNo: 3
            }
          },
          {
            header: {
              text: 'Rozmiar headera h2',
              size: 2,
            },
          },
          {
            header: {
              text: 'Rozmiar headera h3',
              size: 3,
            },
          },
          {
            header: {
              text: 'Rozmiar headera h4',
              size: 4,
            },
          },
          {
            bible: {
              book: BibliaInfoBook.RODZAJU,
              chapter: 1,
              verses: '1-3',
            },
            format: {
              boldIndexes: [[1, 2], [3]],
              hideIndexes: [[],[],[7,8,9,10]]
            }
          },
          {
            bible: {
              book: BibliaInfoBook.LIST_DO_HEBRAJCZYKOW,
              chapter: 1,
              verses: '1-3',
            },
            format: {
              strong: [
                {
                  index: 0,
                  verse: 1,
                  no: 2316,
                  tooltip: 'theos',
                }
              ]
            }
          },
          {
            poemes: [
              ['Litwo, Ojczyzno moja!', 'ty jesteś jak zdrowie;'],
              ['Ile cię trzeba cenić', 'ten tylko się dowie,', 'Kto cię stracił.'],
            ]
          },
          {
            quotation: {
              text: 'Talk is cheap. Show me the code.',
              author: 'Linus Torvalds',
              bibliographyNo: 4,
            }
          },
          {
            quotation: {
              paragraph: [
                {
                  text: 'Simple text of'
                },
                {
                  paragraphBible: {
                    content: [
                      {
                        book: BibliaInfoBook.DZIEJE_APOSTOLSKIE,
                        chapter: 1,
                        verses: '1',
                      },
                    ],
                    brackets: false,
                  }
                },
                {
                  italic: 'is cheap.'
                },
                {
                  boldItalic: 'Show'
                },
                {
                  text: 'me the'
                },
                {
                  bold: 'Strong:'
                },
                {
                  strong: {
                    text: "ekklesia.",
                    no: 1577,
                    tooltip: "ἐκκλησία"
                  }
                },
              ],
              author: 'Linus Torvalds',
              bibliographyNo: 4,
            }
          },
          {
            dialogue: [
              {
                text: 'Proszę powiedzieć.'
              },
              {
                text: 'O, nie wolno jest nam tego mówić, to jest tajne!',
                narrator: 'Odparłem:'
              },
            ]
          },
          {
            video: {
              id: 'PRQCAL_RMVo'
            }
          }
        ]
      }
    }).pipe(delay(environment.request_delay));
  }
}
