import { Directive, Input, OnChanges, SimpleChanges, TemplateRef, ViewContainerRef } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';

import { filter } from 'rxjs/operators';

import { Breakpoints } from './media/breakpoints.enum';
import { BreakpointsService } from '../services/breakpoints.service';
import { SubDirective } from './sub.directive';

@Directive({
  selector: '[hideFor]',
  standalone: true,
})
export class HideForDirective extends SubDirective implements OnChanges {
  @Input('hideFor') config: {
    urls: string[],
    resolutions?: Breakpoints[],
    matching?: 'exact' | 'startWith',
    showFor?: boolean,
  }[];

  constructor(private breakpointsService: BreakpointsService,
              private router: Router,
              private templateRef: TemplateRef<any>,
              private viewContainer: ViewContainerRef) {
    super();
    this.observeRouter();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.config) {
      this.initDefaultConfig();
    }
  }

  private observeRouter(): void {
    this.subscription.add(
      this.router.events.pipe(filter(e => e instanceof NavigationStart)).subscribe((event: any) => {
        const condition = this.config.some(element => {
          return (element.matching === 'exact'
              ? element.urls.some(url => event.url === url)
              : element.urls.some(url => (event.url as string).startsWith(url))
          ) && element.resolutions.includes(this.breakpointsService.currentBreakpoint)
        });
        const showFor = this.config.some(element => element.showFor);

        if (condition) {
          this.viewContainer.clear();
          if (showFor) {
            this.viewContainer.createEmbeddedView(this.templateRef);
          }
        } else {
          this.viewContainer.clear();
          if (!showFor) {
            this.viewContainer.createEmbeddedView(this.templateRef);
          }
        }
      })
    );
  }

  private initDefaultConfig(): void {
    this.config.forEach(element => {
      if (!element.matching) {
        element.matching = 'exact';
      }
      if (element.resolutions?.length == null) {
        element.resolutions = [
          Breakpoints.DESKTOP, Breakpoints.TABLET, Breakpoints.LANDSCAPE, Breakpoints.PORTRAIT
        ];
      }
    });
  }
}
