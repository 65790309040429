import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, OnInit, Output } from '@angular/core';

import { MatExpansionModule } from '@angular/material/expansion';

import { ElementLinkComponent } from '../element-link/element-link.component';
import { ElementSeparatorComponent } from '../element-separator/element-separator.component';
import { ExpansionPanelContent } from '../sidenav.component';
import { RouterLinksPipe } from './pipes/router-links.pipe';

@Component({
  selector: 'app-expansion-panel',
  templateUrl: './expansion-panel.component.html',
  styleUrls: ['./expansion-panel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    ElementLinkComponent,
    ElementSeparatorComponent,
    MatExpansionModule,
    RouterLinksPipe
],
})
export class ExpansionPanelComponent implements OnInit {
  @HostBinding('class.active-panel') @Input({ required: true }) protected readonly isActive = false;

  @Input({ required: true }) protected readonly content: ExpansionPanelContent[];
  @Input({ required: true }) protected readonly name: string;
  @Input({ required: true }) protected readonly index: number;
  @Input({ required: true }) protected readonly currentIndex: number;
  @Input({ required: true }) protected readonly subIndex: number;
  @Input({ required: true }) protected readonly svgId: string;

  @Output() protected readonly changeSubIndex$ = new EventEmitter<number>();

  protected abbreviations: string[];
  protected links: string[];
  protected smallSvgIds: string[];
  protected urls: string[];

  ngOnInit(): void {
    this.abbreviations = this.content.map(item => item.abbreviation);
    this.links = this.content.map(item => item.name);
    this.smallSvgIds = this.content.map(item => item.smallSvgId);
    this.urls = this.content.map(item => item.url);
  }

  changeSubIndex(index: number): void {
    this.changeSubIndex$.emit(index);
  }
}
