@if (book) {
  <section>
    <div class="center flex-center image-container">
      <div class="text">
        @if (book.bookDescription) {
          @for (paragraph of book.bookDescription; track paragraph) {
            <app-dynamic-page-paragraph
              [paragraph]="paragraph"
            ></app-dynamic-page-paragraph>
          }
        }
      </div>
      <app-swap-images *onlyDesktopTabletLandscape class="container"
        [frontUrl]="book.url.frontUrl"
        [backUrl]="book.url.backUrl"
        [frontAlt]="book.alt.frontCover"
        [backAlt]="book.alt.backCover"
      ></app-swap-images>
    </div>
    <div class="center flex-center image-container" *onlyPortrait>
      <app-swap-images class="container"
        [frontUrl]="book.url.frontUrl"
        [backUrl]="book.url.backUrl"
        [frontAlt]="book.alt.frontCover"
        [backAlt]="book.alt.backCover"
      ></app-swap-images>
    </div>
    @if (book.description) {
      @for (paragraph of book.description; track paragraph) {
        <app-dynamic-page-paragraph
          [paragraph]="paragraph"
        ></app-dynamic-page-paragraph>
      }
    }
  </section>
  @if (book.url.pdfId) {
    <section class="center">
      <app-header [title]="book.header || 'Pobierz książkę'"></app-header>
      <div class="flex-center">
        <app-book-download
          [epubUrl]="book.url.epubId | googleUrl"
          [mobiUrl]="book.url.mobiId | googleUrl"
          [pdfUrl]="book.url.pdfId | googleUrl"
          [googleUrl]="book.url.google"
          >
          @if (book.download) {
            @for (paragraph of book.download; track paragraph) {
              <app-dynamic-page-paragraph
                [paragraph]="paragraph"
              ></app-dynamic-page-paragraph>
            }
          }
        </app-book-download>
      </div>
    </section>
  }
}
