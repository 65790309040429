/* src/app/tables/apologetics/table-apologetics.component.scss */
:host .question-container {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 3rem;
}
@media only screen and (min-width: 320px) and (max-width: 479px) {
  :host .question-container {
    justify-content: center;
    margin-top: 1rem;
  }
}
/*# sourceMappingURL=table-apologetics.component.css.map */
