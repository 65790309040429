import { ChangeDetectionStrategy, ChangeDetectorRef, Component, inject, OnInit } from '@angular/core';


import { NAVIGATION_ITEMS, NavigationItem } from '../../services/navigation-cache/navigation-cache.model';
import { NavigationCacheService } from '../../services/navigation-cache/navigation-cache.service';
import { SharedPipesModule } from '../../pipes/shared-pipes.module';
import { StudiesRow, TableCommentariesComponent as TableComponent } from '../../components/shared-tables/commentaries/table-commentaries.component';
import { SubComponent } from '../../components/utils/sub/sub.component';
import { TableHelper } from '../table.helper';
import { BreakpointsService } from '../../services/breakpoints.service';

@Component({
  templateUrl: './table-commentaries.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    SharedPipesModule,
    TableComponent
],
})
export class TableCommentariesComponent extends SubComponent implements OnInit {
  protected data: StudiesRow[] = [];
  protected isError = false;
  protected isLoading = false;

  protected readonly breakpointService= inject(BreakpointsService);
  private readonly cdr = inject(ChangeDetectorRef);
  private readonly navigationCacheService = inject(NavigationCacheService);

  ngOnInit(): void {
    this.getNavigationItemFromStorage();
    if (!this.data) {
      this.fetchNavigation();
    }
  }

  private getNavigationItemFromStorage(): void {
    this.data = this.mapToDataRow(TableHelper.getNavigationItemFromStorage(NAVIGATION_ITEMS.KOMENTARZE));
  }

  private fetchNavigation(): void {
    this.isLoading = true;
    this.subscription.add(this.navigationCacheService.navigation$.subscribe(value => {
      if (value.isError) {
        this.isError = true;
      } else {
        this.data = this.mapToDataRow(TableHelper.getNavigationItem(value.data, NAVIGATION_ITEMS.KOMENTARZE));
      }
      this.isLoading = false;
      this.cdr.markForCheck();
    }));
  }

  private mapToDataRow(item: NavigationItem[]): StudiesRow[] {
    return item?.map(element => {
      return {
        book: element.title,
        chapter: element.chapter,
        date: new Date(Date.parse(element.date)),
        description: element.description,
        subtitle: element.subtitle,
        url: element.url,
      } as StudiesRow });
  }
}
