import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'queryParamValue',
  standalone: true,
})
export class QueryParamValuePipe implements PipeTransform {
  transform(link: string): string {
    return link
      .split('/')
      .splice(-1, 1)[0]
      .split(' ')
      .join('-')
      .toLowerCase();
  }
}
