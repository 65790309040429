import { ActivatedRoute, Router } from '@angular/router';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostBinding, inject, OnInit, } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { ViewportScroller } from '@angular/common';

import { Observable, of } from 'rxjs';

import { BreakpointsService } from '../../services/breakpoints.service';
import { DynamicPage, DynamicPageError, DynamicPageResponse } from '../../services/dynamic-page/dynamic-page.model';
import { DynamicPageService } from '../../services/dynamic-page/dynamic-page.service';
import { StateService } from '../../services/state/state.service';
import { SubComponent } from '../../components/utils/sub/sub.component';

@Component({
  templateUrl: './dynamic-page.component.html',
  styleUrls: ['./dynamic-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DynamicPageComponent extends SubComponent implements OnInit {
  code: string;
  page: DynamicPage;

  errorStatus: DynamicPageError;
  isLoading = true;

  protected readonly isCommentary: boolean;

  @HostBinding('class.scroll-nav-collapsed') scrollNavCollapsed: boolean;

  protected breakpointsService = inject(BreakpointsService);
  protected dynamicPageService = inject(DynamicPageService);
  private cdr = inject(ChangeDetectorRef);
  private route = inject(ActivatedRoute);
  private router = inject(Router);
  private stateService = inject(StateService);
  private viewportScroller = inject(ViewportScroller);

  constructor() {
    super();
  }

  ngOnInit(): void {
    this.setLastUrl();
    const code = this.route.snapshot.data.code;
    if (code) {
      this.idChange(code);
    } else {
      this.subscription.add(this.route.paramMap.subscribe(params => this.idChange(params.get('id'))));
    }
  }

  protected fetchPage(id: string): Observable<DynamicPageResponse> {
    return of(null);
  }

  private idChange(id: string): void {
    this.page = null;
    this.isLoading = true;
    this.fetchPage(id).subscribe({
      next: res => this.success(res),
      error: err => this.error(err),
    });
  }

  private success(res: DynamicPageResponse): void {
    this.page = res.page;
    this.code = res.code;
    this.scrollNavCollapsed = !this.page.tableOfContents;
    this.isLoading = false;
    this.setLastUrl();
    setTimeout(() => this.viewportScroller.scrollToAnchor(this.route.snapshot.fragment), 1000);
    this.errorStatus = null;
    this.cdr.markForCheck();
  }

  private error(err: HttpErrorResponse): void {
    this.page = null;
    this.isLoading = false;
    this.errorStatus = err.status;
    this.cdr.markForCheck();
  }

  private setLastUrl(): void {
    this.stateService.setLastUrl(this.router.url);
  }
}
