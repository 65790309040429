import { BreakpointObserver } from '@angular/cdk/layout';
import { Directive, TemplateRef, ViewContainerRef } from '@angular/core';

import { Breakpoints } from '../../directives/media/breakpoints.enum';
import { BreakpointsService } from '../../services/breakpoints.service';
import { MenuService } from '../../services/menu.service';
import { SubDirective } from '../../directives/sub.directive';

@Directive({
  selector: '[hideScrollTopButton]',
  standalone: true,
})
export class HideScrollTopButtonDirective extends SubDirective {
  private isCreated: boolean;

  constructor(private breakpointsService: BreakpointsService,
              private breakpointObserver: BreakpointObserver,
              private menuService: MenuService,
              private templateRef: TemplateRef<any>,
              private viewContainer: ViewContainerRef) {
    super();
    this.observeBreakpoint();
    this.observeMenu();
  }

  private observeMenu(): void {
    this.subscription.add(
      this.menuService.toggle$.subscribe(openMenu => {
        if (this.breakpointsService.isDesktop || !this.breakpointsService.isDesktop && !openMenu) {
          if (!this.isCreated) {
            this.viewContainer.clear();
            this.viewContainer.createEmbeddedView(this.templateRef);
          }
          this.isCreated = true;
        } else {
          this.viewContainer.clear();
          this.isCreated = false;
        }
      })
    );
  }

  private observeBreakpoint(): void {
    this.subscription.add(
      this.breakpointObserver.observe([`(min-width: ${Breakpoints.DESKTOP}px)`])
        .subscribe(value => {
          if(value.matches || (!value.matches && !this.menuService.isOpen)) {
            if (!this.isCreated) {
              this.viewContainer.clear();
              this.viewContainer.createEmbeddedView(this.templateRef);
            }
            this.isCreated = true;
          } else {
            this.viewContainer.clear();
            this.isCreated = false;
          }
        })
    );
  }
}
