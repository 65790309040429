import {
  ChangeDetectionStrategy,
  Component,
  inject,
  OnInit,
} from '@angular/core';

import { RouterLink } from '@angular/router';

import { bibleCards, booksCard, musicCard, newsCards, otherCards, strongCards, studiesCards } from './home.data';
import { CardComponent } from './card/card.component';
import { CarouselComponent } from './carousel/carousel.component';
import { environment } from '../../../environments/environment';
import { MediaDirectivesModule } from '../../directives/media/media-directives.module';
import { NewsletterComponent } from '../newsletter/newsletter.component';
import { SeoService } from '../../services/seo/seo.service';
import { SocialComponent } from './social/social.component';
import { WelcomeComponent } from './welcome/welcome.component';

@Component({
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CardComponent,
    CarouselComponent,
    MediaDirectivesModule,
    NewsletterComponent,
    RouterLink,
    SocialComponent,
    WelcomeComponent
],
})
export class HomeComponent implements OnInit {
  protected readonly environment = environment;

  protected readonly bibleCards = bibleCards;
  protected readonly strongCards = strongCards;
  protected readonly newsCards = newsCards;
  protected readonly studiesCards = studiesCards;
  protected readonly booksCard = booksCard;
  protected readonly musicCard = musicCard;
  protected readonly otherCards = otherCards;

  private readonly seoService = inject(SeoService);

  ngOnInit(): void {
    this.initSeo();
  }

  private initSeo(): void {
    this.seoService.init({
      title: null,
      description: 'Apologetyka biblijna to szeroka platforma apologetyczna udostępniająca przekłady online, narzędzia badawcze, komentarze i opracowania tekstów biblijnych.',
      ogUrl: `${environment.domain}`,
      twitterImageAlt: 'Apologetyka Biblijna',
    });
  }
}
