import { BreakpointObserver } from '@angular/cdk/layout';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  inject,
  OnInit,
  signal,
  ViewChild
} from '@angular/core';
import { MAT_AUTOCOMPLETE_DEFAULT_OPTIONS } from '@angular/material/autocomplete';
import { MatDrawer, MatSidenavModule } from '@angular/material/sidenav';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';

import { ActiveRouteService } from '../../services/active-route.service';
import { Breakpoints } from '../../directives/media/breakpoints.enum';
import { BreakpointsService } from '../../services/breakpoints.service';
import { ElementLinkComponent } from './element-link/element-link.component';
import { ElementSeparatorComponent } from './element-separator/element-separator.component';
import { ExpansionPanelComponent } from './expansion-panel/expansion-panel.component';
import { LogoComponent } from '../logo/logo.component';
import { MediaDirectivesModule } from '../../directives/media/media-directives.module';
import { MenuService } from '../../services/menu.service';
import { NAVIGATION_ICONS, NAVIGATION_ITEMS } from '../../services/navigation-cache/navigation-cache.model';
import { SearchInputComponent } from '../search-input/search-input.component';
import { SubComponent } from '../utils/sub/sub.component';
import { TopBarComponent } from '../top-bar/top-bar.component';
import { TopBarService } from '../../services/top-bar.service';

export interface ExpansionPanel {
  content: ExpansionPanelContent[];
  link: SidenavLink;
  svgId: string;
}

export interface ExpansionPanelContent {
  abbreviation?: string;
  name: string;
  url?: string;
  smallSvgId?: string;
}

export interface SidenavLink {
  name: string;
  svgId?: string;
  altLink?: string;
}

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    ElementLinkComponent,
    ElementSeparatorComponent,
    ExpansionPanelComponent,
    LogoComponent,
    MatExpansionModule,
    MatIconModule,
    MatSidenavModule,
    MediaDirectivesModule,
    RouterModule,
    SearchInputComponent,
  ],
  providers: [
    BreakpointObserver,
    {
      provide: MAT_AUTOCOMPLETE_DEFAULT_OPTIONS,
      useFactory: (observer: BreakpointObserver) => {
        const isDesktop = observer.isMatched([`(min-width: ${Breakpoints.DESKTOP}px)`]);
        return { overlayPanelClass: !isDesktop ? 'sidenav' : '' }
      },
      deps: [BreakpointObserver]
    }
  ],
  host: {
    '[class.top-bar-hidden]': 'topBarHidden()',
  }
})
export class SidenavComponent extends SubComponent implements OnInit {

  navElements: (SidenavLink | ExpansionPanel)[] = [
    { link: { name: NAVIGATION_ITEMS.BIBLIA }, svgId: NAVIGATION_ICONS.get(NAVIGATION_ITEMS.BIBLIA),
      content: [
        { name: 'przekłady biblijne', url: 'biblia/przeklady', smallSvgId: 'book-ribbon' },
        { name: 'wyszukiwarka', url: 'biblia/wyszukaj', smallSvgId: 'search' },
        { name: 'opisy przekładów', url: 'biblia/opisy', smallSvgId: 'book-spark' },
      ]
    },
    { link: { name: NAVIGATION_ITEMS.BIBLIA_STRONGA }, svgId: NAVIGATION_ICONS.get(NAVIGATION_ITEMS.BIBLIA_STRONGA),
      content: [
        { name: 'UBG Stronga', url: 'strong/ubg', smallSvgId: 'ubg' },
        { name: 'konkordancja', url: 'strong/konkordancja', smallSvgId: 'numbers' },
      ]
    },
    { link: { name: NAVIGATION_ITEMS.KOMENTARZE }, svgId: NAVIGATION_ICONS.get(NAVIGATION_ITEMS.KOMENTARZE),
      content: [
        { name: 'Pierwszy List do Koryntian', abbreviation: '1Kor' },
      ]
    },
    { name: NAVIGATION_ITEMS.OPRACOWANIA, svgId: NAVIGATION_ICONS.get(NAVIGATION_ITEMS.OPRACOWANIA) },
    { name: NAVIGATION_ITEMS.APOLOGETYKA, svgId: NAVIGATION_ICONS.get(NAVIGATION_ITEMS.APOLOGETYKA) },
    { link: { name: 'książki' }, svgId: NAVIGATION_ICONS.get(NAVIGATION_ITEMS.KSIAZKI),
      content: [
        { name: 'Lista polecanych', url: NAVIGATION_ITEMS.KSIAZKI, smallSvgId: 'search' },
        { name: 'Kościół Pielgrzym', url: NAVIGATION_ITEMS.KSIAZKI + '/kosciol-pielgrzym', abbreviation: 'E.B.' },
        { name: 'Psychologia i kościół', url: NAVIGATION_ITEMS.KSIAZKI + '/psychologia-i-kosciol', abbreviation: 'D.H.' },
        { name: 'Inwazja okultyzmu', url: NAVIGATION_ITEMS.KSIAZKI + '/inwazja-okultyzmu', abbreviation: 'D.H.' },
        { name: 'Zwiedzione chrześcijaństwo', url: NAVIGATION_ITEMS.KSIAZKI + '/zwiedzione-chrzescijanstwo', abbreviation: 'D.H.' },
      ]
    },
  ];

  protected readonly aboutElements: (SidenavLink | ExpansionPanel)[] = [
    { link: { name: NAVIGATION_ITEMS.PLATFORMA }, svgId: NAVIGATION_ICONS.get(NAVIGATION_ITEMS.PLATFORMA),
      content: [
        { name: 'o autorze', url: 'o-autorze', smallSvgId: 'account_circle' },
        { name: 'o platformie', url: 'o-platformie', smallSvgId: 'avatar' },
        { name: 'kontakt', url: 'kontakt', smallSvgId: 'mail_outline' },
      ]
    },
  ]

  protected currentIndex: number;
  protected currentSubIndex: number;
  protected mode: 'side' | 'over' = 'side';

  protected readonly topBarHidden = signal(false);

  @ViewChild('drawer', { static: true }) private readonly drawer: MatDrawer;

  private readonly activeRouteService = inject(ActiveRouteService);
  private readonly breakpointObserver = inject(BreakpointObserver);
  private readonly breakpointsService = inject(BreakpointsService);
  private readonly cdr = inject(ChangeDetectorRef);
  private readonly menuService = inject(MenuService);
  private readonly topBarService = inject(TopBarService);

  ngOnInit(): void {
    this.observeToggle();
    this.observeRouteChange();
    this.observeBreakpointsChanges();
    this.observeTopBarHidden();
  }

  protected changeRouteIndex(index: number): void {
    this.activeRouteService.changeIndexes(index, null);
    this.closeSideNavIfNeeded();
  }

  protected changeRouteSubIndex(index: number, subIndex: number) {
    this.activeRouteService.changeIndexes(index, subIndex);
    this.closeSideNavIfNeeded();
  }

  protected onOpenedChange(): void {
    this.updateSidenavClassOnHtmlElement();
  }

  protected onSelectionChange(): void {
    this.closeSideNavIfNeeded();
  }

  protected clickLogoElement(): void {
    if(this.topBarHidden()) {
      this.activeRouteService.changeIndexes(TopBarComponent.clearIndex, null);
      this.topBarHidden.set(false);
      this.topBarService.showTopBar();
    }
  }

  private closeSideNavIfNeeded(): void {
    if (this.mode === 'over') {
      this.menuService.toggle();
    }
  }

  private observeToggle(): void {
    this.subscription.add(this.menuService.toggle$.subscribe(() => {
      this.drawer.toggle();
      this.cdr.markForCheck();
    }));
  }

  private observeRouteChange(): void {
    this.subscription.add(this.activeRouteService.routeChange$.subscribe(route => {
      this.currentIndex = route.index;
      this.currentSubIndex = route.subIndex;
      this.cdr.markForCheck();
    }));
  }

  private observeBreakpointsChanges(): void {
    this.subscription.add(this.breakpointObserver.observe(['(min-width: 1025px)'])
      .subscribe(value => this.mode = value.matches ? 'side' : 'over'));
  }

  private observeTopBarHidden(): void {
    if (this.breakpointsService.isDesktop) {
      this.subscription.add(this.topBarService.hidden$.subscribe(isHidden => {
        this.topBarHidden.set(isHidden);
        this.cdr.markForCheck();
      }));
    }
  }

  private updateSidenavClassOnHtmlElement(): void {
    if (this.mode === 'over') {
      document.documentElement.classList.toggle('sidenav');
    } else {
      document.documentElement.classList.remove('sidenav');
    }
  }
}
