import { Pipe, PipeTransform } from '@angular/core';
import { ChipsPipe } from '../../../../pipes/chips.pipe';

@Pipe({
  name: 'chips',
  standalone: true,
})
export class TableGenericChipsPipe implements PipeTransform {
  transform(a: any): string[] {
    return ChipsPipe.chipsKeys;
  }
}
