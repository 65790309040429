import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';


@Component({
  selector: 'app-element-separator',
  template: '',
  styleUrls: ['./element-separator.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: []
})
export class ElementSeparatorComponent {
  @HostBinding('class.small-separator') @Input() protected readonly small = false;
}
