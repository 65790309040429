<aside>
  <mat-drawer-container class="drawer-container">
    <mat-drawer #drawer [mode]="mode" class="drawer" (openedChange)="onOpenedChange()">
      <div>
        <app-search-input *onlyPortraitLandscapeTablet (selectionChange$)="onSelectionChange()" class="clear-btn-inside"></app-search-input>

        <app-element-separator></app-element-separator>

        @for (element of navElements; track element.svgId; let index = $index) {
          @if (element.content) {
            <app-expansion-panel
              [isActive]="currentIndex === index"
              [content]="element.content"
              [name]="element.link.name"
              [subIndex]="currentSubIndex"
              [currentIndex]="currentIndex"
              [index]="index"
              [svgId]="element.svgId"
              (changeSubIndex$)="changeRouteSubIndex(index, $event)">
              <mat-icon class="nav-icon" [svgIcon]="'icons:' + element.svgId"></mat-icon>
              {{ element.link.name }}
            </app-expansion-panel>
          }
          @if (!element.content) {
            <app-element-link
              [isActive]="currentIndex === index || index === 5"
              [name]="element.name"
              [link]="element.altLink"
              [svgId]="element.svgId"
              (click)="changeRouteIndex(index)"
            ></app-element-link>
          }
          <app-element-separator></app-element-separator>
        }
      </div>

      <div>
        <app-element-separator *onlyDesktopTablet></app-element-separator>

        @for (element of aboutElements; track element.svgId; let index = $index) {
          @if (element.content) {
            <app-expansion-panel [isActive]="currentIndex === index"
                                 [content]="element.content"
                                 [name]="element.link.name"
                                 [subIndex]="currentSubIndex"
                                 [currentIndex]="currentIndex"
                                 [index]="index"
                                 [svgId]="element.svgId"
                                 class="last-element"
                                 (changeSubIndex$)="clearIndexRoute()">
              <mat-icon class="nav-icon small-icon" [svgIcon]="'icons:' + element.svgId"></mat-icon>

              {{ element.link.name }}
            </app-expansion-panel>
          } @else {
            <app-element-link [isActive]="currentIndex === index"
                              [name]="element.name"
                              [link]="element.altLink"
                              [svgId]="element.svgId"
                              (click)="clearIndexRoute()"
            ></app-element-link>
          }
        }

        <app-element-separator *onlyPortraitLandscape></app-element-separator>
      </div>
    </mat-drawer>

    <mat-drawer-content>
      <ng-content></ng-content>
    </mat-drawer-content>
  </mat-drawer-container>
</aside>
