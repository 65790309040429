import { Pipe, PipeTransform } from '@angular/core';

import { ChipsPipe } from '../../../../pipes/chips.pipe';

@Pipe({
  name: 'articleChipsTooltip',
  standalone: true,
})
export class ArticleChipsTooltipPipe implements PipeTransform {
  transform(chips: string[]): string {
    return chips?.map(chip => ChipsPipe.chips.get(chip)).join(', ');
  }
}
