<header>
  <div class="nav-container">
    <div class="actions-wrapper">
      <button class="nav-item menu" (click)="openMenu()" aria-label="menu" matTooltip="menu">
        <mat-icon class="header-icon" svgIcon="icons:menu-top"></mat-icon>
      </button>

      @if (!!this.stateService.lastUrl) {
        <a *onlyPortraitLandscape
          aria-label="wróć do ostatniego tekstu"
          class="nav-item other-action"
          matTooltip="wróć do ostatniego tekstu"
          [fragment]="(this.stateService.lastUrl | splitHash)[1]"
          [routerLink]="(this.stateService.lastUrl | splitHash)[0]"
          (click)="closeMenu()">
          <mat-icon class="other-action-icon" svgIcon="icons:last_article"></mat-icon>
        </a>
        <a *onlyDesktopTablet
          aria-label="wróć do ostatniego tekstu"
          class="nav-item other-action"
          matTooltip="wróć do ostatniego tekstu"
          [fragment]="(this.stateService.lastUrl | splitHash)[1]"
          [routerLink]="(this.stateService.lastUrl | splitHash)[0]">
          <mat-icon class="other-action-icon" svgIcon="icons:last_article"></mat-icon>
        </a>
      }

      @if (theme === 'dark') {
        <button
          class="nav-item other-action"
          aria-label="jasny szablon strony"
          matTooltip="jasny szablon"
          (click)="changeTheme()">
          <mat-icon class="other-action-icon" svgIcon="icons:light-mode"></mat-icon>
        </button>
      }

      @if (theme === 'light') {
        <button
          class="nav-item other-action"
          aria-label="ciemny szablon strony"
          matTooltip="ciemny szablon"
          (click)="changeTheme()">
          <mat-icon class="other-action-icon" svgIcon="icons:dark-mode"></mat-icon>
        </button>
      }
    </div>

    <app-search-input *onlyDesktop></app-search-input>
  </div>

  <div class="title-container">
    <a class="title-text" routerLink="/" (click)="clickLogoElement()">
      <mat-icon class="logo" svgIcon="logo"></mat-icon>
    </a>
  </div>
</header>
