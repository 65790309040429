import { ChangeDetectionStrategy, Component, EventEmitter, Output } from '@angular/core';

import { SubComponent } from '../../../utils/sub/sub.component';

@Component({
  template: ``,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
})
export class GenericFilterEmitterComponent<T> extends SubComponent {
  @Output() private search$ = new EventEmitter<T>();

  applyFilter(value: T): void {
    this.search$.emit(value);
  }
}
