@if (paragraph) {
<p [ngClass]="{
  'center': paragraph[0].center,
  'no-padding': paragraph[0].noPadding || noPadding,
  'no-padding-top': paragraph[0].noPaddingTop,
  'no-padding-bottom': paragraph[0].noPaddingBottom }">
    @for (paragraph_item of paragraph; track paragraph_item) {
      @if (paragraph_item.text) {
        @if (paragraph_item.tooltip) {
          <span class="tooltip" matTooltipClass="tooltip-large"
            [matTooltip]="paragraph_item.tooltip" matTooltipPosition="left">
            {{ paragraph_item.text }}<mat-icon class="info" svgIcon="icons:info"></mat-icon>
          </span>
        }
        @if (!paragraph_item.tooltip) {
          <ng-container>{{ paragraph_item.text + ' ' }}</ng-container>
        }
      }
      @if (paragraph_item.bold) {
        @if (paragraph_item.tooltip) {
          <b class="tooltip" matTooltipClass="tooltip-large"
            [matTooltip]="paragraph_item.tooltip" matTooltipPosition="left">
            {{ paragraph_item.bold }}<mat-icon class="info" svgIcon="icons:info"></mat-icon>
          </b>
        }
        @if (!paragraph_item.tooltip) {
          <b>{{ paragraph_item.bold + ' ' }}</b>
        }
      }
      @if (paragraph_item.italic) {
        @if (paragraph_item.tooltip) {
          <span class="italic tooltip" matTooltipClass="tooltip-large"
            [matTooltip]="paragraph_item.tooltip" matTooltipPosition="left">
            {{ paragraph_item.italic }}
            @if (paragraph_item.tooltip) {
              <mat-icon class="info" svgIcon="icons:info"></mat-icon>
            }
          </span>
        }
        @if (!paragraph_item.tooltip) {
          <span class="italic">{{ paragraph_item.italic + ' ' }}</span>
        }
      }
      @if (paragraph_item.boldItalic) {
        @if (paragraph_item.tooltip) {
          <span class="italic bold tooltip" matTooltipClass="tooltip-large"
            [matTooltip]="paragraph_item.tooltip" matTooltipPosition="left">
            {{ paragraph_item.boldItalic }}
            @if (paragraph_item.tooltip) {
              <mat-icon class="info" svgIcon="icons:info"></mat-icon>
            }
          </span>
        }
        @if (!paragraph_item.tooltip) {
          <span class="italic bold">{{ paragraph_item.boldItalic + ' ' }}</span>
        }
      }
      @if (paragraph_item.link) {
        @if (paragraph_item.link.routerLink) {
          <a [routerLink]="paragraph_item.link.routerLink" [class.italic]="paragraph_item.link.italic">
          {{ paragraph_item.link.text || paragraph_item.link.italic }}</a>{{paragraph_item.link.textAfter }}
          {{ ' ' }}
        }
        @if (paragraph_item.link.external) {
          <a [href]="paragraph_item.link.external.href" [class.italic]="paragraph_item.link.italic"
            [target]="paragraph_item.link.external.notBlank ? '_self' : '_blank'"
            [matTooltip]="paragraph_item.link.external.notBlank ? '' : paragraph_item.link.external.access | tooltip">
          {{ paragraph_item.link.text || paragraph_item.link.italic }}</a>{{paragraph_item.link.textAfter }}
          {{ ' ' }}
        }
      }
      @if (paragraph_item.paragraphBible) {
        <app-paragraph-bible [bible]="paragraph_item.paragraphBible"></app-paragraph-bible>
      }
      @if (paragraph_item.strong) {
        <app-paragraph-strong [strong]="paragraph_item.strong"></app-paragraph-strong>
      }
      @if (paragraph_item.bibliographyNo) {
        <app-scroll-handler [index]="paragraph_item.bibliographyNo"></app-scroll-handler>
      }
    }
  </p>
}
