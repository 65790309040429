import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostBinding, OnInit } from '@angular/core';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { RouterLink } from '@angular/router';

import { AppComponent } from 'src/app/app.component';
import { environment } from '../../../../environments/environment';
import { MediaDirectivesModule } from '../../../directives/media/media-directives.module';
import { SnackbarService } from '../../snackbar/snackbar.service';
import { SnackBarType } from '../../snackbar/snackbar-type.enum';

@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    ClipboardModule,
    MatExpansionModule,
    MatIconModule,
    MediaDirectivesModule,
    RouterLink,
  ]
})
export class WelcomeComponent implements OnInit {
  protected readonly environment = environment;
  protected readonly AppComponent = AppComponent;

  protected expanded: boolean;
  protected disableExpansionPanel = true;

  @HostBinding('class.expanded') isExpandStyle: boolean;
  @HostBinding('class.expand-loaded') isExpandLoadedStyle = false;
  @HostBinding('class.initial') isInitialStyle = true;
  @HostBinding('class.initial-loaded') isInitialLoadedStyle = false;

  constructor(private cdr: ChangeDetectorRef,
              private snackbarService: SnackbarService) {}

  ngOnInit(): void {
    this.initExpandedFromStorage();
    this.setIsInitialLoadedStyleAfterDelay();
  }

  onClick(): void {
    this.snackbarService.open('Skopiowano e-mail', SnackBarType.COPY, 2000);
  }

  expandedChange(value: boolean): void {
    WelcomeComponent.setWelcomePanelExpand(value);
    this.isExpandStyle = value;
    this.isInitialStyle = false;
    this.setIsExpandLoadedStyleAfterDelay(value);
  }

  private initExpandedFromStorage(): void {
    const welcomePanelExpand = WelcomeComponent.getWelcomePanelExpand();
    this.expanded = welcomePanelExpand === null ? true : welcomePanelExpand;
  }

  private setIsInitialLoadedStyleAfterDelay(): void {
    setTimeout(() => {
      this.isInitialLoadedStyle = true;
      this.disableExpansionPanel = false;
      this.cdr.markForCheck();
    }, 1500);
  }

  private setIsExpandLoadedStyleAfterDelay(value: boolean): void {
    this.disableExpansionPanel = true;
    setTimeout(() => {
      this.isExpandLoadedStyle = value;
      this.disableExpansionPanel = false;
      this.cdr.markForCheck();
    }, 500);
  }

  private static getWelcomePanelExpand(): boolean {
    switch (localStorage.getItem('welcome-panel-expand')) {
      case 'false': return false;
      case 'true': return true;
      default: return null;
    }
  }

  private static setWelcomePanelExpand(open: boolean): void {
    localStorage.setItem('welcome-panel-expand', JSON.stringify(open));
  }
}
