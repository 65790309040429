import { ChangeDetectionStrategy, Component, inject } from '@angular/core';

import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';

import { ActiveRouteService } from '../../services/active-route.service';

@Component({
  selector: 'app-simple-footer',
  templateUrl: './simple-footer.component.html',
  styleUrls: ['./simple-footer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    MatIconModule,
    RouterModule
]
})
export class SimpleFooterComponent {
  private activeRouteService = inject(ActiveRouteService);

  protected clickLogoElement(): void {
    this.activeRouteService.changeIndexes(ActiveRouteService.clearIndex, null);
  }
}
