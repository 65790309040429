@if (maxResults > 0) {
  <app-header title="Odnośniki biblijne"></app-header>
  <table id="bible-references" mat-table [dataSource]="dataSource">
    <ng-container matColumnDef="bible">
      <th mat-header-cell *matHeaderCellDef>Księga</th>
      <td mat-cell *matCellDef="let element">
        @if ((element.verses | searchVerseNo); as verse) {
          <a aria-label="Przejdź do wersetu" class="bible-link" matTooltip="przejdź do wersetu" matTooltipPosition="left"
            routerLink="/biblia/przeklady" queryParamsHandling="merge"
            [queryParams]="getQueryParams(element.book.abbreviation, (element.chapter || 1), element.verses_range.split('-')?.at(0))"
            >
            {{ element.book.abbreviation | abbreviation }}. {{ element.chapter || 1 }},{{ element.verses_range }}
          </a>
        }
      </td>
    </ng-container>
    <ng-container matColumnDef="text">
      <th mat-header-cell *matHeaderCellDef>Tekst
        @if (isLoading) {
          <mat-spinner diameter="40"></mat-spinner>
        }
      </th>
      <td mat-cell *matCellDef="let element; let i = index;">
        @for (verseItem of element.verses; track verseItem; let j = $index) {
          @if (element.verses.length !== 1) {
            ({{ verseItem.verse }})
          }
          {{ verseItem.text }}
        }
        <div *onlyPortrait class="portrait-abbreviation">
          @if ((element.verses | searchVerseNo); as verse) {
            <a aria-label="Przejdź do wersetu" class="bible-link" matTooltip="przejdź do wersetu" matTooltipPosition="left"
              routerLink="/biblia/przeklady" queryParamsHandling="merge"
              [queryParams]="getQueryParams(element.book.abbreviation, (element.chapter || 1), element.verses_range.split('-')?.at(0))"
              >
              {{ element.book.abbreviation | abbreviation }}. {{ (element.chapter || 1) }},{{ element.verses_range }}
            </a>
          }
        </div>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns">
    </tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
  <mat-paginator [pageSizeOptions]="pageSizeOptions" [length]="data?.all_results"
    [disabled]="isLoading"
    showFirstLastButtons aria-label="Zmiana strony wyników wyszukiwania"
    (page)="paginationChange($event)"
  ></mat-paginator>
}
