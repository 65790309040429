import { ClipboardModule } from '@angular/cdk/clipboard';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { MediaDirectivesModule } from '../directives/media/media-directives.module';
import { SharedPipesModule } from '../pipes/shared-pipes.module';
import { TableApologeticsComponent } from './apologetics/table-apologetics.component';
import { TableCommentariesComponent } from './commentaries/table-commentaries.component';
import { TableElaborationComponent } from './elaboration/table-elaboration.component';

@NgModule({
  imports: [
    ClipboardModule,
    CommonModule,
    MatIconModule,
    MediaDirectivesModule,
    RouterModule,
    SharedPipesModule,
    TableApologeticsComponent,
    TableCommentariesComponent,
    TableElaborationComponent,
  ],
})
export class TablesModule {}
