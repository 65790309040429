import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { ApologeticsPageComponent } from './pages/dynamic-page/apologetics/apologetics-page.component';
import { CommentaryPageComponent } from './pages/dynamic-page/commentary/commentary-page.component';
import { ElaborationPageComponent } from './pages/dynamic-page/elaboration/elaboration-page.component';
import { environment } from '../environments/environment';
import { GoogleUrlPipe } from './pipes/google-url.pipe';
import { HomeComponent } from './components/home/home.component';
import { NAVIGATION_ITEMS } from './services/navigation-cache/navigation-cache.model';
import { QuestionPageComponent } from './pages/common/question/question-page.component';
import { TableApologeticsComponent } from './tables/apologetics/table-apologetics.component';
import { TableCommentariesComponent } from './tables/commentaries/table-commentaries.component';
import { TableElaborationComponent } from './tables/elaboration/table-elaboration.component';

const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
  },
  {
    path: 'biblia',
    loadChildren: () => import('./routes/bible-routing.module').then(m => m.BibleRoutingModule)
  },
  {
    path: 'strong',
    loadChildren: () => import('./routes/strong-routing.module').then(m => m.StrongRoutingModule)
  },
  {
    path: NAVIGATION_ITEMS.APOLOGETYKA,
    children: [
      {
        path: '',
        component: TableApologeticsComponent,
        data: {
          title: 'Dział pytań i odpowiedzi',
          description: 'Dział w popularnym formacie pytań i odpowiedzi (Q&A). Poprzez pytania biblijne rozumie się pytania, do których Biblia bezpośrednio lub pośrednio się odnosi.',
          ogUrl: `${environment.domain}/apologetyka`,
          twitterImageAlt: 'Dział pytań i odpowiedzi',
        }
      },
      {
        path: 'pytanie',
        component: QuestionPageComponent,
        data: {
          title: 'Zadaj pytanie',
          description: 'Zadaj swoje pytanie dotyczące tekstu Biblii, które zostanie opracowane i udostępnione w sekcji Apologetyka platformy Apologetyka Biblijna',
          ogUrl: `${environment.domain}/pytanie`,
          twitterImageAlt: 'Zadaj pytanie',
        }
      },
      {
        path: 'szamanizm-czarnoksiestwo-i-magia',
        component: ApologeticsPageComponent,
        data: {
          code: 'szamanizm-czarnoksiestwo-i-magia',
          title: 'Szamanizm czarnoksięstwo i magia',
          description: 'Szamanizm, czarnoksięstwo i magia (pytanie); Historia okultyzmu w Biblii, konsekwencje praktykowania magii, analiza książek Hunta, zagrożeń w Polsce.',
          ogUrl: `${environment.domain}/apologetyka`,
          twitterImageAlt: 'Apologetyka (pytania)',
        }
      },
      {
        path: ':id',
        component: ApologeticsPageComponent,
      }
    ]
  },
  {
    path: NAVIGATION_ITEMS.OPRACOWANIA,
    children: [
      {
        path: '',
        component: TableElaborationComponent,
        data: {
          title: 'Opracowania tematyczne',
          description: 'W dziale Opracowania czytelnik odnajdzie artykuły i opracowania w zakresie tematycznym. Omawiane są wybrane zagadnienia, a nie poszczególne księgi biblijne.',
          ogUrl: `${environment.domain}/opracowania`,
          twitterImageAlt: 'Opracowania tematyczne',
        }
      },
      {
        path: 'the-chosen-10-kluczowych-obaw',
        component: ElaborationPageComponent,
        data: {
          code: 'the-chosen-10-kluczowych-obaw',
          title: 'Serial The Chosen: 10 kluczowych obaw',
          description: 'Niezwykle popularny serial The Chosen jest opisywany jako „globalny fenomen” i rozwijający się „ruch”, który tworzy podwaliny pod światowe „przebudzenie”.',
          ogUrl: `${environment.domain}/opracowania`,
          ogImage: GoogleUrlPipe.getUrl('1aKNeBZlMGdatGw_dlDwtxx-ZzWZ5MpEA'),
          twitterImageAlt: 'Opracowania tematyczne',
        }
      },
      {
        path: 'tajemnica-krolestwa-niebieskiego',
        component: ElaborationPageComponent,
        data: {
          code: 'tajemnica-krolestwa-niebieskiego',
          title: 'Tajemnica królestwa niebieskiego, Apologetyka Biblijna',
          description: 'Tajemnica królestwa niebieskiego (opracowanie): Słowa tajemnica i objawienie, pojęcie królestwa do którego Jezus odnosił się podczas swojej misji.',
          ogUrl: `${environment.domain}/opracowania`,
          twitterImageAlt: 'Opracowania tematyczne',
        }
      },
      {
        path: 'powrot-do-biblijnego-chrzescijanstwa-1',
        component: ElaborationPageComponent,
        data: {
          code: 'powrot-do-biblijnego-chrzescijanstwa-1',
          title: 'New Age w chrześcijaństwie | Powrót do biblijnego chrześcijaństwa',
          description: 'Zacząłem dostrzegać związki miedzy ruchem promującym narkotyki w Stanach Zjednoczonych, parapsychologią, zjawiskami nadprzyrodzonymi i eksplozją okultyzmu.',
          ogUrl: `${environment.domain}/opracowania`,
          twitterImageAlt: 'Opracowania tematyczne',
        }
      },
      {
        path: 'powrot-do-biblijnego-chrzescijanstwa-2',
        component: ElaborationPageComponent,
        data: {
          code: 'powrot-do-biblijnego-chrzescijanstwa-2',
          title: 'Okultyzm w chrześcijaństwie | Powrót do biblijnego chrześcijaństwa',
          description: 'Czy jest coś, co powinno się wydarzyć przed pochwyceniem Kościoła? Musi nastąpić wielkie odstępstwo, zanim nadejdzie dzień Pański.',
          ogUrl: `${environment.domain}/opracowania`,
          twitterImageAlt: 'Opracowania tematyczne',
        }
      },
      {
        path: 'powrot-do-biblijnego-chrzescijanstwa-3',
        component: ElaborationPageComponent,
        data: {
          code: 'powrot-do-biblijnego-chrzescijanstwa-3',
          title: 'Wizualizacja | Powrót do biblijnego chrześcijaństwa',
          description: 'Kiedyś zamykano ludzi w specjalnych zakładach dla ich dobra, jeśli nie odróżniali fantazji od rzeczywistości. Dzisiaj fantazja jest nową metodą terapii.',
          ogUrl: `${environment.domain}/opracowania`,
          twitterImageAlt: 'Opracowania tematyczne',
        }
      },
      {
        path: 'powrot-do-biblijnego-chrzescijanstwa-4',
        component: ElaborationPageComponent,
        data: {
          code: 'powrot-do-biblijnego-chrzescijanstwa-4',
          title: 'Psychologia chrześcijańska | Powrót do biblijnego chrześcijaństwa',
          description: 'Psychologia zdominowała nie tylko społeczeństwo świeckie. Eksplozja tego zjawiska nastąpiła po II wojnie światowej. Zdominowała również Kościół.',
          ogUrl: `${environment.domain}/opracowania`,
          twitterImageAlt: 'Opracowania tematyczne',
        }
      },
      {
        path: 'powrot-do-biblijnego-chrzescijanstwa-5',
        component: ElaborationPageComponent,
        data: {
          code: 'powrot-do-biblijnego-chrzescijanstwa-5',
          title: 'Egocentryzm | Powrót do biblijnego chrześcijaństwa',
          description: 'Jednym z rezultatów wpływu psychologii jest położenie nacisku na ludzkie ego. Słyszymy to nie tylko od świeckich psychologów, lecz również "chrześcijańskich".',
          ogUrl: `${environment.domain}/opracowania`,
          twitterImageAlt: 'Opracowania tematyczne',
        }
      },
      {
        path: 'powrot-do-biblijnego-chrzescijanstwa-6',
        component: ElaborationPageComponent,
        data: {
          code: 'powrot-do-biblijnego-chrzescijanstwa-6',
          title: 'Ewangelia Mateusza 16 i krzyż | Powrót do biblijnego chrześcijaństwa',
          description: 'Ponad wszystko, powinniśmy zostać ukrzyżowani z Chrystusem, wyrzekając się życia po swojemu i doświadczając zmartwychwstałego życia w Chrystusie.',
          ogUrl: `${environment.domain}/opracowania`,
          twitterImageAlt: 'Opracowania tematyczne',
        }
      },
      {
        path: 'powrot-do-biblijnego-chrzescijanstwa-7',
        component: ElaborationPageComponent,
        data: {
          code: 'powrot-do-biblijnego-chrzescijanstwa-7',
          title: 'Dominionizm, Teologia Królestwa | Powrót do biblijnego chrześcijaństwa',
          description: 'Pochwycenie Kościoła jest tematem kontrowersyjnym. W ostatnich latach dyskusja toczyła się, czy Jezus zabierze nas przed, w środku, czy po wielkim ucisku.',
          ogUrl: `${environment.domain}/opracowania`,
          twitterImageAlt: 'Opracowania tematyczne',
        }
      },
      {
        path: 'powrot-do-biblijnego-chrzescijanstwa-8',
        component: ElaborationPageComponent,
        data: {
          code: 'powrot-do-biblijnego-chrzescijanstwa-8',
          title: 'Pytania i odpowiedzi (Powrót do biblijnego chrześcijaństwa 8)',
          description: 'Pytania i odpowiedzi: joga, reprezentanci New Age, Anonimowi Alkoholicy, odkrywanie prywatnego świata, kreskówki dla dzieci, uzdrawianie wspomnień',
          ogUrl: `${environment.domain}/opracowania`,
          twitterImageAlt: 'Opracowania tematyczne',
        }
      },
      {
        path: 'coz-to-za-milosc',
        component: ElaborationPageComponent,
        data: {
          code: 'coz-to-za-milosc',
          title: 'Cóż to za miłość: Fałszywy obraz Boga w kalwinizmie, Dave Hunt',
          description: 'Streszczenie książki na temat zagrożeń i błędów związanych z kalwinizmem, inaczej teologią reformowaną, napisanej przez apologetę Dave\'a Hunta.',
          ogUrl: `${environment.domain}/opracowania`,
          twitterImageAlt: 'Opracowania tematyczne',
        }
      },
      {
        path: 'kobieta-jadaca-na-bestii',
        component: ElaborationPageComponent,
        data: {
          code: 'kobieta-jadaca-na-bestii',
          title: 'Kobieta jadąca na bestii, Dave Hunt',
          description: 'Kim jest kobieta jadąca na bestii opisana w Księdze Objawienia (Apokalipsy). Zapis nagrania omawiający książkę autorstwa apologetyka Dave\'a Hunta.',
          ogUrl: `${environment.domain}/opracowania`,
          twitterImageAlt: 'Opracowania tematyczne',
        }
      },
      {
        path: 'czego-suwerenny-bog-zrobic-nie-moze',
        component: ElaborationPageComponent,
        data: {
          code: 'czego-suwerenny-bog-zrobic-nie-moze',
          title: 'Czego suwerenny Bóg zrobić nie może, Dave Hunt',
          description: 'Bóg jest miłością i sprawiedliwością. Z tego powodu jest ograniczony. Jednak dając człowiekowi wolność wyboru, nie zaprzeczył w ten sposób swojej suwerenności.',
          ogUrl: `${environment.domain}/opracowania`,
          twitterImageAlt: 'Opracowania tematyczne',
        }
      },
      {
        path: 'slowo-pochwycenie-pojawia-sie-w-biblii',
        component: ElaborationPageComponent,
        data: {
          code: 'slowo-pochwycenie-pojawia-sie-w-biblii',
          title: 'Słowo „pochwycenie” pojawia się w Biblii, Lee Brainard',
          description: 'Słowo pochwycenie pojawia się w Biblii, ang. rapture jest po prostu zangielszczoną formą greckiego słowa ̔αρπαζω (harpazō), które pojawia się w 1Tes.4,17.',
          ogUrl: `${environment.domain}/opracowania`,
          twitterImageAlt: 'Opracowania tematyczne',
        }
      },
      {
        path: 'blogoslawcie-pana',
        component: ElaborationPageComponent,
        data: {
          code: 'blogoslawcie-pana',
          title: 'Błogosławcie Pana!, Dave Hunt',
          description: 'Oczekujemy, że Bóg będzie nam błogosławił, mamy nadzieję i modlimy się o to, zwłaszcza gdy mamy jakąś pilną potrzebę. Ale kto kiedykolwiek myśli o błogosławieniu Boga?',
          ogUrl: `${environment.domain}/opracowania`,
          twitterImageAlt: 'Opracowania tematyczne',
        }
      },
      {
        path: 'napominanie-przepraszanie-przebaczanie',
        component: ElaborationPageComponent,
        data: {
          code: 'napominanie-przepraszanie-przebaczanie',
          title: 'Napominanie, przepraszanie, przebaczanie, Apologetyka Biblijna',
          description: 'Napominanie, przepraszanie i przebaczanie w Biblii. W jaki sposób Bóg okazuje swoje miłosierdzie i czego oczekuje od człowieka. Boża definicja miłości.',
          ogUrl: `${environment.domain}/opracowania`,
          twitterImageAlt: 'Opracowania tematyczne',
        }
      },
      {
        path: 'zaklopotany-przez-dzieje-apostolskie-1348',
        component: ElaborationPageComponent,
        data: {
          code: 'zaklopotany-przez-dzieje-apostolskie-1348',
          title: 'Zakłopotany przez Dzieje Apostolskie 13,48?',
          description: 'Lee Brainard omawia Dz.13,48 pokazując, że przekład tego fragmentu zgodny teologią reformowaną jest błędny w jaki sposób człowiek może zwrócić swoje serce u Bogu.',
          ogUrl: `${environment.domain}/opracowania`,
          twitterImageAlt: 'Opracowania tematyczne',
        }
      },
      {
        path: 'czy-odrzucic-sluzbe-apostola-pawla',
        component: ElaborationPageComponent,
        data: {
          code: 'czy-odrzucic-sluzbe-apostola-pawla',
          title: 'Czy odrzucić służbę apostoła Pawła?',
          description: 'Czy słusznie pojawia się sprzeciw – nawet ze względu na zdrową wiarę – co do demaskowania błędu jako całkowicie negatywnego i pozbawionego prawdziwego zbudowania?',
          ogUrl: `${environment.domain}/opracowania`,
          twitterImageAlt: 'Opracowania tematyczne',
        }
      },
      {
        path: 'potomkowie-adama',
        loadChildren: () => import('./routes/genesis-page-routing.module').then(m => m.GenesisPageRoutingModule)
      },
      {
        path: 'globalny-potop',
        loadChildren: () => import('./routes/flood-page-routing.module').then(m => m.FloodPageRoutingModule)
      },
      {
        path: ':id',
        component: ElaborationPageComponent,
      }
    ]
  },
  {
    path: NAVIGATION_ITEMS.KOMENTARZE,
    children: [
      {
        path: '',
        component: TableCommentariesComponent,
        data: {
          title: 'Komentarze biblijne',
          description: 'Dział Komentarzy Biblijnych poświęcony jest badaniom ksiąg biblijnych. Omawiane są księgi biblijne traktowane jako całość, a nie wybrane zagadnienia.',
          ogUrl: `${environment.domain}/komentarze`,
          twitterImageAlt: 'Komentarze biblijne',
        }
      },
      {
        path: 'pierwszy-list-do-koryntian-streszczenie',
        component: CommentaryPageComponent,
        data: {
          code: 'pierwszy-list-do-koryntian-streszczenie',
          title: 'Streszczenie listu - Komentarz Pierwszego Listu do Koryntian',
          description: 'Pierwszy list do Koryntian: streszczenie rozdziałów, omówienie głównych myśli i zagadnień poruszonych przez apostoła Pawła do społeczności w Koryncie.',
          ogUrl: `${environment.domain}/komentarze`,
          twitterImageAlt: 'Komentarze biblijne',
        }
      },
      {
        path: 'pierwszy-list-do-koryntian-kontekst',
        component: CommentaryPageComponent,
        data: {
          code: 'pierwszy-list-do-koryntian-kontekst',
          title: 'Kontekst listu - Komentarz Pierwszego Listu do Koryntian',
          description: 'Pierwszy list do Koryntian: kontekst listu, autor, adresaci, czas i miejsce napisania listu, pobyt Pawła w Koryncie, rys historyczny i geograficzny.',
          ogUrl: `${environment.domain}/komentarze`,
          twitterImageAlt: 'Komentarze biblijne',
        }
      },
      {
        path: 'pierwszy-list-do-koryntian-1',
        component: CommentaryPageComponent,
        data: {
          code: 'pierwszy-list-do-koryntian-1',
          title: 'Rozdział 1 - Komentarz Pierwszego Listu do Koryntian',
          description: 'Pierwszy list do Koryntian: rozdział 1; Koryntianie otrzymali wszystko w Chrystusie, spory i rozłamy w Koryncie, głoszenie mądrości Bożej, mądrość ludzka.',
          ogUrl: `${environment.domain}/komentarze`,
          twitterImageAlt: 'Komentarze biblijne',
        }
      },
      {
        path: 'pierwszy-list-do-koryntian-2',
        component: CommentaryPageComponent,
        data: {
          code: 'pierwszy-list-do-koryntian-2',
          title: 'Rozdział 2 - Komentarz Pierwszego Listu do Koryntian',
          description: 'Pierwszy list do Koryntian: rozdział 2; Głoszenie przez Pawła mądrości Bożej, wobec mądrości ludzkiej, objawienie mądrości przez Ducha Świętego.',
          ogUrl: `${environment.domain}/komentarze`,
          twitterImageAlt: 'Komentarze biblijne',
        }
      },
      {
        path: 'pierwszy-list-do-koryntian-3',
        component: CommentaryPageComponent,
        data: {
          code: 'pierwszy-list-do-koryntian-3',
          title: 'Rozdział 3 - Komentarz Pierwszego Listu do Koryntian',
          description: 'Pierwszy list do Koryntian: rozdział 3; Koryntianie nie rozumieją mądrości Bożej, służba nauczycieli, wezwanie do porzucenia mądrości ludzkiej.',
          ogUrl: `${environment.domain}/komentarze`,
          twitterImageAlt: 'Komentarze biblijne',
        }
      },
      {
        path: ':id',
        component: CommentaryPageComponent,
      }
    ]
  },
  {
    path: 'o-autorze',
    loadChildren: () => import('./routes/about-author-routing.module').then(m => m.AboutAuthorRoutingModule)
  },
  {
    path: 'o-platformie',
    loadChildren: () => import('./routes/about-platform-routing.module').then(m => m.AboutAuthorRoutingModule)
  },
  {
    path: 'kontakt',
    loadChildren: () => import('./routes/contact-routing.module').then(m => m.ContactRoutingModule)
  },
  {
    path: 'prawa-autorskie',
    loadChildren: () => import('./routes/copyrights-routing.module').then(m => m.CopyrightsRoutingModule)
  },
  {
    path: 'polityka-prywatnosci',
    loadChildren: () => import('./routes/private-policy-routing.module').then(m => m.PrivatePolicyRoutingModule)
  },
  {
    path: 'mapa-platformy',
    loadChildren: () => import('./routes/sitemap-routing.module').then(m => m.SitemapRoutingModule)
  },
  {
    path: 'ksiazki',
    loadChildren: () => import('./routes/books-routing.module').then(m => m.BooksRoutingModule)
  },
  { path: '**', redirectTo: '', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
