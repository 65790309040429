import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'splitHash',
  standalone: true,
})
export class SplitHashPipe implements PipeTransform {
  transform(value: string): [string, string] {
    return value?.split('#') as [string, string] || [null, null];
  }
}
