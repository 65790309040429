/* src/app/components/home/carousel/carousel.component.scss */
:root {
  --color-brand: #4caf50;
  --color-brand-dark: #306239;
  --color-brand-very-dark: #2f5339;
  --color-brand-hover: #2f5339;
  --color-background: #2c303a;
  --color-background-dark: #1e222b;
  --color-background-very-dark: #000;
  --color-background-panel: #303541;
  --color-text-foreground-hover: #4caf50;
  --color-foreground: #1b1b2f;
  --color-header: #ddd;
  --color-text: #ccc;
  --color-text-background-dark: #ccc;
  --color-text-hover-background-dark: #fff;
  --color-text-hover: #fff;
  --color-text-disable: #999;
  --color-disable: #666;
  --color-disable-light: #444;
  --color-box-shadow: #111;
  --color-icons: #1c2a36;
  --color-scrollbar-track: #222;
  --color-scrollbar-thumb: #444;
  --color-scrollbar-thumb-hover: #555;
  --brightness-default: 1.1;
  --brightness-high: 1.5;
  --color-footer-background: #1b1b2f;
  --color-footer: #ccc;
  --color-footer-hover: #4caf50;
  --color-top-bar-background: #1b1b2f;
  --color-top-bar-hover: #4caf50;
  --color-bible-search-strong: #4caf50;
  --color-bible-verse: #4caf50;
  --color-strong-text-disable: #888;
  --color-strong-text-translation: #ddd;
  --color-strong-text-transliteration: #deb887;
  --color-strong-text-root: #8fbc8f;
  --color-strong-text-number: #bbb;
  --color-strong-text-less-import: #aaa;
  --color-concordance-dialog-text-translation: #7fbce1;
  --color-book-info-1: #7eafc9;
  --color-book-info-2: #70bd63;
  --color-book-info-3: #d4dc73;
  --color-book-info-4: #d28d60;
  --color-bulb: #e7e75b;
  --color-breadcrumb: #ccc;
  --color-input-background: #2c303a;
  --color-chip-background: #ccc;
  --color-chip-hover: #000;
  --color-logo: #fff;
  --color-text-rgb:
    204,
    204,
    204;
  --color-text-hover-rgb:
    204,
    204,
    204;
  --color-brand-rgb:
    76,
    175,
    80;
  --color-background-rgb:
    44,
    48,
    58;
  --color-background-dark-rgb:
    30,
    34,
    43;
  --color-social-gradient-1-rgb:
    38,
    64,
    50;
  --color-social-gradient-2-rgb:
    38,
    64,
    50;
}
@media only screen and (min-width: 1024px) {
  .hidden-desktop {
    display: none !important;
  }
}
@media only screen and (min-width: 480px) and (max-width: 767px) {
  .hidden-landscape {
    display: none !important;
  }
}
@media only screen and (min-width: 320px) and (max-width: 479px) {
  .hidden-portrait {
    display: none !important;
  }
}
@media only screen and (min-width: 1024px) {
  .hidden-desktop-tablet {
    display: none !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .hidden-desktop-tablet {
    display: none !important;
  }
}
@media only screen and (min-width: 480px) and (max-width: 767px) {
  .hidden-landscape-portrait {
    display: none !important;
  }
}
@media only screen and (min-width: 320px) and (max-width: 479px) {
  .hidden-landscape-portrait {
    display: none !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .hidden-tablet-landscape-portrait {
    display: none !important;
  }
}
@media only screen and (min-width: 480px) and (max-width: 767px) {
  .hidden-tablet-landscape-portrait {
    display: none !important;
  }
}
@media only screen and (min-width: 320px) and (max-width: 479px) {
  .hidden-tablet-landscape-portrait {
    display: none !important;
  }
}
@media only screen and (min-width: 1024px) {
  .hidden-desktop-tablet-landscape {
    display: none !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .hidden-desktop-tablet-landscape {
    display: none !important;
  }
}
@media only screen and (min-width: 480px) and (max-width: 767px) {
  .hidden-desktop-tablet-landscape {
    display: none !important;
  }
}
@media only screen and (min-width: 1024px) {
  .hidden {
    display: none !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .hidden {
    display: none !important;
  }
}
@media only screen and (min-width: 480px) and (max-width: 767px) {
  .hidden {
    display: none !important;
  }
}
@media only screen and (min-width: 320px) and (max-width: 479px) {
  .hidden {
    display: none !important;
  }
}
.center.right {
  display: flex;
  justify-content: flex-end;
}
.center.block {
  display: block;
  text-align: center;
}
.flex {
  display: flex;
}
.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.box-shadow {
  -webkit-box-shadow: 0 5px 6px 0 var(--color-box-shadow);
  -moz-box-shadow: 0 5px 6px 0 0 var(--color-box-shadow);
  box-shadow: 0 5px 6px 0 var(--color-box-shadow);
}
.image-box {
  -webkit-box-shadow: 0 5px 6px 0 var(--color-box-shadow);
  -moz-box-shadow: 0 5px 6px 0 0 var(--color-box-shadow);
  box-shadow: 0 5px 6px 0 var(--color-box-shadow);
  border: 1px solid var(--color-disable);
}
.hover {
  cursor: pointer;
}
:root {
  --color-brand: #4caf50;
  --color-brand-dark: #306239;
  --color-brand-very-dark: #2f5339;
  --color-brand-hover: #2f5339;
  --color-background: #2c303a;
  --color-background-dark: #1e222b;
  --color-background-very-dark: #000;
  --color-background-panel: #303541;
  --color-text-foreground-hover: #4caf50;
  --color-foreground: #1b1b2f;
  --color-header: #ddd;
  --color-text: #ccc;
  --color-text-background-dark: #ccc;
  --color-text-hover-background-dark: #fff;
  --color-text-hover: #fff;
  --color-text-disable: #999;
  --color-disable: #666;
  --color-disable-light: #444;
  --color-box-shadow: #111;
  --color-icons: #1c2a36;
  --color-scrollbar-track: #222;
  --color-scrollbar-thumb: #444;
  --color-scrollbar-thumb-hover: #555;
  --brightness-default: 1.1;
  --brightness-high: 1.5;
  --color-footer-background: #1b1b2f;
  --color-footer: #ccc;
  --color-footer-hover: #4caf50;
  --color-top-bar-background: #1b1b2f;
  --color-top-bar-hover: #4caf50;
  --color-bible-search-strong: #4caf50;
  --color-bible-verse: #4caf50;
  --color-strong-text-disable: #888;
  --color-strong-text-translation: #ddd;
  --color-strong-text-transliteration: #deb887;
  --color-strong-text-root: #8fbc8f;
  --color-strong-text-number: #bbb;
  --color-strong-text-less-import: #aaa;
  --color-concordance-dialog-text-translation: #7fbce1;
  --color-book-info-1: #7eafc9;
  --color-book-info-2: #70bd63;
  --color-book-info-3: #d4dc73;
  --color-book-info-4: #d28d60;
  --color-bulb: #e7e75b;
  --color-breadcrumb: #ccc;
  --color-input-background: #2c303a;
  --color-chip-background: #ccc;
  --color-chip-hover: #000;
  --color-logo: #fff;
  --color-text-rgb:
    204,
    204,
    204;
  --color-text-hover-rgb:
    204,
    204,
    204;
  --color-brand-rgb:
    76,
    175,
    80;
  --color-background-rgb:
    44,
    48,
    58;
  --color-background-dark-rgb:
    30,
    34,
    43;
  --color-social-gradient-1-rgb:
    38,
    64,
    50;
  --color-social-gradient-2-rgb:
    38,
    64,
    50;
}
:root {
  --color-brand: #4caf50;
  --color-brand-dark: #306239;
  --color-brand-very-dark: #2f5339;
  --color-brand-hover: #2f5339;
  --color-background: #2c303a;
  --color-background-dark: #1e222b;
  --color-background-very-dark: #000;
  --color-background-panel: #303541;
  --color-text-foreground-hover: #4caf50;
  --color-foreground: #1b1b2f;
  --color-header: #ddd;
  --color-text: #ccc;
  --color-text-background-dark: #ccc;
  --color-text-hover-background-dark: #fff;
  --color-text-hover: #fff;
  --color-text-disable: #999;
  --color-disable: #666;
  --color-disable-light: #444;
  --color-box-shadow: #111;
  --color-icons: #1c2a36;
  --color-scrollbar-track: #222;
  --color-scrollbar-thumb: #444;
  --color-scrollbar-thumb-hover: #555;
  --brightness-default: 1.1;
  --brightness-high: 1.5;
  --color-footer-background: #1b1b2f;
  --color-footer: #ccc;
  --color-footer-hover: #4caf50;
  --color-top-bar-background: #1b1b2f;
  --color-top-bar-hover: #4caf50;
  --color-bible-search-strong: #4caf50;
  --color-bible-verse: #4caf50;
  --color-strong-text-disable: #888;
  --color-strong-text-translation: #ddd;
  --color-strong-text-transliteration: #deb887;
  --color-strong-text-root: #8fbc8f;
  --color-strong-text-number: #bbb;
  --color-strong-text-less-import: #aaa;
  --color-concordance-dialog-text-translation: #7fbce1;
  --color-book-info-1: #7eafc9;
  --color-book-info-2: #70bd63;
  --color-book-info-3: #d4dc73;
  --color-book-info-4: #d28d60;
  --color-bulb: #e7e75b;
  --color-breadcrumb: #ccc;
  --color-input-background: #2c303a;
  --color-chip-background: #ccc;
  --color-chip-hover: #000;
  --color-logo: #fff;
  --color-text-rgb:
    204,
    204,
    204;
  --color-text-hover-rgb:
    204,
    204,
    204;
  --color-brand-rgb:
    76,
    175,
    80;
  --color-background-rgb:
    44,
    48,
    58;
  --color-background-dark-rgb:
    30,
    34,
    43;
  --color-social-gradient-1-rgb:
    38,
    64,
    50;
  --color-social-gradient-2-rgb:
    38,
    64,
    50;
}
:host .section-container {
  padding-bottom: 0;
}
:host .section-container.section-carousel {
  overflow-x: hidden;
}
@media only screen and (min-width: 1024px) {
  :host .section-container.section-carousel {
    padding-left: 0;
    padding-right: 0;
  }
}
:host .section-container.section-carousel .flex-container {
  flex-wrap: nowrap;
  justify-content: flex-start;
  position: relative;
  left: 0;
  transition: 1000ms cubic-bezier(0.65, 0.05, 0.36, 1);
}
@media only screen and (min-width: 320px) and (max-width: 1023px) {
  :host .section-container.section-carousel .flex-container {
    overflow-x: auto;
  }
}
:host .section-container.section-carousel .flex-container .card-group {
  display: flex;
  width: 100%;
  flex: 1 0 100%;
}
@media only screen and (min-width: 320px) and (max-width: 479px) {
  :host .section-container.section-carousel .flex-container .card-group {
    flex-flow: column;
    align-items: center;
  }
  :host .section-container.section-carousel .flex-container .card-group app-card {
    flex: 0;
    width: calc(100% - 6rem);
  }
}
@media only screen and (min-width: 480px) and (max-width: 767px) {
  :host .section-container.section-carousel .flex-container .card-group.card-group-author {
    width: 200%;
    flex: 1 0 200%;
  }
}
@media only screen and (min-width: 320px) and (max-width: 479px) {
  :host .section-container.section-carousel .flex-container .card-group.card-group-author:not(:last-of-type) {
    margin-right: 2rem;
  }
}
@media only screen and (min-width: 1024px) {
  :host .section-container.section-carousel .flex-container .card-group:not(.card-group-author) {
    left: 3rem;
    position: relative;
  }
}
:host .section-container.section-carousel .flex-container .card-group .author-card {
  display: flex;
  font-size: 1.3rem;
  width: 33%;
  background-color: var(--color-brand-very-dark);
  margin: 1rem 1rem 2rem;
  text-decoration: none;
  box-sizing: border-box;
}
@media only screen and (min-width: 320px) and (max-width: 767px) {
  :host .section-container.section-carousel .flex-container .card-group .author-card {
    width: 100%;
    font-size: 1.1rem;
    margin-bottom: 1rem;
  }
}
@media only screen and (min-width: 480px) and (max-width: 767px) {
  :host .section-container.section-carousel .flex-container .card-group .author-card {
    width: 50%;
    font-size: 1.2rem;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1023px) {
  :host .section-container.section-carousel .flex-container .card-group .author-card {
    margin: 0 0.5rem 2rem;
    width: 50%;
  }
}
:host .section-container.section-carousel .flex-container .card-group .author-card:focus,
:host .section-container.section-carousel .flex-container .card-group .author-card:hover {
  color: var(--color-text-hover);
  border-color: var(--color-brand);
}
@media only screen and (min-width: 1024px) {
  :host .section-container.section-carousel .flex-container .card-group .author-card:focus,
  :host .section-container.section-carousel .flex-container .card-group .author-card:hover {
    transform: scale(1.05);
  }
}
:host .section-container.section-carousel .flex-container .card-group .author-card .author-text {
  display: flex;
  flex-flow: column;
  justify-content: space-evenly;
  align-items: center;
  margin-left: 0.5rem;
  flex: 1;
}
@media only screen and (min-width: 320px) and (max-width: 767px) {
  :host .section-container.section-carousel .flex-container .card-group .author-card .author-text {
    margin-left: 0.5rem;
  }
}
:host .section-container.section-carousel .flex-container .card-group .author-card .author-text .author-title {
  text-align: center;
}
:host .section-container.section-carousel .flex-container .card-group .author-card .author-text .author {
  font-size: 1.1rem;
}
@media only screen and (min-width: 320px) and (max-width: 479px) {
  :host .section-container.section-carousel .flex-container .card-group .author-card .author-text .author {
    font-size: 1rem;
  }
}
@media only screen and (min-width: 480px) and (max-width: 767px) {
  :host .section-container.section-carousel .flex-container .card-group .author-card .author-text .author {
    font-size: 0.9rem;
  }
}
:host .section-container.section-carousel .flex-container .card-group .author-card img {
  width: 120px;
  height: 120px;
  margin-left: 1rem;
}
@media only screen and (min-width: 320px) and (max-width: 767px) {
  :host .section-container.section-carousel .flex-container .card-group .author-card img {
    width: 100px;
    height: 100px;
    margin-left: 0.5rem;
  }
}
:host .section-container.section-carousel .slider-panel {
  text-align: center;
}
:host .section-container.section-carousel .slider-panel .slider-dot {
  border: 2px solid var(--color-text);
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  margin: 0 0.2rem;
}
:host .section-container.section-carousel .slider-panel .slider-dot:hover,
:host .section-container.section-carousel .slider-panel .slider-dot:focus,
:host .section-container.section-carousel .slider-panel .slider-dot.active {
  background-color: var(--color-brand);
}
:host .flex-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
  max-width: 1200px;
}
@media only screen and (min-width: 320px) and (max-width: 479px) {
  :host .flex-container {
    margin: 0 auto;
  }
}
:host .flex-container app-card {
  flex: 0 0 calc(33.33% - 6rem);
  margin: 2rem;
}
@media only screen and (min-width: 768px) and (max-width: 1023px) {
  :host .flex-container app-card {
    flex: 0 0 calc(50% - 4rem);
  }
}
@media only screen and (min-width: 480px) and (max-width: 767px) {
  :host .flex-container app-card {
    flex: 0 0 calc(50% - 2rem);
    margin: 1rem;
  }
}
@media only screen and (min-width: 320px) and (max-width: 479px) {
  :host .flex-container app-card {
    flex: 0 0 calc(100% - 6rem);
    margin-top: 1rem;
  }
}
/*# sourceMappingURL=carousel.component.css.map */
