import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { MatTooltipModule } from '@angular/material/tooltip';

import { ArticleChipsTooltipPipe } from './article-chips-tooltip.pipe';
import { SharedPipesModule } from '../../../../pipes/shared-pipes.module';

@Component({
  selector: 'app-article-chips',
  templateUrl: './article-chips.component.html',
  styleUrls: ['./article-chips.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    ArticleChipsTooltipPipe,
    MatTooltipModule,
    SharedPipesModule
],
})
export class ArticleChipsComponent {
  @Input({ required: true }) protected readonly chips: string[] = [];
}
