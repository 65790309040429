import { ChangeDetectionStrategy, Component } from '@angular/core';

import { ContactFormType } from '../../../components/contact-form/contact-form.component';

@Component({
  templateUrl: './question-page.component.html',
  styleUrls: ['./question-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QuestionPageComponent {
  readonly ContactFormType = ContactFormType;
}
