/* src/app/components/top-bar/top-bar.component.scss */
:root {
  --color-brand: #4caf50;
  --color-brand-dark: #306239;
  --color-brand-very-dark: #2f5339;
  --color-brand-hover: #2f5339;
  --color-background: #2c303a;
  --color-background-dark: #1e222b;
  --color-background-very-dark: #000;
  --color-background-panel: #303541;
  --color-text-foreground-hover: #4caf50;
  --color-foreground: #1b1b2f;
  --color-header: #ddd;
  --color-text: #ccc;
  --color-text-background-dark: #ccc;
  --color-text-hover-background-dark: #fff;
  --color-text-hover: #fff;
  --color-text-disable: #999;
  --color-disable: #666;
  --color-disable-light: #444;
  --color-box-shadow: #111;
  --color-icons: #1c2a36;
  --color-scrollbar-track: #222;
  --color-scrollbar-thumb: #444;
  --color-scrollbar-thumb-hover: #555;
  --brightness-default: 1.1;
  --brightness-high: 1.5;
  --color-footer-background: #1b1b2f;
  --color-footer: #ccc;
  --color-footer-hover: #4caf50;
  --color-top-bar-background: #1b1b2f;
  --color-top-bar-hover: #4caf50;
  --color-bible-search-strong: #4caf50;
  --color-bible-verse: #4caf50;
  --color-strong-text-disable: #888;
  --color-strong-text-translation: #ddd;
  --color-strong-text-transliteration: #deb887;
  --color-strong-text-root: #8fbc8f;
  --color-strong-text-number: #bbb;
  --color-strong-text-less-import: #aaa;
  --color-concordance-dialog-text-translation: #7fbce1;
  --color-book-info-1: #7eafc9;
  --color-book-info-2: #70bd63;
  --color-book-info-3: #d4dc73;
  --color-book-info-4: #d28d60;
  --color-bulb: #e7e75b;
  --color-breadcrumb: #ccc;
  --color-input-background: #2c303a;
  --color-chip-background: #ccc;
  --color-chip-hover: #000;
  --color-logo: #fff;
  --color-text-rgb:
    204,
    204,
    204;
  --color-text-hover-rgb:
    204,
    204,
    204;
  --color-brand-rgb:
    76,
    175,
    80;
  --color-background-rgb:
    44,
    48,
    58;
  --color-background-dark-rgb:
    30,
    34,
    43;
  --color-social-gradient-1-rgb:
    38,
    64,
    50;
  --color-social-gradient-2-rgb:
    38,
    64,
    50;
}
@media only screen and (min-width: 1024px) {
  .hidden-desktop {
    display: none !important;
  }
}
@media only screen and (min-width: 480px) and (max-width: 767px) {
  .hidden-landscape {
    display: none !important;
  }
}
@media only screen and (min-width: 320px) and (max-width: 479px) {
  .hidden-portrait {
    display: none !important;
  }
}
@media only screen and (min-width: 1024px) {
  .hidden-desktop-tablet {
    display: none !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .hidden-desktop-tablet {
    display: none !important;
  }
}
@media only screen and (min-width: 480px) and (max-width: 767px) {
  .hidden-landscape-portrait {
    display: none !important;
  }
}
@media only screen and (min-width: 320px) and (max-width: 479px) {
  .hidden-landscape-portrait {
    display: none !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .hidden-tablet-landscape-portrait {
    display: none !important;
  }
}
@media only screen and (min-width: 480px) and (max-width: 767px) {
  .hidden-tablet-landscape-portrait {
    display: none !important;
  }
}
@media only screen and (min-width: 320px) and (max-width: 479px) {
  .hidden-tablet-landscape-portrait {
    display: none !important;
  }
}
@media only screen and (min-width: 1024px) {
  .hidden-desktop-tablet-landscape {
    display: none !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .hidden-desktop-tablet-landscape {
    display: none !important;
  }
}
@media only screen and (min-width: 480px) and (max-width: 767px) {
  .hidden-desktop-tablet-landscape {
    display: none !important;
  }
}
@media only screen and (min-width: 1024px) {
  .hidden {
    display: none !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .hidden {
    display: none !important;
  }
}
@media only screen and (min-width: 480px) and (max-width: 767px) {
  .hidden {
    display: none !important;
  }
}
@media only screen and (min-width: 320px) and (max-width: 479px) {
  .hidden {
    display: none !important;
  }
}
.center.right {
  display: flex;
  justify-content: flex-end;
}
.center.block {
  display: block;
  text-align: center;
}
.flex {
  display: flex;
}
.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.box-shadow {
  -webkit-box-shadow: 0 5px 6px 0 var(--color-box-shadow);
  -moz-box-shadow: 0 5px 6px 0 0 var(--color-box-shadow);
  box-shadow: 0 5px 6px 0 var(--color-box-shadow);
}
.image-box {
  -webkit-box-shadow: 0 5px 6px 0 var(--color-box-shadow);
  -moz-box-shadow: 0 5px 6px 0 0 var(--color-box-shadow);
  box-shadow: 0 5px 6px 0 var(--color-box-shadow);
  border: 1px solid var(--color-disable);
}
.hover {
  cursor: pointer;
}
:root {
  --color-brand: #4caf50;
  --color-brand-dark: #306239;
  --color-brand-very-dark: #2f5339;
  --color-brand-hover: #2f5339;
  --color-background: #2c303a;
  --color-background-dark: #1e222b;
  --color-background-very-dark: #000;
  --color-background-panel: #303541;
  --color-text-foreground-hover: #4caf50;
  --color-foreground: #1b1b2f;
  --color-header: #ddd;
  --color-text: #ccc;
  --color-text-background-dark: #ccc;
  --color-text-hover-background-dark: #fff;
  --color-text-hover: #fff;
  --color-text-disable: #999;
  --color-disable: #666;
  --color-disable-light: #444;
  --color-box-shadow: #111;
  --color-icons: #1c2a36;
  --color-scrollbar-track: #222;
  --color-scrollbar-thumb: #444;
  --color-scrollbar-thumb-hover: #555;
  --brightness-default: 1.1;
  --brightness-high: 1.5;
  --color-footer-background: #1b1b2f;
  --color-footer: #ccc;
  --color-footer-hover: #4caf50;
  --color-top-bar-background: #1b1b2f;
  --color-top-bar-hover: #4caf50;
  --color-bible-search-strong: #4caf50;
  --color-bible-verse: #4caf50;
  --color-strong-text-disable: #888;
  --color-strong-text-translation: #ddd;
  --color-strong-text-transliteration: #deb887;
  --color-strong-text-root: #8fbc8f;
  --color-strong-text-number: #bbb;
  --color-strong-text-less-import: #aaa;
  --color-concordance-dialog-text-translation: #7fbce1;
  --color-book-info-1: #7eafc9;
  --color-book-info-2: #70bd63;
  --color-book-info-3: #d4dc73;
  --color-book-info-4: #d28d60;
  --color-bulb: #e7e75b;
  --color-breadcrumb: #ccc;
  --color-input-background: #2c303a;
  --color-chip-background: #ccc;
  --color-chip-hover: #000;
  --color-logo: #fff;
  --color-text-rgb:
    204,
    204,
    204;
  --color-text-hover-rgb:
    204,
    204,
    204;
  --color-brand-rgb:
    76,
    175,
    80;
  --color-background-rgb:
    44,
    48,
    58;
  --color-background-dark-rgb:
    30,
    34,
    43;
  --color-social-gradient-1-rgb:
    38,
    64,
    50;
  --color-social-gradient-2-rgb:
    38,
    64,
    50;
}
:root {
  --color-brand: #4caf50;
  --color-brand-dark: #306239;
  --color-brand-very-dark: #2f5339;
  --color-brand-hover: #2f5339;
  --color-background: #2c303a;
  --color-background-dark: #1e222b;
  --color-background-very-dark: #000;
  --color-background-panel: #303541;
  --color-text-foreground-hover: #4caf50;
  --color-foreground: #1b1b2f;
  --color-header: #ddd;
  --color-text: #ccc;
  --color-text-background-dark: #ccc;
  --color-text-hover-background-dark: #fff;
  --color-text-hover: #fff;
  --color-text-disable: #999;
  --color-disable: #666;
  --color-disable-light: #444;
  --color-box-shadow: #111;
  --color-icons: #1c2a36;
  --color-scrollbar-track: #222;
  --color-scrollbar-thumb: #444;
  --color-scrollbar-thumb-hover: #555;
  --brightness-default: 1.1;
  --brightness-high: 1.5;
  --color-footer-background: #1b1b2f;
  --color-footer: #ccc;
  --color-footer-hover: #4caf50;
  --color-top-bar-background: #1b1b2f;
  --color-top-bar-hover: #4caf50;
  --color-bible-search-strong: #4caf50;
  --color-bible-verse: #4caf50;
  --color-strong-text-disable: #888;
  --color-strong-text-translation: #ddd;
  --color-strong-text-transliteration: #deb887;
  --color-strong-text-root: #8fbc8f;
  --color-strong-text-number: #bbb;
  --color-strong-text-less-import: #aaa;
  --color-concordance-dialog-text-translation: #7fbce1;
  --color-book-info-1: #7eafc9;
  --color-book-info-2: #70bd63;
  --color-book-info-3: #d4dc73;
  --color-book-info-4: #d28d60;
  --color-bulb: #e7e75b;
  --color-breadcrumb: #ccc;
  --color-input-background: #2c303a;
  --color-chip-background: #ccc;
  --color-chip-hover: #000;
  --color-logo: #fff;
  --color-text-rgb:
    204,
    204,
    204;
  --color-text-hover-rgb:
    204,
    204,
    204;
  --color-brand-rgb:
    76,
    175,
    80;
  --color-background-rgb:
    44,
    48,
    58;
  --color-background-dark-rgb:
    30,
    34,
    43;
  --color-social-gradient-1-rgb:
    38,
    64,
    50;
  --color-social-gradient-2-rgb:
    38,
    64,
    50;
}
:host {
  display: flex;
  position: sticky;
  top: 0;
  width: 100%;
  height: 60px;
  z-index: 2;
  background-color: var(--color-top-bar-background);
  transition: 800ms cubic-bezier(0.79, 0, 0.22, 1);
  box-shadow:
    0 0 10px 5px rgba(0, 0, 0, 0.6),
    0 4px 8px 0 rgba(0, 0, 0, 0.4),
    0 6px 12px 0 rgba(0, 0, 0, 0.2);
}
@media only screen and (min-width: 1024px) {
  :host {
    height: 70px;
  }
}
@media only screen and (min-width: 320px) and (max-width: 479px) {
  :host {
    height: 50px;
  }
}
:host header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0 3rem;
}
@media only screen and (max-width: 1174px) {
  :host header {
    padding: 0 2rem;
  }
}
@media only screen and (min-width: 320px) and (max-width: 479px) {
  :host header {
    padding: 0 1rem;
  }
}
:host .nav-container {
  display: inline-flex;
  height: 100%;
  font-size: 1.5rem;
  position: relative;
}
:host .nav-container .actions-wrapper {
  display: flex;
  width: 13rem;
}
:host .nav-container .actions-wrapper .nav-item {
  z-index: 2;
  display: flex;
  cursor: pointer;
  align-items: center;
  will-change: transform;
  justify-content: center;
  transition: transform var(--timeOut, 0.7s);
  background: none;
  border: none;
  padding: 0;
}
:host .nav-container .actions-wrapper .nav-item.menu {
  margin-right: 2rem;
}
@media only screen and (min-width: 480px) and (max-width: 767px) {
  :host .nav-container .actions-wrapper .nav-item.menu {
    margin-right: 1.5rem;
  }
}
@media only screen and (min-width: 320px) and (max-width: 479px) {
  :host .nav-container .actions-wrapper .nav-item.menu {
    margin-right: 1.5rem;
  }
}
:host .nav-container .actions-wrapper .nav-item.other-action {
  margin-right: 2rem;
}
@media only screen and (min-width: 480px) and (max-width: 767px) {
  :host .nav-container .actions-wrapper .nav-item.other-action {
    margin-right: 1.5rem;
  }
}
@media only screen and (min-width: 320px) and (max-width: 479px) {
  :host .nav-container .actions-wrapper .nav-item.other-action {
    margin-right: 1.5rem;
  }
}
:host .nav-container .actions-wrapper .nav-item.other-action .other-action-icon {
  width: 2rem;
  height: 2rem;
  fill: #eee;
  transition: 400ms cubic-bezier(0.79, 0, 0.22, 1);
}
@media only screen and (min-width: 320px) and (max-width: 479px) {
  :host .nav-container .actions-wrapper .nav-item.other-action .other-action-icon {
    width: 1.7rem;
    height: 1.7rem;
  }
}
:host .nav-container .actions-wrapper .nav-item.other-action:focus .other-action-icon,
:host .nav-container .actions-wrapper .nav-item.other-action:hover .other-action-icon {
  fill: var(--color-top-bar-hover);
}
:host .nav-container .actions-wrapper .nav-item::before {
  content: "";
  z-index: -1;
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
  position: absolute;
  transform: scale(0);
  transition: transform 0.7s;
}
:host .nav-container .actions-wrapper .nav-item .header-icon {
  width: 2rem;
  height: 2rem;
  stroke: #eee;
  fill: #eee;
  transition: 400ms cubic-bezier(0.79, 0, 0.22, 1);
}
:host .nav-container .actions-wrapper .nav-item:focus .header-icon,
:host .nav-container .actions-wrapper .nav-item:hover .header-icon {
  stroke: var(--color-top-bar-hover);
  fill: var(--color-top-bar-hover);
}
:host .nav-container .actions-wrapper .nav-item .mat-icon {
  width: 3rem;
  height: 3rem;
}
@media only screen and (min-width: 480px) and (max-width: 767px) {
  :host .nav-container .actions-wrapper .nav-item .mat-icon {
    width: 2.5rem;
    height: 2.5rem;
  }
}
@media only screen and (min-width: 320px) and (max-width: 479px) {
  :host .nav-container .actions-wrapper .nav-item .mat-icon {
    width: 2rem;
    height: 2rem;
  }
}
@media only screen and (min-width: 480px) and (max-width: 767px) {
  :host .nav-container .actions-wrapper .nav-item {
    margin-right: 1rem;
  }
}
@media only screen and (min-width: 320px) and (max-width: 479px) {
  :host .nav-container .actions-wrapper .nav-item {
    margin-right: 1rem;
  }
}
:host .title-container {
  height: 100%;
}
@media only screen and (min-width: 1024px) {
  :host .title-container {
    padding-left: 3rem;
  }
}
:host .title-container .title-text {
  display: flex;
  align-items: center;
  color: #fff;
  font-size: 2.2rem;
  text-decoration: none;
  transition: 400ms cubic-bezier(0.79, 0, 0.22, 1);
  height: 100%;
}
:host .title-container .title-text:focus {
  outline: none;
}
:host .title-container .title-text:focus .logo ::ng-deep svg g,
:host .title-container .title-text:hover .logo ::ng-deep svg g {
  fill: var(--color-top-bar-hover);
}
@media only screen and (max-width: 1174px) {
  :host .title-container .title-text {
    font-size: 2rem;
  }
}
@media only screen and (min-width: 480px) and (max-width: 767px) {
  :host .title-container .title-text {
    font-size: 1.4rem;
    text-align: right;
  }
}
@media only screen and (min-width: 320px) and (max-width: 479px) {
  :host .title-container .title-text {
    font-size: 1.8rem;
  }
}
:host .title-container .title-text .logo {
  display: flex;
  width: 10rem;
  height: 100%;
  fill: #fff;
}
:host .title-container .title-text .logo ::ng-deep svg g {
  transition: 400ms cubic-bezier(0.79, 0, 0.22, 1);
}
@media only screen and (min-width: 480px) and (max-width: 767px) {
  :host .title-container .title-text .logo {
    width: 9rem;
  }
}
@media only screen and (min-width: 320px) and (max-width: 479px) {
  :host .title-container .title-text .logo {
    width: 7rem;
  }
}
:host .svg-container {
  width: 0;
  height: 0;
}
:host-context(.top-bar-hidden) {
  top: -60px;
}
@media only screen and (min-width: 1024px) {
  :host-context(.top-bar-hidden) {
    top: -70px;
  }
}
@media only screen and (min-width: 320px) and (max-width: 479px) {
  :host-context(.top-bar-hidden) {
    top: -50px;
  }
}
/*# sourceMappingURL=top-bar.component.css.map */
