import { ChangeDetectionStrategy, Component, OnInit, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSort, MatSortModule } from '@angular/material/sort';
import { MediaDirectivesModule } from '../../../directives/media/media-directives.module';
import { RouterModule } from '@angular/router';

import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { SharedPipesModule } from '../../../pipes/shared-pipes.module';
import { TableGenericComponent } from '../generic/table/table-generic.component';
import { TableCommentariesFilterComponent } from './filter/table-commentaries-filter.component';

export interface StudiesRow {
  book: string;
  date: Date;
  description?: string;
  chapter: number;
  url?: string;
}

@Component({
  selector: 'app-table-commentaries',
  templateUrl: './table-commentaries.component.html',
  styleUrls: ['./table-commentaries.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    MatIconModule,
    MatProgressSpinnerModule,
    MatTableModule,
    MatTooltipModule,
    MatSortModule,
    MediaDirectivesModule,
    RouterModule,
    SharedPipesModule,
    TableCommentariesFilterComponent,
    TableGenericComponent,
  ]
})
export class TableCommentariesComponent extends TableGenericComponent<StudiesRow> implements OnInit {
  protected readonly displayedColumns = ['position', 'book', 'chapter', 'date'];

  @ViewChild(MatSort) protected readonly matSort: MatSort;
}
