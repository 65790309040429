<div class="page-wrapper">
  <app-table-commentaries
          class="table" [data]="data" svgIcon="description"
          [title]="breakpointService.isTabletOrUp ? 'Komentarze biblijne' : 'Komentarze'" [isLoading]="isLoading">
    <p>Dział ten poświęcony jest autorskim badaniom poszczególnych <b>ksiąg biblijnych.</b> Omawiane są poszczególne księgi
      biblijne traktowane jako całość, a nie wybrane zagadnienia.
    </p>

    <p>Komentarze powstały w oparciu o przekład Uwspółczeńsionej Biblii Gdańskiej, o ile nie zaznaczono inaczej.</p>
  </app-table-commentaries>
</div>
