/* src/app/components/home/home.component.scss */
:root {
  --color-brand: #4caf50;
  --color-brand-dark: #306239;
  --color-brand-very-dark: #2f5339;
  --color-brand-hover: #2f5339;
  --color-background: #2c303a;
  --color-background-dark: #1e222b;
  --color-background-very-dark: #000;
  --color-background-panel: #303541;
  --color-text-foreground-hover: #4caf50;
  --color-foreground: #1b1b2f;
  --color-header: #ddd;
  --color-text: #ccc;
  --color-text-background-dark: #ccc;
  --color-text-hover-background-dark: #fff;
  --color-text-hover: #fff;
  --color-text-disable: #999;
  --color-disable: #666;
  --color-disable-light: #444;
  --color-box-shadow: #111;
  --color-icons: #1c2a36;
  --color-scrollbar-track: #222;
  --color-scrollbar-thumb: #444;
  --color-scrollbar-thumb-hover: #555;
  --brightness-default: 1.1;
  --brightness-high: 1.5;
  --color-footer-background: #1b1b2f;
  --color-footer: #ccc;
  --color-footer-hover: #4caf50;
  --color-top-bar-background: #1b1b2f;
  --color-top-bar-hover: #4caf50;
  --color-bible-search-strong: #4caf50;
  --color-bible-verse: #4caf50;
  --color-strong-text-disable: #888;
  --color-strong-text-translation: #ddd;
  --color-strong-text-transliteration: #deb887;
  --color-strong-text-root: #8fbc8f;
  --color-strong-text-number: #bbb;
  --color-strong-text-less-import: #aaa;
  --color-concordance-dialog-text-translation: #7fbce1;
  --color-book-info-1: #7eafc9;
  --color-book-info-2: #70bd63;
  --color-book-info-3: #d4dc73;
  --color-book-info-4: #d28d60;
  --color-bulb: #e7e75b;
  --color-breadcrumb: #ccc;
  --color-input-background: #2c303a;
  --color-chip-background: #ccc;
  --color-chip-hover: #000;
  --color-logo: #fff;
  --color-text-rgb:
    204,
    204,
    204;
  --color-text-hover-rgb:
    204,
    204,
    204;
  --color-brand-rgb:
    76,
    175,
    80;
  --color-background-rgb:
    44,
    48,
    58;
  --color-background-dark-rgb:
    30,
    34,
    43;
  --color-social-gradient-1-rgb:
    38,
    64,
    50;
  --color-social-gradient-2-rgb:
    38,
    64,
    50;
}
@media only screen and (min-width: 1024px) {
  .hidden-desktop {
    display: none !important;
  }
}
@media only screen and (min-width: 480px) and (max-width: 767px) {
  .hidden-landscape {
    display: none !important;
  }
}
@media only screen and (min-width: 320px) and (max-width: 479px) {
  .hidden-portrait {
    display: none !important;
  }
}
@media only screen and (min-width: 1024px) {
  .hidden-desktop-tablet {
    display: none !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .hidden-desktop-tablet {
    display: none !important;
  }
}
@media only screen and (min-width: 480px) and (max-width: 767px) {
  .hidden-landscape-portrait {
    display: none !important;
  }
}
@media only screen and (min-width: 320px) and (max-width: 479px) {
  .hidden-landscape-portrait {
    display: none !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .hidden-tablet-landscape-portrait {
    display: none !important;
  }
}
@media only screen and (min-width: 480px) and (max-width: 767px) {
  .hidden-tablet-landscape-portrait {
    display: none !important;
  }
}
@media only screen and (min-width: 320px) and (max-width: 479px) {
  .hidden-tablet-landscape-portrait {
    display: none !important;
  }
}
@media only screen and (min-width: 1024px) {
  .hidden-desktop-tablet-landscape {
    display: none !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .hidden-desktop-tablet-landscape {
    display: none !important;
  }
}
@media only screen and (min-width: 480px) and (max-width: 767px) {
  .hidden-desktop-tablet-landscape {
    display: none !important;
  }
}
@media only screen and (min-width: 1024px) {
  .hidden {
    display: none !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .hidden {
    display: none !important;
  }
}
@media only screen and (min-width: 480px) and (max-width: 767px) {
  .hidden {
    display: none !important;
  }
}
@media only screen and (min-width: 320px) and (max-width: 479px) {
  .hidden {
    display: none !important;
  }
}
.center.right {
  display: flex;
  justify-content: flex-end;
}
.center.block {
  display: block;
  text-align: center;
}
.flex {
  display: flex;
}
.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.box-shadow {
  -webkit-box-shadow: 0 5px 6px 0 var(--color-box-shadow);
  -moz-box-shadow: 0 5px 6px 0 0 var(--color-box-shadow);
  box-shadow: 0 5px 6px 0 var(--color-box-shadow);
}
.image-box {
  -webkit-box-shadow: 0 5px 6px 0 var(--color-box-shadow);
  -moz-box-shadow: 0 5px 6px 0 0 var(--color-box-shadow);
  box-shadow: 0 5px 6px 0 var(--color-box-shadow);
  border: 1px solid var(--color-disable);
}
.hover {
  cursor: pointer;
}
:root {
  --color-brand: #4caf50;
  --color-brand-dark: #306239;
  --color-brand-very-dark: #2f5339;
  --color-brand-hover: #2f5339;
  --color-background: #2c303a;
  --color-background-dark: #1e222b;
  --color-background-very-dark: #000;
  --color-background-panel: #303541;
  --color-text-foreground-hover: #4caf50;
  --color-foreground: #1b1b2f;
  --color-header: #ddd;
  --color-text: #ccc;
  --color-text-background-dark: #ccc;
  --color-text-hover-background-dark: #fff;
  --color-text-hover: #fff;
  --color-text-disable: #999;
  --color-disable: #666;
  --color-disable-light: #444;
  --color-box-shadow: #111;
  --color-icons: #1c2a36;
  --color-scrollbar-track: #222;
  --color-scrollbar-thumb: #444;
  --color-scrollbar-thumb-hover: #555;
  --brightness-default: 1.1;
  --brightness-high: 1.5;
  --color-footer-background: #1b1b2f;
  --color-footer: #ccc;
  --color-footer-hover: #4caf50;
  --color-top-bar-background: #1b1b2f;
  --color-top-bar-hover: #4caf50;
  --color-bible-search-strong: #4caf50;
  --color-bible-verse: #4caf50;
  --color-strong-text-disable: #888;
  --color-strong-text-translation: #ddd;
  --color-strong-text-transliteration: #deb887;
  --color-strong-text-root: #8fbc8f;
  --color-strong-text-number: #bbb;
  --color-strong-text-less-import: #aaa;
  --color-concordance-dialog-text-translation: #7fbce1;
  --color-book-info-1: #7eafc9;
  --color-book-info-2: #70bd63;
  --color-book-info-3: #d4dc73;
  --color-book-info-4: #d28d60;
  --color-bulb: #e7e75b;
  --color-breadcrumb: #ccc;
  --color-input-background: #2c303a;
  --color-chip-background: #ccc;
  --color-chip-hover: #000;
  --color-logo: #fff;
  --color-text-rgb:
    204,
    204,
    204;
  --color-text-hover-rgb:
    204,
    204,
    204;
  --color-brand-rgb:
    76,
    175,
    80;
  --color-background-rgb:
    44,
    48,
    58;
  --color-background-dark-rgb:
    30,
    34,
    43;
  --color-social-gradient-1-rgb:
    38,
    64,
    50;
  --color-social-gradient-2-rgb:
    38,
    64,
    50;
}
:root {
  --color-brand: #4caf50;
  --color-brand-dark: #306239;
  --color-brand-very-dark: #2f5339;
  --color-brand-hover: #2f5339;
  --color-background: #2c303a;
  --color-background-dark: #1e222b;
  --color-background-very-dark: #000;
  --color-background-panel: #303541;
  --color-text-foreground-hover: #4caf50;
  --color-foreground: #1b1b2f;
  --color-header: #ddd;
  --color-text: #ccc;
  --color-text-background-dark: #ccc;
  --color-text-hover-background-dark: #fff;
  --color-text-hover: #fff;
  --color-text-disable: #999;
  --color-disable: #666;
  --color-disable-light: #444;
  --color-box-shadow: #111;
  --color-icons: #1c2a36;
  --color-scrollbar-track: #222;
  --color-scrollbar-thumb: #444;
  --color-scrollbar-thumb-hover: #555;
  --brightness-default: 1.1;
  --brightness-high: 1.5;
  --color-footer-background: #1b1b2f;
  --color-footer: #ccc;
  --color-footer-hover: #4caf50;
  --color-top-bar-background: #1b1b2f;
  --color-top-bar-hover: #4caf50;
  --color-bible-search-strong: #4caf50;
  --color-bible-verse: #4caf50;
  --color-strong-text-disable: #888;
  --color-strong-text-translation: #ddd;
  --color-strong-text-transliteration: #deb887;
  --color-strong-text-root: #8fbc8f;
  --color-strong-text-number: #bbb;
  --color-strong-text-less-import: #aaa;
  --color-concordance-dialog-text-translation: #7fbce1;
  --color-book-info-1: #7eafc9;
  --color-book-info-2: #70bd63;
  --color-book-info-3: #d4dc73;
  --color-book-info-4: #d28d60;
  --color-bulb: #e7e75b;
  --color-breadcrumb: #ccc;
  --color-input-background: #2c303a;
  --color-chip-background: #ccc;
  --color-chip-hover: #000;
  --color-logo: #fff;
  --color-text-rgb:
    204,
    204,
    204;
  --color-text-hover-rgb:
    204,
    204,
    204;
  --color-brand-rgb:
    76,
    175,
    80;
  --color-background-rgb:
    44,
    48,
    58;
  --color-background-dark-rgb:
    30,
    34,
    43;
  --color-social-gradient-1-rgb:
    38,
    64,
    50;
  --color-social-gradient-2-rgb:
    38,
    64,
    50;
}
:host {
  display: flex;
  justify-content: center;
  align-items: center;
}
:host .home {
  width: 100%;
}
:host .home .section {
  background: #1e222b;
  position: relative;
  padding: 2rem 0 0;
}
@media only screen and (min-width: 768px) and (max-width: 1023px) {
  :host .home .section {
    padding: 2rem 0 0;
  }
}
@media only screen and (min-width: 480px) and (max-width: 767px) {
  :host .home .section {
    padding: 1rem 0 0;
  }
}
@media only screen and (min-width: 320px) and (max-width: 479px) {
  :host .home .section {
    padding: 0.5rem 0;
  }
}
:host .home .section:first-of-type {
  padding-top: 4rem;
  padding-bottom: 2rem;
}
@media only screen and (min-width: 480px) and (max-width: 767px) {
  :host .home .section:first-of-type {
    padding-top: 2rem;
  }
}
@media only screen and (min-width: 320px) and (max-width: 479px) {
  :host .home .section:first-of-type {
    padding-top: 1rem;
  }
}
:host .home .section.section-dark {
  background: var(--color-background-dark);
  background:
    linear-gradient(
      0deg,
      var(--color-background-dark) 0%,
      var(--color-background-dark) 10%,
      var(--color-background) 40%,
      var(--color-background) 50%,
      var(--color-background) 60%,
      var(--color-background-dark) 90%,
      var(--color-background-dark) 100%);
}
:host .home .section.section-light {
  background: var(--color-background);
  background:
    linear-gradient(
      0deg,
      var(--color-background-dark) 0%,
      var(--color-background-dark) 10%,
      var(--color-background) 40%,
      var(--color-background) 50%,
      var(--color-background) 60%,
      var(--color-background-dark) 90%,
      var(--color-background-dark) 100%);
}
:host .home .section:last-of-type {
  padding-bottom: 4rem;
}
@media only screen and (min-width: 768px) and (max-width: 1023px) {
  :host .home .section:last-of-type {
    padding-bottom: 3rem;
  }
}
@media only screen and (min-width: 480px) and (max-width: 767px) {
  :host .home .section:last-of-type {
    padding-bottom: 2rem;
  }
}
@media only screen and (min-width: 320px) and (max-width: 479px) {
  :host .home .section:last-of-type {
    padding-bottom: 4rem;
  }
}
:host .home .section h2 {
  max-width: none;
  line-height: 1;
  border-top: 2px solid var(--color-brand-dark);
  border-bottom: 2px solid var(--color-brand-dark);
  padding: 2rem 0;
  margin: 0 0 4rem;
  position: relative;
  text-align: center;
}
@media only screen and (min-width: 768px) and (max-width: 1023px) {
  :host .home .section h2 {
    margin: 0 0 4rem;
  }
}
@media only screen and (min-width: 480px) and (max-width: 767px) {
  :host .home .section h2 {
    margin: 0 0 4rem;
    padding: 1rem 0;
  }
}
@media only screen and (min-width: 320px) and (max-width: 479px) {
  :host .home .section h2 {
    margin: 0 0 3rem;
    padding: 1rem;
  }
}
:host .home .section h2.header-with-cards {
  background-color: var(--color-background-dark);
  -webkit-box-shadow: 0 4px 6px 0 var(--color-box-shadow);
  -moz-box-shadow: 0 4px 6px 0 0 var(--color-box-shadow);
  box-shadow: 0 4px 6px 0 var(--color-box-shadow);
}
:host .home .section h2.header-with-cards.header-extra-space {
  margin-bottom: 2rem;
}
@media only screen and (min-width: 320px) and (max-width: 479px) {
  :host .home .section h2.header-with-cards.header-extra-space {
    margin-bottom: 1.5rem;
  }
}
:host .home .section h2.first {
  margin-top: 2rem;
}
:host .home .section .section-container {
  padding-bottom: 0;
}
@media only screen and (min-width: 320px) and (max-width: 479px) {
  :host .home .section .section-container.welcome {
    padding: 2rem 1rem 0;
  }
}
:host .home .flex-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
  max-width: 1200px;
}
@media only screen and (min-width: 320px) and (max-width: 479px) {
  :host .home .flex-container {
    margin: 0 auto;
  }
}
:host .home .flex-container app-card {
  flex: 0 0 calc(33.33% - 4rem);
  margin: 2rem;
}
@media only screen and (min-width: 768px) and (max-width: 1023px) {
  :host .home .flex-container app-card {
    flex: 0 0 calc(50% - 4rem);
  }
}
@media only screen and (min-width: 480px) and (max-width: 767px) {
  :host .home .flex-container app-card {
    flex: 0 0 calc(50% - 2rem);
    margin: 1rem;
  }
}
@media only screen and (min-width: 320px) and (max-width: 479px) {
  :host .home .flex-container app-card {
    flex: 0 0 calc(100% - 6rem);
    margin-top: 1rem;
  }
}
:host .placeholder-card {
  aspect-ratio: 1/1;
  margin: 2rem;
}
@media only screen and (min-width: 1024px) {
  :host .placeholder-card {
    display: block;
    width: 336px;
    box-sizing: border-box;
    background-color: var(--color-background-dark);
    background:
      linear-gradient(
        100deg,
        rgba(255, 255, 255, 0) 40%,
        rgba(255, 255, 255, 0.5) 50%,
        rgba(255, 255, 255, 0) 60%) var(--color-background-dark);
    background-size: 200% 100%;
    background-position-x: 180%;
    animation: 1s loading ease-in-out infinite;
    border: 1px solid var(--color-brand-dark);
    -webkit-box-shadow: 0 5px 6px 0 var(--color-box-shadow);
    -moz-box-shadow: 0 5px 6px 0 0 var(--color-box-shadow);
    box-shadow: 0 5px 6px 0 var(--color-box-shadow);
  }
  @keyframes loading {
    to {
      background-position-x: -20%;
    }
  }
}
@media only screen and (min-width: 768px) and (max-width: 1023px) {
  :host .placeholder-card {
    display: block;
    width: calc(50vw - 7rem);
    box-sizing: border-box;
    background-color: var(--color-background-dark);
    background:
      linear-gradient(
        100deg,
        rgba(255, 255, 255, 0) 40%,
        rgba(255, 255, 255, 0.5) 50%,
        rgba(255, 255, 255, 0) 60%) var(--color-background-dark);
    background-size: 200% 100%;
    background-position-x: 180%;
    animation: 1s loading ease-in-out infinite;
    border: 1px solid var(--color-brand-dark);
    -webkit-box-shadow: 0 5px 6px 0 var(--color-box-shadow);
    -moz-box-shadow: 0 5px 6px 0 0 var(--color-box-shadow);
    box-shadow: 0 5px 6px 0 var(--color-box-shadow);
  }
  @keyframes loading {
    to {
      background-position-x: -20%;
    }
  }
}
@media only screen and (min-width: 480px) and (max-width: 767px) {
  :host .placeholder-card {
    display: block;
    width: calc(50vw - 4rem);
    box-sizing: border-box;
    background-color: var(--color-background-dark);
    background:
      linear-gradient(
        100deg,
        rgba(255, 255, 255, 0) 40%,
        rgba(255, 255, 255, 0.5) 50%,
        rgba(255, 255, 255, 0) 60%) var(--color-background-dark);
    background-size: 200% 100%;
    background-position-x: 180%;
    animation: 1s loading ease-in-out infinite;
    border: 1px solid var(--color-brand-dark);
    -webkit-box-shadow: 0 5px 6px 0 var(--color-box-shadow);
    -moz-box-shadow: 0 5px 6px 0 0 var(--color-box-shadow);
    box-shadow: 0 5px 6px 0 var(--color-box-shadow);
  }
  @keyframes loading {
    to {
      background-position-x: -20%;
    }
  }
}
@media only screen and (min-width: 320px) and (max-width: 479px) {
  :host .placeholder-card {
    display: block;
    width: calc(100vw - 10rem);
    box-sizing: border-box;
    background-color: var(--color-background-dark);
    background:
      linear-gradient(
        100deg,
        rgba(255, 255, 255, 0) 40%,
        rgba(255, 255, 255, 0.5) 50%,
        rgba(255, 255, 255, 0) 60%) var(--color-background-dark);
    background-size: 200% 100%;
    background-position-x: 180%;
    animation: 1s loading ease-in-out infinite;
    border: 1px solid var(--color-brand-dark);
    -webkit-box-shadow: 0 5px 6px 0 var(--color-box-shadow);
    -moz-box-shadow: 0 5px 6px 0 0 var(--color-box-shadow);
    box-shadow: 0 5px 6px 0 var(--color-box-shadow);
  }
  @keyframes loading {
    to {
      background-position-x: -20%;
    }
  }
}
@media only screen and (min-width: 480px) and (max-width: 767px) {
  :host .placeholder-card {
    margin: 1rem;
  }
}
@media only screen and (min-width: 320px) and (max-width: 479px) {
  :host .placeholder-card {
    margin: 1rem 2rem 2rem;
  }
}
:host .placeholder-news,
:host .placeholder-books,
:host .placeholder-social {
  display: flex;
  justify-content: center;
}
@media only screen and (min-width: 320px) and (max-width: 767px) {
  :host .placeholder-news {
    flex-flow: column;
    align-items: center;
  }
}
:host .placeholder-news div {
  display: block;
  width: 368px;
  height: 122px;
  box-sizing: border-box;
  background-color: var(--color-background-dark);
  background:
    linear-gradient(
      100deg,
      rgba(255, 255, 255, 0) 40%,
      rgba(255, 255, 255, 0.5) 50%,
      rgba(255, 255, 255, 0) 60%) var(--color-background-dark);
  background-size: 200% 100%;
  background-position-x: 180%;
  animation: 1s loading ease-in-out infinite;
  border: 1px solid var(--color-brand-dark);
  -webkit-box-shadow: 0 5px 6px 0 var(--color-box-shadow);
  -moz-box-shadow: 0 5px 6px 0 0 var(--color-box-shadow);
  box-shadow: 0 5px 6px 0 var(--color-box-shadow);
  margin: 0 1rem 2rem;
}
@keyframes loading {
  to {
    background-position-x: -20%;
  }
}
@media only screen and (min-width: 1024px) {
  :host .placeholder-news div {
    display: block;
    width: 368px;
    height: 122px;
    box-sizing: border-box;
    background-color: var(--color-background-dark);
    background:
      linear-gradient(
        100deg,
        rgba(255, 255, 255, 0) 40%,
        rgba(255, 255, 255, 0.5) 50%,
        rgba(255, 255, 255, 0) 60%) var(--color-background-dark);
    background-size: 200% 100%;
    background-position-x: 180%;
    animation: 1s loading ease-in-out infinite;
    border: 1px solid var(--color-brand-dark);
    -webkit-box-shadow: 0 5px 6px 0 var(--color-box-shadow);
    -moz-box-shadow: 0 5px 6px 0 0 var(--color-box-shadow);
    box-shadow: 0 5px 6px 0 var(--color-box-shadow);
  }
  @keyframes loading {
    to {
      background-position-x: -20%;
    }
  }
}
@media only screen and (min-width: 768px) and (max-width: 1023px) {
  :host .placeholder-news div {
    display: block;
    width: calc(50% - 4rem);
    height: 122px;
    box-sizing: border-box;
    background-color: var(--color-background-dark);
    background:
      linear-gradient(
        100deg,
        rgba(255, 255, 255, 0) 40%,
        rgba(255, 255, 255, 0.5) 50%,
        rgba(255, 255, 255, 0) 60%) var(--color-background-dark);
    background-size: 200% 100%;
    background-position-x: 180%;
    animation: 1s loading ease-in-out infinite;
    border: 1px solid var(--color-brand-dark);
    -webkit-box-shadow: 0 5px 6px 0 var(--color-box-shadow);
    -moz-box-shadow: 0 5px 6px 0 0 var(--color-box-shadow);
    box-shadow: 0 5px 6px 0 var(--color-box-shadow);
  }
  @keyframes loading {
    to {
      background-position-x: -20%;
    }
  }
}
@media only screen and (min-width: 480px) and (max-width: 767px) {
  :host .placeholder-news div {
    display: block;
    width: calc(100vw - 4rem);
    height: 100px;
    box-sizing: border-box;
    background-color: var(--color-background-dark);
    background:
      linear-gradient(
        100deg,
        rgba(255, 255, 255, 0) 40%,
        rgba(255, 255, 255, 0.5) 50%,
        rgba(255, 255, 255, 0) 60%) var(--color-background-dark);
    background-size: 200% 100%;
    background-position-x: 180%;
    animation: 1s loading ease-in-out infinite;
    border: 1px solid var(--color-brand-dark);
    -webkit-box-shadow: 0 5px 6px 0 var(--color-box-shadow);
    -moz-box-shadow: 0 5px 6px 0 0 var(--color-box-shadow);
    box-shadow: 0 5px 6px 0 var(--color-box-shadow);
  }
  @keyframes loading {
    to {
      background-position-x: -20%;
    }
  }
}
@media only screen and (min-width: 320px) and (max-width: 479px) {
  :host .placeholder-news div {
    display: block;
    width: calc(100vw - 4rem);
    height: 100px;
    box-sizing: border-box;
    background-color: var(--color-background-dark);
    background:
      linear-gradient(
        100deg,
        rgba(255, 255, 255, 0) 40%,
        rgba(255, 255, 255, 0.5) 50%,
        rgba(255, 255, 255, 0) 60%) var(--color-background-dark);
    background-size: 200% 100%;
    background-position-x: 180%;
    animation: 1s loading ease-in-out infinite;
    border: 1px solid var(--color-brand-dark);
    -webkit-box-shadow: 0 5px 6px 0 var(--color-box-shadow);
    -moz-box-shadow: 0 5px 6px 0 0 var(--color-box-shadow);
    box-shadow: 0 5px 6px 0 var(--color-box-shadow);
  }
  @keyframes loading {
    to {
      background-position-x: -20%;
    }
  }
}
@media only screen and (min-width: 768px) and (max-width: 1023px) {
  :host .placeholder-news div {
    margin: 0 0.5rem 2rem;
  }
}
@media only screen and (min-width: 320px) and (max-width: 767px) {
  :host .placeholder-news div {
    margin: 0 1rem 1rem;
  }
}
@media only screen and (min-width: 320px) and (max-width: 479px) {
  :host .placeholder-books {
    flex-flow: column;
    align-items: center;
  }
}
:host .placeholder-books div {
  aspect-ratio: 1/1;
  margin: 2rem;
}
@media only screen and (min-width: 1024px) {
  :host .placeholder-books div {
    display: block;
    width: 336px;
    box-sizing: border-box;
    background-color: var(--color-background-dark);
    background:
      linear-gradient(
        100deg,
        rgba(255, 255, 255, 0) 40%,
        rgba(255, 255, 255, 0.5) 50%,
        rgba(255, 255, 255, 0) 60%) var(--color-background-dark);
    background-size: 200% 100%;
    background-position-x: 180%;
    animation: 1s loading ease-in-out infinite;
    border: 1px solid var(--color-brand-dark);
    -webkit-box-shadow: 0 5px 6px 0 var(--color-box-shadow);
    -moz-box-shadow: 0 5px 6px 0 0 var(--color-box-shadow);
    box-shadow: 0 5px 6px 0 var(--color-box-shadow);
  }
  @keyframes loading {
    to {
      background-position-x: -20%;
    }
  }
}
@media only screen and (min-width: 768px) and (max-width: 1023px) {
  :host .placeholder-books div {
    display: block;
    width: calc(50vw - 7rem);
    box-sizing: border-box;
    background-color: var(--color-background-dark);
    background:
      linear-gradient(
        100deg,
        rgba(255, 255, 255, 0) 40%,
        rgba(255, 255, 255, 0.5) 50%,
        rgba(255, 255, 255, 0) 60%) var(--color-background-dark);
    background-size: 200% 100%;
    background-position-x: 180%;
    animation: 1s loading ease-in-out infinite;
    border: 1px solid var(--color-brand-dark);
    -webkit-box-shadow: 0 5px 6px 0 var(--color-box-shadow);
    -moz-box-shadow: 0 5px 6px 0 0 var(--color-box-shadow);
    box-shadow: 0 5px 6px 0 var(--color-box-shadow);
  }
  @keyframes loading {
    to {
      background-position-x: -20%;
    }
  }
}
@media only screen and (min-width: 480px) and (max-width: 767px) {
  :host .placeholder-books div {
    display: block;
    width: calc(50vw - 4rem);
    box-sizing: border-box;
    background-color: var(--color-background-dark);
    background:
      linear-gradient(
        100deg,
        rgba(255, 255, 255, 0) 40%,
        rgba(255, 255, 255, 0.5) 50%,
        rgba(255, 255, 255, 0) 60%) var(--color-background-dark);
    background-size: 200% 100%;
    background-position-x: 180%;
    animation: 1s loading ease-in-out infinite;
    border: 1px solid var(--color-brand-dark);
    -webkit-box-shadow: 0 5px 6px 0 var(--color-box-shadow);
    -moz-box-shadow: 0 5px 6px 0 0 var(--color-box-shadow);
    box-shadow: 0 5px 6px 0 var(--color-box-shadow);
  }
  @keyframes loading {
    to {
      background-position-x: -20%;
    }
  }
}
@media only screen and (min-width: 320px) and (max-width: 479px) {
  :host .placeholder-books div {
    display: block;
    width: calc(100vw - 10rem);
    box-sizing: border-box;
    background-color: var(--color-background-dark);
    background:
      linear-gradient(
        100deg,
        rgba(255, 255, 255, 0) 40%,
        rgba(255, 255, 255, 0.5) 50%,
        rgba(255, 255, 255, 0) 60%) var(--color-background-dark);
    background-size: 200% 100%;
    background-position-x: 180%;
    animation: 1s loading ease-in-out infinite;
    border: 1px solid var(--color-brand-dark);
    -webkit-box-shadow: 0 5px 6px 0 var(--color-box-shadow);
    -moz-box-shadow: 0 5px 6px 0 0 var(--color-box-shadow);
    box-shadow: 0 5px 6px 0 var(--color-box-shadow);
  }
  @keyframes loading {
    to {
      background-position-x: -20%;
    }
  }
}
@media only screen and (min-width: 480px) and (max-width: 767px) {
  :host .placeholder-books div {
    margin: 1rem;
  }
}
@media only screen and (min-width: 320px) and (max-width: 767px) {
  :host .placeholder-social {
    flex-flow: wrap;
  }
}
:host .placeholder-social div {
  aspect-ratio: 1/1;
  margin: 0 4rem 2rem;
}
@media only screen and (min-width: 1024px) {
  :host .placeholder-social div {
    display: block;
    width: calc(14rem + 2px);
    box-sizing: border-box;
    background-color: var(--color-background-dark);
    background:
      linear-gradient(
        100deg,
        rgba(255, 255, 255, 0) 40%,
        rgba(255, 255, 255, 0.5) 50%,
        rgba(255, 255, 255, 0) 60%) var(--color-background-dark);
    background-size: 200% 100%;
    background-position-x: 180%;
    animation: 1s loading ease-in-out infinite;
    border: 1px solid var(--color-brand-dark);
    -webkit-box-shadow: 0 5px 6px 0 var(--color-box-shadow);
    -moz-box-shadow: 0 5px 6px 0 0 var(--color-box-shadow);
    box-shadow: 0 5px 6px 0 var(--color-box-shadow);
  }
  @keyframes loading {
    to {
      background-position-x: -20%;
    }
  }
}
@media only screen and (min-width: 768px) and (max-width: 1023px) {
  :host .placeholder-social div {
    display: block;
    width: 10rem;
    box-sizing: border-box;
    background-color: var(--color-background-dark);
    background:
      linear-gradient(
        100deg,
        rgba(255, 255, 255, 0) 40%,
        rgba(255, 255, 255, 0.5) 50%,
        rgba(255, 255, 255, 0) 60%) var(--color-background-dark);
    background-size: 200% 100%;
    background-position-x: 180%;
    animation: 1s loading ease-in-out infinite;
    border: 1px solid var(--color-brand-dark);
    -webkit-box-shadow: 0 5px 6px 0 var(--color-box-shadow);
    -moz-box-shadow: 0 5px 6px 0 0 var(--color-box-shadow);
    box-shadow: 0 5px 6px 0 var(--color-box-shadow);
  }
  @keyframes loading {
    to {
      background-position-x: -20%;
    }
  }
}
@media only screen and (min-width: 480px) and (max-width: 767px) {
  :host .placeholder-social div {
    display: block;
    width: 9rem;
    box-sizing: border-box;
    background-color: var(--color-background-dark);
    background:
      linear-gradient(
        100deg,
        rgba(255, 255, 255, 0) 40%,
        rgba(255, 255, 255, 0.5) 50%,
        rgba(255, 255, 255, 0) 60%) var(--color-background-dark);
    background-size: 200% 100%;
    background-position-x: 180%;
    animation: 1s loading ease-in-out infinite;
    border: 1px solid var(--color-brand-dark);
    -webkit-box-shadow: 0 5px 6px 0 var(--color-box-shadow);
    -moz-box-shadow: 0 5px 6px 0 0 var(--color-box-shadow);
    box-shadow: 0 5px 6px 0 var(--color-box-shadow);
  }
  @keyframes loading {
    to {
      background-position-x: -20%;
    }
  }
}
@media only screen and (min-width: 320px) and (max-width: 479px) {
  :host .placeholder-social div {
    display: block;
    width: 7rem;
    box-sizing: border-box;
    background-color: var(--color-background-dark);
    background:
      linear-gradient(
        100deg,
        rgba(255, 255, 255, 0) 40%,
        rgba(255, 255, 255, 0.5) 50%,
        rgba(255, 255, 255, 0) 60%) var(--color-background-dark);
    background-size: 200% 100%;
    background-position-x: 180%;
    animation: 1s loading ease-in-out infinite;
    border: 1px solid var(--color-brand-dark);
    -webkit-box-shadow: 0 5px 6px 0 var(--color-box-shadow);
    -moz-box-shadow: 0 5px 6px 0 0 var(--color-box-shadow);
    box-shadow: 0 5px 6px 0 var(--color-box-shadow);
  }
  @keyframes loading {
    to {
      background-position-x: -20%;
    }
  }
}
@media only screen and (min-width: 768px) and (max-width: 1023px) {
  :host .placeholder-social div {
    margin: 0 1rem 2rem;
  }
}
@media only screen and (min-width: 480px) and (max-width: 767px) {
  :host .placeholder-social div {
    margin: 0 2rem 3rem;
  }
}
@media only screen and (min-width: 320px) and (max-width: 479px) {
  :host .placeholder-social div {
    margin: 0 1rem 2rem;
  }
}
:host .placeholder-newsletter {
  display: block;
  width: 402px;
  height: 450px;
  box-sizing: border-box;
  background-color: var(--color-background-dark);
  background:
    linear-gradient(
      100deg,
      rgba(255, 255, 255, 0) 40%,
      rgba(255, 255, 255, 0.5) 50%,
      rgba(255, 255, 255, 0) 60%) var(--color-background-dark);
  background-size: 200% 100%;
  background-position-x: 180%;
  animation: 1s loading ease-in-out infinite;
  border: 1px solid var(--color-brand-dark);
  -webkit-box-shadow: 0 5px 6px 0 var(--color-box-shadow);
  -moz-box-shadow: 0 5px 6px 0 0 var(--color-box-shadow);
  box-shadow: 0 5px 6px 0 var(--color-box-shadow);
}
@keyframes loading {
  to {
    background-position-x: -20%;
  }
}
/*# sourceMappingURL=home.component.css.map */
