import { ChangeDetectionStrategy, Component, Input, OnInit, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSort } from '@angular/material/sort';
import { MatSortModule } from '@angular/material/sort';
import { RouterModule } from '@angular/router';

import { ArticleChipsComponent } from './chips/article-chips.component';
import { ChipsPipe } from '../../../pipes/chips.pipe';
import { GetLogoBasedOnAuthor } from '../../../pages/dynamic-page/dynamic-page.pipe';
import { MediaDirectivesModule } from '../../../directives/media/media-directives.module';
import { SharedPipesModule } from '../../../pipes/shared-pipes.module';
import { TableArticleFilterComponent } from './filter/table-article-filter.component';
import { TableGenericComponent } from '../generic/table/table-generic.component';

export interface ArticleRow {
  author: string;
  date: Date;
  description?: string;
  chips?: string[];
  title: string;
  subtitle?: string;
  url?: string;
}

@Component({
  selector: 'app-table-article',
  templateUrl: './table-article.component.html',
  styleUrls: ['./table-article.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    ArticleChipsComponent,
    CommonModule,
    MatIconModule,
    MatProgressSpinnerModule,
    MatSortModule,
    MatTableModule,
    MatTooltipModule,
    MediaDirectivesModule,
    RouterModule,
    SharedPipesModule,
    TableArticleFilterComponent,
    TableGenericComponent,
    GetLogoBasedOnAuthor,
  ]
})
export class TableArticleComponent extends TableGenericComponent<ArticleRow> implements OnInit {
  protected readonly displayedColumns = ['position', 'title', 'author', 'date'];

  @Input() protected apologetics = false;
  @Input() protected section: string;

  protected readonly authorsCounter = new Map<string, number>();
  protected readonly chipsCounter = new Map<string, number>();

  @ViewChild(MatSort) matSort: MatSort;

  protected authors: string[];

  ngOnInit(): void {
    super.ngOnInit();
    this.dataSource.data.forEach(row => {
      this.authorsCounter.set(row.author, (this.authorsCounter.get(row.author) || 0) + 1);
      row.chips?.forEach(chip => {
        this.chipsCounter.set(chip, (this.chipsCounter.get(chip) || 0) + 1);
      })
    })
    this.initAuthors();
    this.updateChips();
  }

  private initAuthors(): void {
    this.authors = this.data ? this.data
      ?.filter((v, i, a) => a.findIndex(v2 => (v2.author === v.author)) === i)
      .map(row => row.author) : [];
  }

  private updateChips(): void {
    if (this.apologetics) {
      ChipsPipe.setApologeticsChips();
    } else {
      ChipsPipe.setElaborationChips();
    }
  }
}
