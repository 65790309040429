import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ClipboardModule } from '@angular/cdk/clipboard';

import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';

import { ArticleRow, TableArticleComponent } from '../../components/shared-tables/articles/table-article.component';
import { ButtonComponent } from '../../components/button/button.component';
import { environment } from '../../../environments/environment';
import { MediaDirectivesModule } from '../../directives/media/media-directives.module';
import { NAVIGATION_ITEMS } from '../../services/navigation-cache/navigation-cache.model';
import { NavigationCacheService } from '../../services/navigation-cache/navigation-cache.service';
import { SharedPipesModule } from '../../pipes/shared-pipes.module';
import { SnackbarService } from '../../components/snackbar/snackbar.service';
import { SnackBarType } from '../../components/snackbar/snackbar-type.enum';
import { SubComponent } from '../../components/utils/sub/sub.component';
import { TableCommentariesComponent as TableComponent } from '../../components/shared-tables/commentaries/table-commentaries.component';
import { TableHelper } from '../table.helper';

@Component({
  templateUrl: './table-apologetics.component.html',
  styleUrls: ['./table-apologetics.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    ButtonComponent,
    ClipboardModule,
    MatIconModule,
    MediaDirectivesModule,
    RouterModule,
    SharedPipesModule,
    TableArticleComponent,
    TableComponent,
  ],
})
export class TableApologeticsComponent extends SubComponent implements OnInit {
  protected readonly environment = environment;

  protected data: ArticleRow[] = [];
  protected isLoading = false;

  private isError = false;

  constructor(private cdr: ChangeDetectorRef,
              private navigationCacheService: NavigationCacheService,
              private snackbarService: SnackbarService) {
    super();
  }

  ngOnInit(): void {
    this.getNavigationItemFromStorage();
    if (!this.data) {
      this.fetchNavigation();
    }
  }

  protected copyEmail(): void {
    this.snackbarService.open('Skopiowano', SnackBarType.COPY, 2000);
  }

  private getNavigationItemFromStorage(): void {
    this.data = TableHelper.mapToArticleRow(TableHelper.getNavigationItemFromStorage(NAVIGATION_ITEMS.APOLOGETYKA));
  }

  private fetchNavigation(): void {
    this.isLoading = true;
    this.subscription.add(this.navigationCacheService.navigation$.subscribe(value => {
      if (value.isError) {
        this.isError = true;
      } else {
        this.data = TableHelper.mapToArticleRow(TableHelper.getNavigationItem(value.data, NAVIGATION_ITEMS.APOLOGETYKA));
      }
      this.isLoading = false;
      this.cdr.markForCheck();
    }));
  }
}
