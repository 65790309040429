import { ChangeDetectionStrategy, Component, HostBinding, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';

import { QueryParamValuePipe } from './pipes/query-param-value.pipe';
import { RouterLinkPipe } from './pipes/router-link.pipe';
import { SharedPipesModule } from '../../../pipes/shared-pipes.module';
import { RouterLinkAsQueryPipe } from './pipes/router-link-as-query.pipe';

@Component({
  selector: 'app-element-link',
  templateUrl: './element-link.component.html',
  styleUrls: ['./element-link.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    MatIconModule,
    QueryParamValuePipe,
    RouterLinkAsQueryPipe,
    RouterLinkPipe,
    RouterModule,
    SharedPipesModule,
  ]
})
export class ElementLinkComponent implements OnInit {
  @HostBinding('class.active-link')
  @Input() protected readonly isActive = false;
  @Input() protected readonly abbreviation: string;
  @Input() protected link: string;
  @Input() protected readonly svgId: string;
  @Input() protected readonly asQueryParams: boolean;
  @Input() protected readonly smallSvgId: string;
  @Input() protected readonly url: string;
  @Input({ required: true }) protected readonly name: string;

  ngOnInit(): void {
    this.link = this.url || this.link || this.name;
  }
}
