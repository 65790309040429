/* src/app/components/footer/footer.component.scss */
:root {
  --color-brand: #4caf50;
  --color-brand-dark: #306239;
  --color-brand-very-dark: #2f5339;
  --color-brand-hover: #2f5339;
  --color-background: #2c303a;
  --color-background-dark: #1e222b;
  --color-background-very-dark: #000;
  --color-background-panel: #303541;
  --color-text-foreground-hover: #4caf50;
  --color-foreground: #1b1b2f;
  --color-header: #ddd;
  --color-text: #ccc;
  --color-text-background-dark: #ccc;
  --color-text-hover-background-dark: #fff;
  --color-text-hover: #fff;
  --color-text-disable: #999;
  --color-disable: #666;
  --color-disable-light: #444;
  --color-box-shadow: #111;
  --color-icons: #1c2a36;
  --color-scrollbar-track: #222;
  --color-scrollbar-thumb: #444;
  --color-scrollbar-thumb-hover: #555;
  --brightness-default: 1.1;
  --brightness-high: 1.5;
  --color-footer-background: #1b1b2f;
  --color-footer: #ccc;
  --color-footer-hover: #4caf50;
  --color-top-bar-background: #1b1b2f;
  --color-top-bar-hover: #4caf50;
  --color-bible-search-strong: #4caf50;
  --color-bible-verse: #4caf50;
  --color-strong-text-disable: #888;
  --color-strong-text-translation: #ddd;
  --color-strong-text-transliteration: #deb887;
  --color-strong-text-root: #8fbc8f;
  --color-strong-text-number: #bbb;
  --color-strong-text-less-import: #aaa;
  --color-concordance-dialog-text-translation: #7fbce1;
  --color-book-info-1: #7eafc9;
  --color-book-info-2: #70bd63;
  --color-book-info-3: #d4dc73;
  --color-book-info-4: #d28d60;
  --color-bulb: #e7e75b;
  --color-breadcrumb: #ccc;
  --color-input-background: #2c303a;
  --color-chip-background: #ccc;
  --color-chip-hover: #000;
  --color-logo: #fff;
  --color-text-rgb:
    204,
    204,
    204;
  --color-text-hover-rgb:
    204,
    204,
    204;
  --color-brand-rgb:
    76,
    175,
    80;
  --color-background-rgb:
    44,
    48,
    58;
  --color-background-dark-rgb:
    30,
    34,
    43;
  --color-social-gradient-1-rgb:
    38,
    64,
    50;
  --color-social-gradient-2-rgb:
    38,
    64,
    50;
}
@media only screen and (min-width: 1024px) {
  .hidden-desktop {
    display: none !important;
  }
}
@media only screen and (min-width: 480px) and (max-width: 767px) {
  .hidden-landscape {
    display: none !important;
  }
}
@media only screen and (min-width: 320px) and (max-width: 479px) {
  .hidden-portrait {
    display: none !important;
  }
}
@media only screen and (min-width: 1024px) {
  .hidden-desktop-tablet {
    display: none !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .hidden-desktop-tablet {
    display: none !important;
  }
}
@media only screen and (min-width: 480px) and (max-width: 767px) {
  .hidden-landscape-portrait {
    display: none !important;
  }
}
@media only screen and (min-width: 320px) and (max-width: 479px) {
  .hidden-landscape-portrait {
    display: none !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .hidden-tablet-landscape-portrait {
    display: none !important;
  }
}
@media only screen and (min-width: 480px) and (max-width: 767px) {
  .hidden-tablet-landscape-portrait {
    display: none !important;
  }
}
@media only screen and (min-width: 320px) and (max-width: 479px) {
  .hidden-tablet-landscape-portrait {
    display: none !important;
  }
}
@media only screen and (min-width: 1024px) {
  .hidden-desktop-tablet-landscape {
    display: none !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .hidden-desktop-tablet-landscape {
    display: none !important;
  }
}
@media only screen and (min-width: 480px) and (max-width: 767px) {
  .hidden-desktop-tablet-landscape {
    display: none !important;
  }
}
@media only screen and (min-width: 1024px) {
  .hidden {
    display: none !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .hidden {
    display: none !important;
  }
}
@media only screen and (min-width: 480px) and (max-width: 767px) {
  .hidden {
    display: none !important;
  }
}
@media only screen and (min-width: 320px) and (max-width: 479px) {
  .hidden {
    display: none !important;
  }
}
.center.right {
  display: flex;
  justify-content: flex-end;
}
.center.block {
  display: block;
  text-align: center;
}
.flex {
  display: flex;
}
.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.box-shadow {
  -webkit-box-shadow: 0 5px 6px 0 var(--color-box-shadow);
  -moz-box-shadow: 0 5px 6px 0 0 var(--color-box-shadow);
  box-shadow: 0 5px 6px 0 var(--color-box-shadow);
}
.image-box {
  -webkit-box-shadow: 0 5px 6px 0 var(--color-box-shadow);
  -moz-box-shadow: 0 5px 6px 0 0 var(--color-box-shadow);
  box-shadow: 0 5px 6px 0 var(--color-box-shadow);
  border: 1px solid var(--color-disable);
}
.hover {
  cursor: pointer;
}
:root {
  --color-brand: #4caf50;
  --color-brand-dark: #306239;
  --color-brand-very-dark: #2f5339;
  --color-brand-hover: #2f5339;
  --color-background: #2c303a;
  --color-background-dark: #1e222b;
  --color-background-very-dark: #000;
  --color-background-panel: #303541;
  --color-text-foreground-hover: #4caf50;
  --color-foreground: #1b1b2f;
  --color-header: #ddd;
  --color-text: #ccc;
  --color-text-background-dark: #ccc;
  --color-text-hover-background-dark: #fff;
  --color-text-hover: #fff;
  --color-text-disable: #999;
  --color-disable: #666;
  --color-disable-light: #444;
  --color-box-shadow: #111;
  --color-icons: #1c2a36;
  --color-scrollbar-track: #222;
  --color-scrollbar-thumb: #444;
  --color-scrollbar-thumb-hover: #555;
  --brightness-default: 1.1;
  --brightness-high: 1.5;
  --color-footer-background: #1b1b2f;
  --color-footer: #ccc;
  --color-footer-hover: #4caf50;
  --color-top-bar-background: #1b1b2f;
  --color-top-bar-hover: #4caf50;
  --color-bible-search-strong: #4caf50;
  --color-bible-verse: #4caf50;
  --color-strong-text-disable: #888;
  --color-strong-text-translation: #ddd;
  --color-strong-text-transliteration: #deb887;
  --color-strong-text-root: #8fbc8f;
  --color-strong-text-number: #bbb;
  --color-strong-text-less-import: #aaa;
  --color-concordance-dialog-text-translation: #7fbce1;
  --color-book-info-1: #7eafc9;
  --color-book-info-2: #70bd63;
  --color-book-info-3: #d4dc73;
  --color-book-info-4: #d28d60;
  --color-bulb: #e7e75b;
  --color-breadcrumb: #ccc;
  --color-input-background: #2c303a;
  --color-chip-background: #ccc;
  --color-chip-hover: #000;
  --color-logo: #fff;
  --color-text-rgb:
    204,
    204,
    204;
  --color-text-hover-rgb:
    204,
    204,
    204;
  --color-brand-rgb:
    76,
    175,
    80;
  --color-background-rgb:
    44,
    48,
    58;
  --color-background-dark-rgb:
    30,
    34,
    43;
  --color-social-gradient-1-rgb:
    38,
    64,
    50;
  --color-social-gradient-2-rgb:
    38,
    64,
    50;
}
:root {
  --color-brand: #4caf50;
  --color-brand-dark: #306239;
  --color-brand-very-dark: #2f5339;
  --color-brand-hover: #2f5339;
  --color-background: #2c303a;
  --color-background-dark: #1e222b;
  --color-background-very-dark: #000;
  --color-background-panel: #303541;
  --color-text-foreground-hover: #4caf50;
  --color-foreground: #1b1b2f;
  --color-header: #ddd;
  --color-text: #ccc;
  --color-text-background-dark: #ccc;
  --color-text-hover-background-dark: #fff;
  --color-text-hover: #fff;
  --color-text-disable: #999;
  --color-disable: #666;
  --color-disable-light: #444;
  --color-box-shadow: #111;
  --color-icons: #1c2a36;
  --color-scrollbar-track: #222;
  --color-scrollbar-thumb: #444;
  --color-scrollbar-thumb-hover: #555;
  --brightness-default: 1.1;
  --brightness-high: 1.5;
  --color-footer-background: #1b1b2f;
  --color-footer: #ccc;
  --color-footer-hover: #4caf50;
  --color-top-bar-background: #1b1b2f;
  --color-top-bar-hover: #4caf50;
  --color-bible-search-strong: #4caf50;
  --color-bible-verse: #4caf50;
  --color-strong-text-disable: #888;
  --color-strong-text-translation: #ddd;
  --color-strong-text-transliteration: #deb887;
  --color-strong-text-root: #8fbc8f;
  --color-strong-text-number: #bbb;
  --color-strong-text-less-import: #aaa;
  --color-concordance-dialog-text-translation: #7fbce1;
  --color-book-info-1: #7eafc9;
  --color-book-info-2: #70bd63;
  --color-book-info-3: #d4dc73;
  --color-book-info-4: #d28d60;
  --color-bulb: #e7e75b;
  --color-breadcrumb: #ccc;
  --color-input-background: #2c303a;
  --color-chip-background: #ccc;
  --color-chip-hover: #000;
  --color-logo: #fff;
  --color-text-rgb:
    204,
    204,
    204;
  --color-text-hover-rgb:
    204,
    204,
    204;
  --color-brand-rgb:
    76,
    175,
    80;
  --color-background-rgb:
    44,
    48,
    58;
  --color-background-dark-rgb:
    30,
    34,
    43;
  --color-social-gradient-1-rgb:
    38,
    64,
    50;
  --color-social-gradient-2-rgb:
    38,
    64,
    50;
}
:host footer {
  min-height: 297px;
  color: var(--color-footer);
  letter-spacing: 0.5px;
  line-height: 2;
  background-color: var(--color-footer-background);
  box-shadow:
    inset 0 0 60px 19px rgba(0, 0, 0, 0.5),
    0 -6px 10px 0 rgba(0, 0, 0, 0.3),
    0 -9px 18px 0 rgba(0, 0, 0, 0.1);
}
:host footer p {
  color: var(--color-footer);
}
@media only screen and (min-width: 768px) and (max-width: 1023px) {
  :host footer {
    min-height: 336px;
    height: inherit;
  }
}
@media only screen and (min-width: 320px) and (max-width: 479px) {
  :host footer {
    min-height: 393px;
    height: inherit;
  }
}
:host footer .footer-up {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;
  padding: 1rem 0;
  max-width: 1000px;
}
:host footer .footer-up .logo-link:focus {
  outline: none;
}
:host footer .footer-up .logo-link:focus .logo ::ng-deep svg g,
:host footer .footer-up .logo-link:hover .logo ::ng-deep svg g {
  fill: var(--color-top-bar-hover);
}
:host footer .footer-up .logo-link .logo {
  height: 4rem;
  width: 12rem;
  margin-top: 1rem;
  fill: var(--color-footer);
}
:host footer .footer-up .logo-link .logo ::ng-deep svg g {
  transition: 400ms cubic-bezier(0.79, 0, 0.22, 1);
}
:host footer .footer-up .logo-link .logo:hover {
  cursor: pointer;
}
:host footer .verse {
  padding-top: 0;
  margin-top: -1rem;
  text-align: right;
}
:host footer .footer-down {
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  padding: 1rem 0 0;
  max-width: 1000px;
  border-top: 2px solid var(--color-footer);
}
@media only screen and (min-width: 480px) and (max-width: 767px) {
  :host footer .footer-down {
    display: block;
  }
}
@media only screen and (min-width: 320px) and (max-width: 479px) {
  :host footer .footer-down {
    display: block;
  }
}
:host footer .footer-down .copyright {
  margin: 0;
}
:host footer .footer-down .copyright a:hover,
:host footer .footer-down .copyright a:focus {
  color: var(--color-top-bar-hover);
}
:host footer .footer-down .socials {
  display: flex;
  align-items: center;
  margin-left: 2rem;
}
:host footer .footer-down .socials .social-link {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  margin: 0 0.5rem;
  border-radius: 50%;
  border: 1px solid var(--color-footer);
  cursor: pointer;
  transition: 400ms cubic-bezier(0.79, 0, 0.22, 1);
}
:host footer .footer-down .socials .social-link:focus {
  outline: none;
}
:host footer .footer-down .socials .social-link:focus,
:host footer .footer-down .socials .social-link:hover {
  background-color: var(--color-background-very-dark);
}
:host footer .footer-down .socials .social-link.link-1 .mat-icon {
  fill: #1877f2;
  height: 80%;
  width: 80%;
}
:host footer .footer-down .socials .social-link.link-2 .mat-icon {
  fill: #f44336;
  height: 80%;
  width: 80%;
}
:host footer .footer-down .socials .social-link.link-3 .mat-icon {
  width: 2rem;
  height: 2rem;
}
@media only screen and (min-width: 768px) and (max-width: 1023px) {
  :host footer {
    padding: 1rem 2rem;
  }
}
@media only screen and (min-width: 480px) and (max-width: 767px) {
  :host footer {
    padding: 1rem 1.5rem;
  }
  :host footer .footer-down .socials {
    justify-content: center;
    margin: 0;
  }
}
@media only screen and (min-width: 320px) and (max-width: 479px) {
  :host footer {
    padding: 1rem 1rem;
  }
  :host footer .footer-down {
    padding: 1rem 0 0 0;
  }
  :host footer .footer-down .copyright {
    margin-right: 0.5rem;
  }
  :host footer .footer-down .socials {
    justify-content: center;
    margin: 0;
  }
  :host footer .footer-down .socials .social-link {
    margin-bottom: 0.5rem;
  }
}
:host footer .links {
  margin: 0 auto;
  padding: 1rem 0 4rem;
  max-width: 1000px;
}
@media only screen and (min-width: 480px) and (max-width: 767px) {
  :host footer .links {
    padding: 2rem 1.5rem 3rem;
  }
}
:host footer .links .page-link {
  color: var(--color-footer);
  display: inline-block;
}
@media only screen and (min-width: 1024px) {
  :host footer .links .page-link {
    width: 25%;
  }
  :host footer .links .page-link:nth-child(3) {
    width: 50%;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1023px) {
  :host footer .links .page-link {
    width: 33%;
  }
}
@media only screen and (min-width: 480px) and (max-width: 767px) {
  :host footer .links .page-link {
    width: 50%;
    margin-bottom: 0.5rem;
  }
}
@media only screen and (min-width: 320px) and (max-width: 479px) {
  :host footer .links .page-link {
    width: 50%;
    margin-bottom: 0.5rem;
  }
  :host footer .links .page-link:nth-child(5),
  :host footer .links .page-link:nth-child(6) {
    width: 100%;
  }
}
:host footer .links .page-link:hover,
:host footer .links .page-link:focus {
  color: var(--color-footer-hover);
}
:host footer .links .page-link:last-of-type {
  margin-right: 0;
}
/*# sourceMappingURL=footer.component.css.map */
