import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { MatTooltipModule } from '@angular/material/tooltip';

import { BookDownloadComponent } from '../../../../components/book-download/book-download.component';
import { DynamicPageBook } from '../../../../services/dynamic-page/dynamic-page.model';
import { DynamicPageParagraphComponent } from '../paragraph/dynamic-page-paragraph.component';
import { GoogleUrlPipe } from '../../../../pipes/google-url.pipe';
import { HeaderComponent } from '../../../../components/header/header/header.component';
import { MediaDirectivesModule } from '../../../../directives/media/media-directives.module';
import { SwapImagesComponent } from '../../../../components/swap-images/swap-images.component';

@Component({
  selector: 'app-dynamic-page-book',
  standalone: true,
  imports: [
    BookDownloadComponent,
    DynamicPageParagraphComponent,
    GoogleUrlPipe,
    HeaderComponent,
    MatTooltipModule,
    MediaDirectivesModule,
    SwapImagesComponent
],
  templateUrl: './dynamic-page-book.component.html',
  styleUrls: ['./dynamic-page-book.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DynamicPageBookComponent {
  @Input({ required: true }) protected readonly book: DynamicPageBook;
}
