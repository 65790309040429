/* src/app/components/home/welcome/welcome.component.scss */
:root {
  --color-brand: #4caf50;
  --color-brand-dark: #306239;
  --color-brand-very-dark: #2f5339;
  --color-brand-hover: #2f5339;
  --color-background: #2c303a;
  --color-background-dark: #1e222b;
  --color-background-very-dark: #000;
  --color-background-panel: #303541;
  --color-text-foreground-hover: #4caf50;
  --color-foreground: #1b1b2f;
  --color-header: #ddd;
  --color-text: #ccc;
  --color-text-background-dark: #ccc;
  --color-text-hover-background-dark: #fff;
  --color-text-hover: #fff;
  --color-text-disable: #999;
  --color-disable: #666;
  --color-disable-light: #444;
  --color-box-shadow: #111;
  --color-icons: #1c2a36;
  --color-scrollbar-track: #222;
  --color-scrollbar-thumb: #444;
  --color-scrollbar-thumb-hover: #555;
  --brightness-default: 1.1;
  --brightness-high: 1.5;
  --color-footer-background: #1b1b2f;
  --color-footer: #ccc;
  --color-footer-hover: #4caf50;
  --color-top-bar-background: #1b1b2f;
  --color-top-bar-hover: #4caf50;
  --color-bible-search-strong: #4caf50;
  --color-bible-verse: #4caf50;
  --color-strong-text-disable: #888;
  --color-strong-text-translation: #ddd;
  --color-strong-text-transliteration: #deb887;
  --color-strong-text-root: #8fbc8f;
  --color-strong-text-number: #bbb;
  --color-strong-text-less-import: #aaa;
  --color-concordance-dialog-text-translation: #7fbce1;
  --color-book-info-1: #7eafc9;
  --color-book-info-2: #70bd63;
  --color-book-info-3: #d4dc73;
  --color-book-info-4: #d28d60;
  --color-bulb: #e7e75b;
  --color-breadcrumb: #ccc;
  --color-input-background: #2c303a;
  --color-chip-background: #ccc;
  --color-chip-hover: #000;
  --color-logo: #fff;
  --color-text-rgb:
    204,
    204,
    204;
  --color-text-hover-rgb:
    204,
    204,
    204;
  --color-brand-rgb:
    76,
    175,
    80;
  --color-background-rgb:
    44,
    48,
    58;
  --color-background-dark-rgb:
    30,
    34,
    43;
  --color-social-gradient-1-rgb:
    38,
    64,
    50;
  --color-social-gradient-2-rgb:
    38,
    64,
    50;
}
@media only screen and (min-width: 1024px) {
  .hidden-desktop {
    display: none !important;
  }
}
@media only screen and (min-width: 480px) and (max-width: 767px) {
  .hidden-landscape {
    display: none !important;
  }
}
@media only screen and (min-width: 320px) and (max-width: 479px) {
  .hidden-portrait {
    display: none !important;
  }
}
@media only screen and (min-width: 1024px) {
  .hidden-desktop-tablet {
    display: none !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .hidden-desktop-tablet {
    display: none !important;
  }
}
@media only screen and (min-width: 480px) and (max-width: 767px) {
  .hidden-landscape-portrait {
    display: none !important;
  }
}
@media only screen and (min-width: 320px) and (max-width: 479px) {
  .hidden-landscape-portrait {
    display: none !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .hidden-tablet-landscape-portrait {
    display: none !important;
  }
}
@media only screen and (min-width: 480px) and (max-width: 767px) {
  .hidden-tablet-landscape-portrait {
    display: none !important;
  }
}
@media only screen and (min-width: 320px) and (max-width: 479px) {
  .hidden-tablet-landscape-portrait {
    display: none !important;
  }
}
@media only screen and (min-width: 1024px) {
  .hidden-desktop-tablet-landscape {
    display: none !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .hidden-desktop-tablet-landscape {
    display: none !important;
  }
}
@media only screen and (min-width: 480px) and (max-width: 767px) {
  .hidden-desktop-tablet-landscape {
    display: none !important;
  }
}
@media only screen and (min-width: 1024px) {
  .hidden {
    display: none !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .hidden {
    display: none !important;
  }
}
@media only screen and (min-width: 480px) and (max-width: 767px) {
  .hidden {
    display: none !important;
  }
}
@media only screen and (min-width: 320px) and (max-width: 479px) {
  .hidden {
    display: none !important;
  }
}
.center.right {
  display: flex;
  justify-content: flex-end;
}
.center.block {
  display: block;
  text-align: center;
}
.flex {
  display: flex;
}
.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.box-shadow {
  -webkit-box-shadow: 0 5px 6px 0 var(--color-box-shadow);
  -moz-box-shadow: 0 5px 6px 0 0 var(--color-box-shadow);
  box-shadow: 0 5px 6px 0 var(--color-box-shadow);
}
.image-box {
  -webkit-box-shadow: 0 5px 6px 0 var(--color-box-shadow);
  -moz-box-shadow: 0 5px 6px 0 0 var(--color-box-shadow);
  box-shadow: 0 5px 6px 0 var(--color-box-shadow);
  border: 1px solid var(--color-disable);
}
.hover {
  cursor: pointer;
}
:root {
  --color-brand: #4caf50;
  --color-brand-dark: #306239;
  --color-brand-very-dark: #2f5339;
  --color-brand-hover: #2f5339;
  --color-background: #2c303a;
  --color-background-dark: #1e222b;
  --color-background-very-dark: #000;
  --color-background-panel: #303541;
  --color-text-foreground-hover: #4caf50;
  --color-foreground: #1b1b2f;
  --color-header: #ddd;
  --color-text: #ccc;
  --color-text-background-dark: #ccc;
  --color-text-hover-background-dark: #fff;
  --color-text-hover: #fff;
  --color-text-disable: #999;
  --color-disable: #666;
  --color-disable-light: #444;
  --color-box-shadow: #111;
  --color-icons: #1c2a36;
  --color-scrollbar-track: #222;
  --color-scrollbar-thumb: #444;
  --color-scrollbar-thumb-hover: #555;
  --brightness-default: 1.1;
  --brightness-high: 1.5;
  --color-footer-background: #1b1b2f;
  --color-footer: #ccc;
  --color-footer-hover: #4caf50;
  --color-top-bar-background: #1b1b2f;
  --color-top-bar-hover: #4caf50;
  --color-bible-search-strong: #4caf50;
  --color-bible-verse: #4caf50;
  --color-strong-text-disable: #888;
  --color-strong-text-translation: #ddd;
  --color-strong-text-transliteration: #deb887;
  --color-strong-text-root: #8fbc8f;
  --color-strong-text-number: #bbb;
  --color-strong-text-less-import: #aaa;
  --color-concordance-dialog-text-translation: #7fbce1;
  --color-book-info-1: #7eafc9;
  --color-book-info-2: #70bd63;
  --color-book-info-3: #d4dc73;
  --color-book-info-4: #d28d60;
  --color-bulb: #e7e75b;
  --color-breadcrumb: #ccc;
  --color-input-background: #2c303a;
  --color-chip-background: #ccc;
  --color-chip-hover: #000;
  --color-logo: #fff;
  --color-text-rgb:
    204,
    204,
    204;
  --color-text-hover-rgb:
    204,
    204,
    204;
  --color-brand-rgb:
    76,
    175,
    80;
  --color-background-rgb:
    44,
    48,
    58;
  --color-background-dark-rgb:
    30,
    34,
    43;
  --color-social-gradient-1-rgb:
    38,
    64,
    50;
  --color-social-gradient-2-rgb:
    38,
    64,
    50;
}
:root {
  --color-brand: #4caf50;
  --color-brand-dark: #306239;
  --color-brand-very-dark: #2f5339;
  --color-brand-hover: #2f5339;
  --color-background: #2c303a;
  --color-background-dark: #1e222b;
  --color-background-very-dark: #000;
  --color-background-panel: #303541;
  --color-text-foreground-hover: #4caf50;
  --color-foreground: #1b1b2f;
  --color-header: #ddd;
  --color-text: #ccc;
  --color-text-background-dark: #ccc;
  --color-text-hover-background-dark: #fff;
  --color-text-hover: #fff;
  --color-text-disable: #999;
  --color-disable: #666;
  --color-disable-light: #444;
  --color-box-shadow: #111;
  --color-icons: #1c2a36;
  --color-scrollbar-track: #222;
  --color-scrollbar-thumb: #444;
  --color-scrollbar-thumb-hover: #555;
  --brightness-default: 1.1;
  --brightness-high: 1.5;
  --color-footer-background: #1b1b2f;
  --color-footer: #ccc;
  --color-footer-hover: #4caf50;
  --color-top-bar-background: #1b1b2f;
  --color-top-bar-hover: #4caf50;
  --color-bible-search-strong: #4caf50;
  --color-bible-verse: #4caf50;
  --color-strong-text-disable: #888;
  --color-strong-text-translation: #ddd;
  --color-strong-text-transliteration: #deb887;
  --color-strong-text-root: #8fbc8f;
  --color-strong-text-number: #bbb;
  --color-strong-text-less-import: #aaa;
  --color-concordance-dialog-text-translation: #7fbce1;
  --color-book-info-1: #7eafc9;
  --color-book-info-2: #70bd63;
  --color-book-info-3: #d4dc73;
  --color-book-info-4: #d28d60;
  --color-bulb: #e7e75b;
  --color-breadcrumb: #ccc;
  --color-input-background: #2c303a;
  --color-chip-background: #ccc;
  --color-chip-hover: #000;
  --color-logo: #fff;
  --color-text-rgb:
    204,
    204,
    204;
  --color-text-hover-rgb:
    204,
    204,
    204;
  --color-brand-rgb:
    76,
    175,
    80;
  --color-background-rgb:
    44,
    48,
    58;
  --color-background-dark-rgb:
    30,
    34,
    43;
  --color-social-gradient-1-rgb:
    38,
    64,
    50;
  --color-social-gradient-2-rgb:
    38,
    64,
    50;
}
:host {
  position: relative;
}
:host .mat-expansion-panel {
  background: transparent;
  box-shadow: none;
  z-index: 2;
  position: relative;
  border-radius: 0;
}
:host .mat-expansion-panel .mat-expansion-panel-header {
  height: 100%;
  padding: 2rem 4rem;
  transition: 400ms cubic-bezier(0.79, 0, 0.22, 1);
}
@media only screen and (min-width: 768px) and (max-width: 1023px) {
  :host .mat-expansion-panel .mat-expansion-panel-header {
    padding: 1.5rem 3rem;
  }
}
@media only screen and (min-width: 480px) and (max-width: 767px) {
  :host .mat-expansion-panel .mat-expansion-panel-header {
    padding: 1.5rem 3rem;
  }
}
@media only screen and (min-width: 320px) and (max-width: 479px) {
  :host .mat-expansion-panel .mat-expansion-panel-header {
    padding: 1rem 2rem;
  }
}
:host .mat-expansion-panel .mat-expansion-panel-header .mat-icon {
  height: 10rem;
  width: 35rem;
  animation: fadeIn 4s;
}
@media only screen and (min-width: 601px) and (max-width: 768px) {
  :host .mat-expansion-panel .mat-expansion-panel-header .mat-icon {
    height: 6rem;
    width: 24rem;
  }
}
@media only screen and (min-width: 480px) and (max-width: 600px) {
  :host .mat-expansion-panel .mat-expansion-panel-header .mat-icon {
    height: 5rem;
    width: 18rem;
  }
}
@media only screen and (min-width: 320px) and (max-width: 479px) {
  :host .mat-expansion-panel .mat-expansion-panel-header .mat-icon {
    height: 5rem;
    width: 15rem;
  }
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
:host .mat-expansion-panel .mat-expansion-panel-header:hover,
:host .mat-expansion-panel .mat-expansion-panel-header.cdk-keyboard-focused {
  background-color: var(--color-background-panel);
}
:host .mat-expansion-panel .mat-expansion-panel-header .mat-expansion-panel-header-title {
  justify-content: center;
}
:host .mat-expansion-panel ::ng-deep .mat-expansion-panel-content .mat-expansion-panel-body {
  padding: 0 3rem;
}
@media only screen and (min-width: 768px) and (max-width: 1023px) {
  :host .mat-expansion-panel ::ng-deep .mat-expansion-panel-content .mat-expansion-panel-body {
    padding: 0 2rem;
  }
}
@media only screen and (min-width: 480px) and (max-width: 767px) {
  :host .mat-expansion-panel ::ng-deep .mat-expansion-panel-content .mat-expansion-panel-body {
    padding: 0 1.5rem;
  }
}
@media only screen and (min-width: 320px) and (max-width: 479px) {
  :host .mat-expansion-panel ::ng-deep .mat-expansion-panel-content .mat-expansion-panel-body {
    padding: 0 0.2rem;
  }
}
:host .mat-expansion-panel.mat-expanded ::ng-deep .mat-expansion-panel-content {
  padding-bottom: 2rem;
}
:host .hidden-box {
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  height: 14rem;
}
@media only screen and (min-width: 769px) and (max-width: 1023px) {
  :host .hidden-box {
    height: 13rem;
  }
}
@media only screen and (min-width: 601px) and (max-width: 768px) {
  :host .hidden-box {
    height: 9rem;
  }
}
@media only screen and (min-width: 480px) and (max-width: 600px) {
  :host .hidden-box {
    height: 8rem;
  }
}
@media only screen and (min-width: 320px) and (max-width: 479px) {
  :host .hidden-box {
    height: 7rem;
  }
}
:host .hidden-box:before,
:host .hidden-box:after {
  content: "";
  position: absolute;
  box-sizing: border-box;
  z-index: 3;
  width: 0;
  height: 0;
}
:host .hidden-box:before {
  left: 0;
  top: -3px;
  border-top: 3px solid var(--color-brand);
  border-right: 3px solid var(--color-brand);
}
:host .hidden-box:after {
  right: 0;
  bottom: -3px;
  border-bottom: 3px solid var(--color-brand);
  border-left: 3px solid var(--color-brand);
}
:host-context(.expanded) .hidden-box:before {
  width: 100%;
  -webkit-animation: border-top-right 0.5s;
  -moz-animation: border-top-right 0.5s;
  -ms-animation: border-top-right 0.5s;
  -o-animation: border-top-right 0.5s;
  animation: border-top-right 0.5s;
}
:host-context(.expanded) .hidden-box:after {
  width: 100%;
  -webkit-animation: border-bottom-left 0.5s;
  -moz-animation: border-bottom-left 0.5s;
  -ms-animation: border-bottom-left 0.5s;
  -o-animation: border-bottom-left 0.5s;
  animation: border-bottom-left 0.5s;
}
@-webkit-keyframes border-top-right {
  0% {
    width: 80%;
    height: 0;
  }
  100% {
    width: 100%;
    height: 0;
  }
}
@-o-keyframes border-top-right {
  0% {
    width: 80%;
    height: 0;
  }
  100% {
    width: 100%;
    height: 0;
  }
}
@-ms-keyframes border-top-right {
  0% {
    width: 80%;
    height: 0;
  }
  100% {
    width: 100%;
    height: 0;
  }
}
@keyframes border-top-right {
  0% {
    width: 80%;
    height: 0;
  }
  100% {
    width: 100%;
    height: 0;
  }
}
@-webkit-keyframes border-bottom-left {
  0% {
    width: 90%;
    height: 0;
  }
  100% {
    width: 100%;
    height: 0;
  }
}
@-o-keyframes border-bottom-left {
  0% {
    width: 100%;
    height: 0;
  }
  100% {
    width: 80%;
    height: 0;
  }
}
@-ms-keyframes border-bottom-left {
  0% {
    width: 100%;
    height: 0;
  }
  100% {
    width: 80%;
    height: 0;
  }
}
@keyframes border-bottom-left {
  0% {
    width: 90%;
    height: 0;
  }
  100% {
    width: 100%;
    height: 0;
  }
}
:host-context(.initial) .hidden-box:before,
:host-context(.initial) .hidden-box:after {
  -webkit-animation: initial-border-animation 1.5s;
  -moz-animation: initial-border-animation 1.5s;
  -ms-animation: initial-border-animation 1.5s;
  -o-animation: initial-border-animation 1.5s;
  animation: initial-border-animation 1.5s;
}
@-webkit-keyframes initial-border-animation {
  0% {
    width: 0;
    height: 0;
  }
  100% {
    width: 80%;
    height: 0;
  }
}
@-o-keyframes initial-border-animation {
  0% {
    width: 100%;
    height: 0;
  }
  100% {
    width: 80%;
    height: 0;
  }
}
@-ms-keyframes initial-border-animation {
  0% {
    width: 100%;
    height: 0;
  }
  100% {
    width: 80%;
    height: 0;
  }
}
@keyframes initial-border-animation {
  0% {
    width: 0;
    height: 0;
  }
  100% {
    width: 80%;
    height: 0;
  }
}
:host-context(.initial-loaded) .hidden-box:before,
:host-context(.initial-loaded) .hidden-box:after {
  width: 80%;
}
:host-context(.expand-loaded) .hidden-box:before,
:host-context(.expand-loaded) .hidden-box:after {
  width: 100%;
}
:host-context(.initial-loaded:not(.expanded).expand-loaded) .hidden-box:before,
:host-context(.initial-loaded:not(.expanded).expand-loaded) .hidden-box:after {
  -webkit-animation: back-border-to-not-expanded 0.5s;
  -moz-animation: back-border-to-not-expanded 0.5s;
  -ms-animation: back-border-to-not-expanded 0.5s;
  -o-animation: back-border-to-not-expanded 0.5s;
  animation: back-border-to-not-expanded 0.5s;
}
@-webkit-keyframes back-border-to-not-expanded {
  0% {
    width: 100%;
    height: 0;
  }
  100% {
    width: 80%;
    height: 0;
  }
}
@-o-keyframes back-border-to-not-expanded {
  0% {
    width: 100%;
    height: 0;
  }
  100% {
    width: 80%;
    height: 0;
  }
}
@-ms-keyframes back-border-to-not-expanded {
  0% {
    width: 100%;
    height: 0;
  }
  100% {
    width: 80%;
    height: 0;
  }
}
@keyframes back-border-to-not-expanded {
  0% {
    width: 100%;
    height: 0;
  }
  100% {
    width: 80%;
    height: 0;
  }
}
/*# sourceMappingURL=welcome.component.css.map */
