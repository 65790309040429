import { ActivatedRoute, Router } from '@angular/router';
import { AsyncPipe } from '@angular/common';
import { ChangeDetectorRef, Component, inject, OnInit } from '@angular/core';

import { FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';

import { BreakpointsService } from '../../../../services/breakpoints.service';
import { DashPipe } from '../../../../pipes/dash.pipe';
import { GenericFilterEmitterComponent } from '../../generic/filter/generic-filter-emitter.component';
import { TableCommentariesFilterForm } from './table-commentaries-filter.model';
import { TableGenericFilterComponent } from '../../generic/filter/table-generic-filter.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatNativeDateModule } from '@angular/material/core';
import { MediaDirectivesModule } from '../../../../directives/media/media-directives.module';

export interface StudiesFilter {
  book: string;
  chapter: string;
  startDate: string;
  endDate: string;
}

@Component({
  selector: 'app-table-studies-filter',
  templateUrl: './table-commentaries-filter.component.html',
  styleUrls: ['./table-commentaries-filter.component.scss'],
  standalone: true,
  imports: [
    AsyncPipe,
    MatDatepickerModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatNativeDateModule,
    MatSelectModule,
    MediaDirectivesModule,
    ReactiveFormsModule,
    TableGenericFilterComponent,
  ],
})
export class TableCommentariesFilterComponent extends GenericFilterEmitterComponent<StudiesFilter> implements OnInit {
  protected readonly breakpointService = inject(BreakpointsService);
  private readonly cdr = inject(ChangeDetectorRef);
  private readonly fb = inject(FormBuilder);
  private readonly route = inject(ActivatedRoute);
  private readonly router = inject(Router);

  readonly books: Map<string, number> = new Map([
    ['Pierwszy List do Koryntian', 16],
  ]);

  chapters: number[] = [];

  readonly form: FormGroup<TableCommentariesFilterForm> = this.fb.group({
    book: this.fb.control(''),
    chapter: this.fb.control(''),
    startDate: this.fb.control(''),
    endDate: this.fb.control(''),
  });

  get isDateFilled(): boolean {
    return !!this.form.get('startDate').value || !!this.form.get('endDate').value;
  }

  ngOnInit(): void {
    this.routeChange();
    this.formValueChanges();
  }

  clearDatePickerValue(): void {
    this.form.get('startDate').reset();
    this.form.get('endDate').reset();
  }

  private routeChange(): void {
    this.subscription.add(this.route.queryParamMap.subscribe(value => {
      const bookBasedOnRoute = [...this.books.entries()].find(book => new DashPipe().transform(book[0]) === value.get('ksiega'));
      this.form.get('book').setValue(!!bookBasedOnRoute ? bookBasedOnRoute[0] : '');
      this.updateChapters();
      this.cdr.markForCheck();
    }));
  }

  private formValueChanges(): void {
    this.subscription.add(this.form.valueChanges.subscribe((value: StudiesFilter) => {
      this.applyFilter(value);
      this.updateQueryParams(value.book);
      this.updateChapters();
    }));
  }

  private updateQueryParams(value: string): void {
    this.router.navigate(
      [],
      {
        relativeTo: this.route,
        queryParams: { ksiega: value ? new DashPipe().transform(value) : null },
      });
  }

  private updateChapters(): void {
    this.chapters = Array.from({ length: this.books.get(this.form.get('book').value) }, (_, i) => i + 1);
  }
}
