<div class="page-wrapper">
  <app-table-article class="table" [data]="data" svgIcon="article" title="Opracowania"
                     [isLoading]="isLoading" section="opracowania">
    <p>
      W tym dziale czytelnik odnajdzie artykuły różnych autorów. Omawiane są <b>wybrane
      zagadnienia</b>, a nie poszczególne księgi biblijne.
    </p>

    <p>Opracowania powstały w oparciu o przekład Uwspółczeńsionej Biblii Gdańskiej, o ile nie zaznaczono inaczej.</p>
  </app-table-article>
</div>
