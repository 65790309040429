import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';


import { ArticleRow, TableArticleComponent } from '../../components/shared-tables/articles/table-article.component';
import { NAVIGATION_ITEMS } from '../../services/navigation-cache/navigation-cache.model';
import { NavigationCacheService } from '../../services/navigation-cache/navigation-cache.service';
import { SubComponent } from '../../components/utils/sub/sub.component';
import { TableHelper } from '../table.helper';

@Component({
  templateUrl: './table-elaboration.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    TableArticleComponent
],
})
export class TableElaborationComponent extends SubComponent implements OnInit {
  data: ArticleRow[] = [];

  isError = false;
  isLoading = false;

  constructor(private cdr: ChangeDetectorRef,
              private navigationCacheService: NavigationCacheService,) {
    super();
  }

  ngOnInit(): void {
    this.getNavigationItemFromStorage();
    if (!this.data) {
      this.fetchNavigation();
    }
  }

  private getNavigationItemFromStorage(): void {
    this.data = TableHelper.mapToArticleRow(TableHelper.getNavigationItemFromStorage(NAVIGATION_ITEMS.OPRACOWANIA));
  }

  private fetchNavigation(): void {
    this.isLoading = true;
    this.subscription.add(this.navigationCacheService.navigation$.subscribe(value => {
      if (value.isError) {
        this.isError = true;
      } else {
        this.data = TableHelper.mapToArticleRow(TableHelper.getNavigationItem(value.data, NAVIGATION_ITEMS.OPRACOWANIA));
      }
      this.isLoading = false;
      this.cdr.markForCheck();
    }));
  }
}

