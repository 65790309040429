@if (!asQueryParams) {
  <a tabindex="0" [routerLink]="link | routerLink | plEn">
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </a>
}

@if (asQueryParams && !url) {
  <a tabindex="0" [routerLink]="link | routerLinkAsQuery | plEn" [queryParams]="{ ksiega: link | queryParamValue }">
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </a>
}

@if (asQueryParams && url) {
  <a tabindex="0" [routerLink]="url | plEn">
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </a>
}

<ng-template #content>
  @if (!svgId) {
    @if (abbreviation) {
      <span class="abbreviation">{{ abbreviation }}</span>
    }
    <span class="name" [class.name-with-svg]="smallSvgId">
      @if (smallSvgId) {
        <mat-icon class="nav-icon small-nav-icon" [svgIcon]="'icons:' + smallSvgId"></mat-icon>
      }
      {{ name }}
    </span>
  }

  @if (svgId) {
    <mat-icon class="nav-icon" [svgIcon]="'icons:' + svgId"></mat-icon>{{ name }}
  }
</ng-template>
