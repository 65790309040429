import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

import { YouTubePlayerModule } from '@angular/youtube-player';

import { MediaDirectivesModule } from '../../directives/media/media-directives.module';

@Component({
  selector: 'app-youtube-player',
  templateUrl: './youtube-player.component.html',
  styleUrls: ['./youtube-player.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.Default,
  imports: [
    MediaDirectivesModule,
    YouTubePlayerModule
],
})
export class YoutubePlayerComponent implements OnInit {
  @Input({ required: true }) protected readonly videoId;

  private apiLoaded = false;

  ngOnInit() {
    if (!this.apiLoaded && !!this.videoId) {
      const tag = document.createElement('script');
      tag.src = 'https://www.youtube.com/iframe_api';
      document.body.appendChild(tag);
      this.apiLoaded = true;
    }
  }
}
