import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Pipe({ name: 'themeUrl' })
export class ThemeUrlPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}

  transform(theme: 'light' | 'dark'): SafeResourceUrl {
    return this.sanitizer.bypassSecurityTrustResourceUrl(`${theme}.css`);
  }
}
