import { ChangeDetectionStrategy, Component } from '@angular/core';

import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';

import { AppComponent } from '../../app.component';
import { MediaDirectivesModule } from '../../directives/media/media-directives.module';
import { SimpleFooterComponent } from '../simple-footer/simple-footer.component';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    MatIconModule,
    MediaDirectivesModule,
    RouterModule
]
})
export class FooterComponent extends SimpleFooterComponent {
  protected readonly AppComponent = AppComponent;
  protected readonly currentYear = new Date().getFullYear();
}
