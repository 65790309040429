/* src/app/components/question/question.component.scss */
:root {
  --color-brand: #4caf50;
  --color-brand-dark: #306239;
  --color-brand-very-dark: #2f5339;
  --color-brand-hover: #2f5339;
  --color-background: #2c303a;
  --color-background-dark: #1e222b;
  --color-background-very-dark: #000;
  --color-background-panel: #303541;
  --color-text-foreground-hover: #4caf50;
  --color-foreground: #1b1b2f;
  --color-header: #ddd;
  --color-text: #ccc;
  --color-text-background-dark: #ccc;
  --color-text-hover-background-dark: #fff;
  --color-text-hover: #fff;
  --color-text-disable: #999;
  --color-disable: #666;
  --color-disable-light: #444;
  --color-box-shadow: #111;
  --color-icons: #1c2a36;
  --color-scrollbar-track: #222;
  --color-scrollbar-thumb: #444;
  --color-scrollbar-thumb-hover: #555;
  --brightness-default: 1.1;
  --brightness-high: 1.5;
  --color-footer-background: #1b1b2f;
  --color-footer: #ccc;
  --color-footer-hover: #4caf50;
  --color-top-bar-background: #1b1b2f;
  --color-top-bar-hover: #4caf50;
  --color-bible-search-strong: #4caf50;
  --color-bible-verse: #4caf50;
  --color-strong-text-disable: #888;
  --color-strong-text-translation: #ddd;
  --color-strong-text-transliteration: #deb887;
  --color-strong-text-root: #8fbc8f;
  --color-strong-text-number: #bbb;
  --color-strong-text-less-import: #aaa;
  --color-concordance-dialog-text-translation: #7fbce1;
  --color-book-info-1: #7eafc9;
  --color-book-info-2: #70bd63;
  --color-book-info-3: #d4dc73;
  --color-book-info-4: #d28d60;
  --color-bulb: #e7e75b;
  --color-breadcrumb: #ccc;
  --color-input-background: #2c303a;
  --color-chip-background: #ccc;
  --color-chip-hover: #000;
  --color-logo: #fff;
  --color-text-rgb:
    204,
    204,
    204;
  --color-text-hover-rgb:
    204,
    204,
    204;
  --color-brand-rgb:
    76,
    175,
    80;
  --color-background-rgb:
    44,
    48,
    58;
  --color-background-dark-rgb:
    30,
    34,
    43;
  --color-social-gradient-1-rgb:
    38,
    64,
    50;
  --color-social-gradient-2-rgb:
    38,
    64,
    50;
}
@media only screen and (min-width: 1024px) {
  .hidden-desktop {
    display: none !important;
  }
}
@media only screen and (min-width: 480px) and (max-width: 767px) {
  .hidden-landscape {
    display: none !important;
  }
}
@media only screen and (min-width: 320px) and (max-width: 479px) {
  .hidden-portrait {
    display: none !important;
  }
}
@media only screen and (min-width: 1024px) {
  .hidden-desktop-tablet {
    display: none !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .hidden-desktop-tablet {
    display: none !important;
  }
}
@media only screen and (min-width: 480px) and (max-width: 767px) {
  .hidden-landscape-portrait {
    display: none !important;
  }
}
@media only screen and (min-width: 320px) and (max-width: 479px) {
  .hidden-landscape-portrait {
    display: none !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .hidden-tablet-landscape-portrait {
    display: none !important;
  }
}
@media only screen and (min-width: 480px) and (max-width: 767px) {
  .hidden-tablet-landscape-portrait {
    display: none !important;
  }
}
@media only screen and (min-width: 320px) and (max-width: 479px) {
  .hidden-tablet-landscape-portrait {
    display: none !important;
  }
}
@media only screen and (min-width: 1024px) {
  .hidden-desktop-tablet-landscape {
    display: none !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .hidden-desktop-tablet-landscape {
    display: none !important;
  }
}
@media only screen and (min-width: 480px) and (max-width: 767px) {
  .hidden-desktop-tablet-landscape {
    display: none !important;
  }
}
@media only screen and (min-width: 1024px) {
  .hidden {
    display: none !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .hidden {
    display: none !important;
  }
}
@media only screen and (min-width: 480px) and (max-width: 767px) {
  .hidden {
    display: none !important;
  }
}
@media only screen and (min-width: 320px) and (max-width: 479px) {
  .hidden {
    display: none !important;
  }
}
.center.right {
  display: flex;
  justify-content: flex-end;
}
.center.block {
  display: block;
  text-align: center;
}
.flex {
  display: flex;
}
.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.box-shadow {
  -webkit-box-shadow: 0 5px 6px 0 var(--color-box-shadow);
  -moz-box-shadow: 0 5px 6px 0 0 var(--color-box-shadow);
  box-shadow: 0 5px 6px 0 var(--color-box-shadow);
}
.image-box {
  -webkit-box-shadow: 0 5px 6px 0 var(--color-box-shadow);
  -moz-box-shadow: 0 5px 6px 0 0 var(--color-box-shadow);
  box-shadow: 0 5px 6px 0 var(--color-box-shadow);
  border: 1px solid var(--color-disable);
}
.hover {
  cursor: pointer;
}
:root {
  --color-brand: #4caf50;
  --color-brand-dark: #306239;
  --color-brand-very-dark: #2f5339;
  --color-brand-hover: #2f5339;
  --color-background: #2c303a;
  --color-background-dark: #1e222b;
  --color-background-very-dark: #000;
  --color-background-panel: #303541;
  --color-text-foreground-hover: #4caf50;
  --color-foreground: #1b1b2f;
  --color-header: #ddd;
  --color-text: #ccc;
  --color-text-background-dark: #ccc;
  --color-text-hover-background-dark: #fff;
  --color-text-hover: #fff;
  --color-text-disable: #999;
  --color-disable: #666;
  --color-disable-light: #444;
  --color-box-shadow: #111;
  --color-icons: #1c2a36;
  --color-scrollbar-track: #222;
  --color-scrollbar-thumb: #444;
  --color-scrollbar-thumb-hover: #555;
  --brightness-default: 1.1;
  --brightness-high: 1.5;
  --color-footer-background: #1b1b2f;
  --color-footer: #ccc;
  --color-footer-hover: #4caf50;
  --color-top-bar-background: #1b1b2f;
  --color-top-bar-hover: #4caf50;
  --color-bible-search-strong: #4caf50;
  --color-bible-verse: #4caf50;
  --color-strong-text-disable: #888;
  --color-strong-text-translation: #ddd;
  --color-strong-text-transliteration: #deb887;
  --color-strong-text-root: #8fbc8f;
  --color-strong-text-number: #bbb;
  --color-strong-text-less-import: #aaa;
  --color-concordance-dialog-text-translation: #7fbce1;
  --color-book-info-1: #7eafc9;
  --color-book-info-2: #70bd63;
  --color-book-info-3: #d4dc73;
  --color-book-info-4: #d28d60;
  --color-bulb: #e7e75b;
  --color-breadcrumb: #ccc;
  --color-input-background: #2c303a;
  --color-chip-background: #ccc;
  --color-chip-hover: #000;
  --color-logo: #fff;
  --color-text-rgb:
    204,
    204,
    204;
  --color-text-hover-rgb:
    204,
    204,
    204;
  --color-brand-rgb:
    76,
    175,
    80;
  --color-background-rgb:
    44,
    48,
    58;
  --color-background-dark-rgb:
    30,
    34,
    43;
  --color-social-gradient-1-rgb:
    38,
    64,
    50;
  --color-social-gradient-2-rgb:
    38,
    64,
    50;
}
:root {
  --color-brand: #4caf50;
  --color-brand-dark: #306239;
  --color-brand-very-dark: #2f5339;
  --color-brand-hover: #2f5339;
  --color-background: #2c303a;
  --color-background-dark: #1e222b;
  --color-background-very-dark: #000;
  --color-background-panel: #303541;
  --color-text-foreground-hover: #4caf50;
  --color-foreground: #1b1b2f;
  --color-header: #ddd;
  --color-text: #ccc;
  --color-text-background-dark: #ccc;
  --color-text-hover-background-dark: #fff;
  --color-text-hover: #fff;
  --color-text-disable: #999;
  --color-disable: #666;
  --color-disable-light: #444;
  --color-box-shadow: #111;
  --color-icons: #1c2a36;
  --color-scrollbar-track: #222;
  --color-scrollbar-thumb: #444;
  --color-scrollbar-thumb-hover: #555;
  --brightness-default: 1.1;
  --brightness-high: 1.5;
  --color-footer-background: #1b1b2f;
  --color-footer: #ccc;
  --color-footer-hover: #4caf50;
  --color-top-bar-background: #1b1b2f;
  --color-top-bar-hover: #4caf50;
  --color-bible-search-strong: #4caf50;
  --color-bible-verse: #4caf50;
  --color-strong-text-disable: #888;
  --color-strong-text-translation: #ddd;
  --color-strong-text-transliteration: #deb887;
  --color-strong-text-root: #8fbc8f;
  --color-strong-text-number: #bbb;
  --color-strong-text-less-import: #aaa;
  --color-concordance-dialog-text-translation: #7fbce1;
  --color-book-info-1: #7eafc9;
  --color-book-info-2: #70bd63;
  --color-book-info-3: #d4dc73;
  --color-book-info-4: #d28d60;
  --color-bulb: #e7e75b;
  --color-breadcrumb: #ccc;
  --color-input-background: #2c303a;
  --color-chip-background: #ccc;
  --color-chip-hover: #000;
  --color-logo: #fff;
  --color-text-rgb:
    204,
    204,
    204;
  --color-text-hover-rgb:
    204,
    204,
    204;
  --color-brand-rgb:
    76,
    175,
    80;
  --color-background-rgb:
    44,
    48,
    58;
  --color-background-dark-rgb:
    30,
    34,
    43;
  --color-social-gradient-1-rgb:
    38,
    64,
    50;
  --color-social-gradient-2-rgb:
    38,
    64,
    50;
}
:host {
  display: block;
  max-width: 1000px;
  margin: 0 auto;
  padding: 1rem 0;
  letter-spacing: 0.5px;
  line-height: 2;
  margin-top: 3rem;
  margin-bottom: 1rem;
}
:host .question {
  border: 2px solid var(--color-brand);
  border-radius: 2rem;
  padding: 3rem;
  position: relative;
  background-color: rgba(var(--color-background-dark-rgb), 0.25);
}
@media only screen and (min-width: 768px) and (max-width: 1023px) {
  :host .question {
    padding: 2.5rem;
  }
}
@media only screen and (min-width: 480px) and (max-width: 767px) {
  :host .question {
    padding: 1.5rem 2rem;
  }
}
@media only screen and (min-width: 320px) and (max-width: 479px) {
  :host .question {
    padding: 1rem 1.5rem;
  }
}
:host .question::before {
  content: "";
  float: left;
  height: 5rem;
  width: 7rem;
}
@media only screen and (min-width: 320px) and (max-width: 479px) {
  :host .question::before {
    width: 6rem;
  }
}
:host .question .mark {
  font-size: 4rem;
  position: absolute;
  top: 4rem;
  left: 3rem;
  width: 5rem;
  height: 5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 3px solid var(--color-brand);
  border-radius: 4rem;
}
@media only screen and (min-width: 768px) and (max-width: 1023px) {
  :host .question .mark {
    top: 3rem;
  }
}
@media only screen and (min-width: 480px) and (max-width: 767px) {
  :host .question .mark {
    top: 2rem;
    left: 2rem;
  }
}
@media only screen and (min-width: 320px) and (max-width: 479px) {
  :host .question .mark {
    font-size: 3rem;
    top: 2rem;
    left: 2rem;
    width: 4rem;
    height: 4rem;
  }
}
/*# sourceMappingURL=question.component.css.map */
