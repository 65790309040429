<mat-expansion-panel [expanded]="expanded" (expandedChange)="expandedChange($event)" [disabled]="disableExpansionPanel" [hideToggle]="true">
  <mat-expansion-panel-header>
    <mat-panel-title>
      <mat-icon svgIcon="logo"></mat-icon>
    </mat-panel-title>
  </mat-expansion-panel-header>

  <p>
    Witam Cię na plaformie Apologetyka Biblijna. Dedykowana jest ona w szczególny sposób osobom nawróconym, którzy tak jak ja,
    <b>wierzą w Jezusa Chrystusa, zbawiciela świata i natchnienie przez Boga Pisma Świętego</b>. Moim pragnieniem jest zachęcanie do
    dokładnego poznawania Słowa Bożego, wszystkich tych, którzy położyli nadzieję w Chrystusie (Ef.1,12-13).

    Jeżeli nie wiesz, czym jest nawrócenie, tutaj jako autor opisuje własną
    <a routerLink="/o-autorze">historię nawrócenia</a>.
  </p>

  <p>
    Strona jest przedsięwzięciem, w ramach którego za cel przyjąłem, aby w jak najdokładniejszy sposób omawiać zagadnienia, które są
    poruszone w Biblii, <b>nie kierując się niczyim interesem</b>, czy to określonej organizacji, czy denominacji chrześcijańskiej.
    Każdy temat opracowywany jest z zasady bez przyjęcia wstępnych założeń, w celu przeprowadzenia rzetelnych badań w taki sposób, <b>
    aby każdy czytelnik mógł rozważyć wszystkie argumenty</b> w danym temacie, nieobarczony żadnym do tego przymusem i rozstrzygnąć, gdzie leży racja - w świetle Słowa Bożego.
  </p>

  <p>
    Wierzę, że <b>Biblia stanowi prawdziwe Słowo Boże</b>, nie wyłącznie jeden z wielu filozoficznych,
    czy religijnych tekstów, lecz przekaz pochodzący od samego Boga, stwórcy nieba i ziemi. Biblia jest z wielu
    powodów księgą niepowtarzalną, jako jedyna zapowiada wydarzenia, które w czasie ich pisania należą do przyszłości,
    i które znajdują swoje wypełnienie w późniejszych wydarzeniach. Opis biblijny, a w szczególności <b>życie, śmierć
    na krzyżu i zmartwychstanie Jezusa Chrystusa</b> jest potwierdzone przez licznych, naocznych świadków tamtych
    wydarzeń, którzy też byli gotowi oddać za to życie, ponieważ mieli świadomość, że działo się to na ich własnych
    oczach i było prawdą.
  </p>

  <p>
    Apologetyka Biblijna to również platforma rozwijająca narzędzia w języku polskim do dokładnego badania Biblii, na wzór
    tych, które dostępne są wyłącznie na stronach anglojęzycznych. Więcej szczegółów ten temat możesz przeczytać w sekcji
    <a routerLink="/o-platformie">opisującej platformę.</a>
  </p>

  <p>
    Moją intencją jest, aby prace te stanowiły <b>wysiłek zbiorowy</b> wszystkich chętnych ku temu osób wierzących. Ten budynek
    nie ma postawionego dachu, będąc tylko człowiekiem mogę się mylić w dowolnej sprawie i nie zakładam, że posiadam
    wiedzę, która nie podlega zmianie. Z tego powodu z <b>radością przyjmę wszelkie wiadomości</b>, które wskażą mi
    błędy, albo uzupełnią lub poszerzą moją wiedzę. Zachęcam się do zapoznania się z opracowanymi materiałami w <b>
    działach tematycznych</b>. Do dyspozycji czytelnika oddanych jest kilka narzędzi i przygotowywane kolejne, które
    mają na celu pomóc mu w dokładniejszych badaniach.
  </p>

  <p>
    Jeżeli chcesz zadać jakieś pytanie, podzielić się swoimi refleksjami lub odnieść się do wybranego zagadnienia,
    zapraszam do kontaktu drogą mailową na:
    <b *onlyDesktopTablet class="email" [cdkCopyToClipboard]="environment.email" (click)="onClick()">
      {{ environment.email }}
    </b>
  </p>

  <p *onlyPortraitLandscape class="no-padding center" [cdkCopyToClipboard]="environment.email" (click)="onClick()">
    <b class="email">{{ environment.email }}</b>
  </p>

  <p class="right">Kamil Chmielowski</p>
</mat-expansion-panel>

<div class="hidden-box"></div>
