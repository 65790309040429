@if (page) {
  <article class="page-wrapper">
    <app-page-header [author]="page.author"
      [date]="page.date"
      [minutes]="page.minutes"
      [title]="page.title"
      [subtitle]="page.subtitle"
      [chips]="page?.chips"
      [pdfUrl]="page.pdfUrl"
      [epubUrl]="page.epubUrl"
      [mobiUrl]="page.mobiUrl"
      [youtubeUrl]="page.youtubeUrl"
    ></app-page-header>
    @if (page.question) {
      <app-question [text]="page.question"></app-question>
    }
    @if (page.foreword) {
      <section>
        @if (page | isPredefinedAuthor) {
          <div class="flex center author-description">
            <div>
              <app-dynamic-page-paragraph [paragraph]="page.foreword[0].paragraph | paragraphCenter"></app-dynamic-page-paragraph>
              <app-dynamic-page-paragraph [paragraph]="page.foreword[1].paragraph | paragraphCenter"></app-dynamic-page-paragraph>
              <app-dynamic-page-paragraph [paragraph]="page.foreword[2].paragraph | paragraphCenter"></app-dynamic-page-paragraph>
              @if (!(breakpointsService.observeLandscape | async)) {
                <app-dynamic-page-paragraph [paragraph]="page.foreword[3].paragraph"></app-dynamic-page-paragraph>
              }
              @if (!(breakpointsService.observeLandscape | async)) {
                <app-dynamic-page-paragraph [paragraph]="page.foreword[4].paragraph"></app-dynamic-page-paragraph>
              }
            </div>
            <img [src]="'assets/img/' + (page | getLogoBasedOnAuthor)" alt="'wystąpił błąd przy pobieraniu obrazka'" class="logo-image image-box">
          </div>
          @for (item of page.foreword; track i; let i = $index) {
            @if (i > ((breakpointsService.observeLandscape | async) ? 2 : 4)) {
              <app-dynamic-page-paragraph [paragraph]="item.paragraph"></app-dynamic-page-paragraph>
            }
          }
        } @else {
          @for (item of page.foreword; track $index) {
            <app-dynamic-page-paragraph [paragraph]="item.paragraph"></app-dynamic-page-paragraph>
          }
        }
        <div class="separator center"></div>
      </section>
    }
    @if (page.book) {
      <app-dynamic-page-book [book]="page.book"></app-dynamic-page-book>
    }
    @if (page.tableOfContents || page.navigation) {
      <app-table-of-contents id="table-of-contents"
        [contents]="page.tableOfContents" [navigation]="page.navigation">
      </app-table-of-contents>
    }
    @if (page.content) {
      <section>
        @for (item of page.content; track item) {
          <ng-container *ngTemplateOutlet="content; context: { item: item }"></ng-container>
        }
        @if (page.navigation?.length > 0 || page.recommended || page.otherFromAuthor) {
          <app-buttons-paginator
            [navigation]="page.navigation"
            [recommended]="page.recommended"
            [otherFromAuthor]="page.otherFromAuthor"
          ></app-buttons-paginator>
        }
        @if (page?.bibliography?.length > 0) {
          <app-bibliography [bibliography]="page.bibliography"></app-bibliography>
        }
        <app-bible-references [removeFirstResult]="isCommentary"></app-bible-references>
      </section>
    }
    @if (page.resources) {
      <section>
        @for (item of page.resources; track item) {
          <ng-container *ngTemplateOutlet="content; context: { item: item }"></ng-container>
        }
      </section>
    }
    <app-contact-form></app-contact-form>
    @if (page.tableOfContents) {
      <app-scroll-nav *onlyDesktop (contentState$)="scrollNavCollapsed = $event === 'out'"></app-scroll-nav>
    }
    @if (page.tableOfContents) {
      <app-page-nav-button *onlyPortraitLandscapeTablet [tableOfContents]="page.tableOfContents"></app-page-nav-button>
    }
  </article>
}

<app-loader [isLoading]="isLoading"></app-loader>

<app-error-page [errorStatus]="errorStatus" [notFoundMsg]="'Szukana strona nie istnieje.'"></app-error-page>

<ng-template #content let-item="item">
  @if (item.header) {
    <app-header [title]="item.header?.text || item.header"
      [separator]="item.header?.separator" [h]="item.header?.size || 2">
    </app-header>
  }

  @if (item.paragraph) {
    <app-dynamic-page-paragraph [paragraph]="item.paragraph"></app-dynamic-page-paragraph>
  }

  @if (item.dialogue) {
    <div class="dialogue">
      @for (dialogue_item of item.dialogue; track dialogue_item) {
        <p>
          — <span class="italic">{{ dialogue_item.text }}</span>
          {{ dialogue_item?.narrator }}
        </p>
      }
    </div>
  }

  @if (item.list) {
    <ul>
      @for (el of item.list; track el) {
        <li>
          @if (!el.router && !el.paragraph) {
            <span class="text">{{ el }}</span>
          }
          @if (el.paragraph) {
            <app-dynamic-page-paragraph [paragraph]="el.paragraph" noPadding="true"></app-dynamic-page-paragraph>
          }
          @if (el.router) {
            <a class="text" [routerLink]="el.router.routerLink">{{ el.router.text }}</a>
          }
        </li>
      }
    </ul>
  }

  @if (item.orderedList) {
    <ol>
      @for (el of item.orderedList; track el) {
        <li>
          @if (!el.router && !el.paragraph) {
            <span class="text">{{ el }}</span>
          }
          @if (el.paragraph) {
            <app-dynamic-page-paragraph [paragraph]="el.paragraph" noPadding="true"></app-dynamic-page-paragraph>
          }
          @if (el.router) {
            <a class="text" [routerLink]="el.router.routerLink">{{ el.router.text }}</a>
          }
        </li>
      }
    </ol>
  }

  @if (item.bible) {
    <app-bible-quotation
      [abbreviation]="item.bible.abbreviation"
      [book]="item.bible.book"
      [chapter]="item.bible.chapter"
      [verses]="item.bible.verses"
      [fullPage]="item.bible.fullPage"
      [format]="item.format"
    ></app-bible-quotation>
  }

  @if (item.quotation) {
    <app-quotation [quotation]="item.quotation" [isMultilang]="item.quotation.altText || item.quotation.altParagraph">
      @if (item.quotation.text) {
        {{ item.quotation.text }}
      }
      <ng-container multilang>
        @if (item.quotation.altText) {
          {{ item.quotation.altText }}
        }
      </ng-container>
    </app-quotation>
  }

  @if (item.poemes) {
    <app-poem [poemes]="item.poemes"></app-poem>
  }

  @if (item.image) {
    <app-image [image]="item.image"></app-image>
  }

  @if (item.table) {
    <app-simple-table [table]="item.table"></app-simple-table>
  }

  @if (item.separator) {
    <div class="separator center"></div>
  }

  @if (item.video) {
    <app-youtube-player [videoId]="item.video.id"></app-youtube-player>
  }
</ng-template>
