import { ChangeDetectionStrategy, Component, HostBinding, Input, OnInit } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { UpperCasePipe } from '@angular/common';

import { DynamicPageNavigation, DynamicPageRecommendation } from '../../../../services/dynamic-page/dynamic-page.model';
import { HeaderComponent } from '../../../../components/header/header/header.component';

@Component({
  selector: 'app-buttons-paginator',
  templateUrl: './buttons-paginator.component.html',
  styleUrls: ['./buttons-paginator.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    HeaderComponent,
    MatIconModule,
    RouterModule,
    UpperCasePipe,
  ]
})
export class ButtonsPaginatorComponent implements OnInit {
  @Input({ required: true }) protected readonly navigation: DynamicPageNavigation[];
  @Input({ required: true }) protected readonly recommended: [DynamicPageRecommendation?, DynamicPageRecommendation?];
  @Input({ required: true }) protected readonly otherFromAuthor: [DynamicPageNavigation?, DynamicPageNavigation?];

  @HostBinding('class.only-next') protected onlyNext: boolean;
  @HostBinding('class.only-prev') protected onlyPrev: boolean;

  protected elementBefore: DynamicPageNavigation;
  protected elementAfter: DynamicPageNavigation;

  ngOnInit(): void {
    this.initElements();
  }

  private initElements(): void {
    if (this.navigation) {
      const currentElementIndex = this.navigation.findIndex(item => item.current);
      this.elementBefore = this.navigation[currentElementIndex - 1];
      this.elementAfter = this.navigation[currentElementIndex + 1];
      this.onlyPrev = !this.elementAfter;
      this.onlyNext = !this.elementBefore;
    }
  }
}
